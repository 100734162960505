<template>
  <div class="content">
    <b-row class="justify-content-center">
      <b-col
        md="8"
        lg="6"
        xl="4"
      >
        <div class="p-sm-3 px-lg-4 py-lg-5">
          <div class="push text-center mb-0">
            <b-img
              :src="logo"
              height="320"
              :alt="$store.getters.appName"
            />

            <div class="font-size-sm mb-5">
              v{{ $appVersion }}
            </div>

            <p v-if="passwordReset">
              Your password has been reset!
            </p>
            <p v-else>
              Enter a new password that you can use to login.
            </p>
          </div>

          <b-alert
            class="mb-0"
            :show="hasBaseServerErrors()"
            variant="danger"
            dismissible
          >
            <p
              class="mb-0"
              v-for="(error, index) in baseServerErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </b-alert>

          <b-alert
            v-model="invalidToken"
            variant="danger"
            dismissible
          >
            Your password reset link is invalid or has expired.
          </b-alert>

          <div v-if="passwordReset" />

          <b-form
            v-else
            @submit.stop.prevent="resetPassword"
          >
            <div>
              <b-form-group
                :label="fieldLabels.password"
                label-for="user-password"
              >
                <b-form-input
                  id="user-password"
                  autocomplete="new-password"
                  v-model="$v.form.password.$model"
                  :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
                  type="password"
                />
                <b-form-invalid-feedback
                  v-if="$v.form.password.$dirty"
                  id="user-password-feedback"
                >
                  <span v-if="!$v.form.password.required">Please enter a password.</span>
                  <span v-if="!$v.form.password.minLength">
                    The password must be at least {{ $v.form.password.$params.minLength.min }} characters long.
                  </span>
                  <span v-if="!$v.form.password.serverFailed">{{ serverErrors.password }}</span>
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                :label="fieldLabels.password_confirmation"
                label-for="user-password_confirmation"
              >
                <b-form-input
                  id="user-password_confirmation"
                  autocomplete="new-password"
                  v-model="$v.form.password_confirmation.$model"
                  :state="$v.form.password_confirmation.$dirty ? !$v.form.password_confirmation.$error : null"
                  type="password"
                />

                <b-form-invalid-feedback
                  v-if="$v.form.password_confirmation.$dirty"
                  id="user-password_confirmation-feedback"
                >
                  <span v-if="!$v.form.password_confirmation.sameAs">Must match the password.</span>
                  <span v-if="!$v.form.password_confirmation.serverFailed">
                    {{ serverErrors.password_confirmation }}
                  </span>
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <b-row class="form-group">
              <b-col>
                <b-button
                  type="submit"
                  variant="primary"
                  block
                >
                  <b-spinner
                    v-if="processing"
                    small
                    label="Updating Password.."
                    class="mr-1"
                  />
                  <i
                    v-else
                    class="fa fa-sync mr-1"
                  />
                  Update My Password
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <div v-if="passwordReset">
            <b-button
              v-if="isAdminOrEmployee"
              class="push text-center"
              variant="primary"
              block
              :to="{ name: 'login' }"
            >
              Return to Login
            </b-button>
            <p
              v-else
              class="push text-center"
            >
              Your password has been reset, you can now login to the mobile app
            </p>
          </div>
          <b-link
            v-else
            :to="{ name: 'login' }"
          >
            Back to Login
          </b-link>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import scopesLogo from '@/assets/images/logo.png';

export default {
  name: 'NewPassword',
  mixins: [formMixin],
  props: {
    reset_token: String,
  },
  data() {
    return {
      form: {
        password: null,
        password_confirmation: null,
        reset_token: this.reset_token,
      },
      fieldLabels: {
        password: 'New Password',
        password_confirmation: 'Confirm New Password',
      },
      category: null,
      processing: false,
      passwordReset: false,
      invalidToken: false,
      logo: scopesLogo,
    };
  },
  computed: {
    isAdminOrEmployee() {
      return this.category === 'admin' || this.category === 'employee';
    },
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        serverFailed() {
          return !this.hasServerErrors('password');
        },
      },
      password_confirmation: {
        sameAs: sameAs('password'),
        serverFailed() {
          return !this.hasServerErrors('password_confirmation');
        },
      },
    },
  },
  methods: {
    resetPassword() {
      this.resetServerErrors();
      this.$v.$touch();

      if (this.$v.$anyError) {
        return;
      }

      this.processing = true;

      this.$authAPI
        .newPassword(this.form)
        .then((response) => {
          this.resetServerErrors();
          this.$v.$reset();
          this.category = response.data.category;
          this.passwordReset = true;
          this.processing = false;
        })
        .catch((error) => {
          this.processing = false;
          if (error.response.data.errors) {
            this.processServerErrors(error?.response?.data?.errors);
          } else {
            this.invalidToken = true;
          }
        });
    },
  },
};
</script>
