<template>
  <div>
    <b-button
      :id="formId"
      :variant="variant"
      :title="`Connect to ${referenceType} NetSuite`"
      v-b-tooltip.hover="{ boundary: 'window' }"
      :class="buttonClass"
      href="#"
      tabindex="0"
    >
      <i
        v-if="icon"
        :class="icon"
      />
      <span v-if="title">
        {{ title }}
      </span>
      <span
        v-if="title"
        class="font-size-xs bg-gray p-1"
      >
        3rd Party
      </span>
    </b-button>
    <b-popover
      triggers="focus"
      :show.sync="showPopoverForm"
      custom-class="netsuite-form-popover p-3"
      :target="formId"
      boundary="window"
      placement="bottomleft"
    >
      <b-form
        novalidate
        @submit.stop.prevent="handleSubmit"
      >
        <b-row>
          <b-col>
            <b-form-group
              :label="fieldLabels.source_id"
              label-for="netsuite-form-form-source_id"
            >
              <record-select
                name="netsuite-form-source_id"
                :reference-type="referenceType"
                :value="currentNetSuiteRecord"
                @input="onSourceIdChange"
                :select-class="{ 'is-invalid': $v.form.source_id.$dirty && $v.form.source_id.$error }"
              />
              <b-form-invalid-feedback
                v-if="$v.form.source_id.$dirty"
                class="netsuite-form-source_id-feedback"
              >
                <span v-if="!$v.form.source_id.required">Please enter a Record to connect.</span>
                <span v-if="!$v.form.source_id.serverFailed">{{ serverErrors.source_id }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <hr class="mt-4">

        <b-row>
          <b-col
            offset-lg="6"
            lg="3"
          >
            <b-button
              variant="link-dark"
              block
              :id="`netsuite-form-${referenceType}-${referenceId}-close-button`"
              @click="closePopover"
            >
              Cancel
            </b-button>
          </b-col>
          <b-col lg="3">
            <b-button
              :id="`netsuite-form-${referenceType}-${referenceId}-submit-button`"
              block

              type="submit"
              variant="primary"
            >
              Save
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-popover>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';
import RecordSelect from '@/components/admin/integrationReferences/NetSuiteRecordSelect.vue';

export default {
  name: 'NetSuiteFormPopover',
  components: {
    RecordSelect,
  },
  mixins: [formMixin, formModalMixin],
  props: {
    currentIntegrationReferenceId: Number,
    referenceId: {
      type: Number,
      required: true,
    },
    referenceType: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: 'NetSuite'
    },
    icon: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'outline-secondary'
    },
    buttonClass: {
      type: String,
      default: 'mr-2'
    }
  },
  data() {
    return {
      showPopoverForm: false,
      formId: `netsuite-form-${this.referenceType}-${this.referenceId}-popover`,
      form: {
        reference_id: this.referenceId,
        reference_type: this.referenceType,
        source: 'netsuite',
        source_id: null,
      },
      currentIntegrationReference: null,
      currentNetSuiteRecord: null,
      fieldLabels: {
        source_id: 'Search for a Record to connect',
      },
    };
  },
  validations: {
    form: {
      reference_id: {},
      reference_type: {},
      source: {},
      source_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('source_id');
        },
      },
    },
  },
  methods: {
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }
      this.$integrationReferencesAPI
        .createNetSuiteReference(this.form)
        .then((newIntegrationReference) => {
          this.resetServerErrors();
          this.$v.$reset();
          this.closePopover();
          this.currentIntegrationReference = newIntegrationReference;
          this.currentNetSuiteRecord = newIntegrationReference.netsuite_record;
          this.$emit('input', newIntegrationReference);
        })
        .catch((error) => {
          this.processServerErrors(error.response.data.errors);
        });
    },
    closePopover() {
      this.showPopoverForm = false;
    },
    onSourceIdChange(netSuiteRecord) {
      this.form.source_id = netSuiteRecord ? netSuiteRecord.id : null;
      this.currentNetSuiteRecord = netSuiteRecord;
    },
    getCurrentIntegrationReference() {
      this.$integrationReferencesAPI.get(this.currentIntegrationReferenceId).then((integrationReference) => {
        this.currentIntegrationReference = integrationReference;
        this.currentNetSuiteRecord = integrationReference.netsuite_record;
        this.form.source_id = integrationReference.source_id;
    });
    },
  },
  mounted() {
    if (this.currentIntegrationReferenceId) {
      this.getCurrentIntegrationReference();
    }
  },
};
</script>

<style scoped>
.netsuite-form-popover {
  font-size: 1rem;
  min-width: 476px;
}
</style>
