import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const SERVICE_MODEL_NAME = 'services';
export const SERVICE_TABLE_NAME = 'services';
export const SERVICE_TABLE_ID = 'services-table';

// The columns available to be shown in a services table.
export const SERVICE_TABLE_COLUMNS = {
  name: { label: 'Name', key: 'name', sortable: true },
  description: { label: 'Description', key: 'description' },
  category: { label: 'Category', key: 'category', sortable: true },
  customer_identifier: { label: 'Customer Identifier', key: 'customer_identifier', sortable: true  },
  technician_payment_gl_code_id: { label: 'Technician Payment GL Code', key: 'technician_payment_gl_code_id' },
  invoice_gl_code_id: { label: 'Invoice GL Code', key: 'invoice_gl_code_id' },
  minimum_days_between_service: {
    label: 'Days Between Service',
    key: 'minimum_days_between_service',
  },
  estimated_hours: {
    label: 'Estimated Hours to Complete',
    key: 'estimated_hours',
  },
  add_on: { label: 'Add-On', key: 'add_on' },
  client_id: { label: 'Client', key: 'client_id', sortable: true  },
  deleted_at: { label: 'Active', key: 'active' },
  netsuite_reference_id: { label: 'NetSuite Record', key: 'netsuite_reference_id' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const SERVICE_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(SERVICE_TABLE_COLUMNS);

// The default columns to be shown when the services table loads.
export const SERVICE_TABLE_DEFAULT_COLUMNS = [
  SERVICE_TABLE_COLUMNS.name,
  SERVICE_TABLE_COLUMNS.description,
  SERVICE_TABLE_COLUMNS.category,
  SERVICE_TABLE_COLUMNS.client_id,
  SERVICE_TABLE_COLUMNS.customer_identifier,
  SERVICE_TABLE_COLUMNS.technician_payment_gl_code_id,
  SERVICE_TABLE_COLUMNS.invoice_gl_code_id,
];
