<template>
  <sfs-form-modal
    :id="modalId"
    title="Edit Order"
    @on-hide="resetFormData"
  >
    <supply-orders-form
      v-bind="value"
      @on-submit="updateOrder"
      @on-save="onSave"
      @on-cancel="closeModal"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import SupplyOrdersForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'SupplyOrdersEditModal',
  mixins: [formModalMixin],
  components: {
    SupplyOrdersForm,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      modalId: 'supply-order-edit-modal',
    };
  },
  methods: {
    updateOrder(data, handleUpdate) {
      handleUpdate(this.$supplyOrdersAPI.update(this.value.id, data));
    },
    onSave(supplyOrder) {
      this.closeModal();
      this.$emit('input', supplyOrder);
    },
  },
};
</script>
