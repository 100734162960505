<template>
  <sfs-form-modal
    :id="modalId"
    title="Finalize Invoices"
    @on-hide="resetForm"
  >
    <b-form
      novalidate
      @submit.stop.prevent="handleSubmit"
    >
      <b-row>
        <b-col>
          <b-alert
            :show="hasBaseServerErrors()"
            variant="danger"
            dismissible
          >
            <p
              class="mb-0"
              v-for="(error, index) in baseServerErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </b-alert>
        </b-col>
      </b-row>

      <b-row class="mb-0">
        <b-col cols="12">
          <b-form-group
            :label="fieldLabels.period"
            label-for="invoice-finalize-period"
          >
            <sfs-date-range-picker
              id="invoice-finalize-period"
              v-model="$v.form.period.$model"
              :with-class="{ 'is-invalid': ($v.form.date_from.$dirty && $v.form.date_from.$error) || ($v.form.date_to.$dirty && $v.form.date_to.$error) }"
              :class="{ 'is-invalid': ($v.form.date_from.$dirty && $v.form.date_from.$error) || ($v.form.date_to.$dirty && $v.form.date_to.$error) }"
              placeholder="Select Dates"
              @on-change="onPeriodChange"
            />
            <b-form-invalid-feedback
              v-if="$v.form.date_from.$dirty"
              id="invoice-finalize-date_from-feedback"
            >
              <span v-if="!$v.form.date_from.required">Please enter a start date.</span>
              <span v-if="!$v.form.date_from.serverFailed">{{ serverErrors.date_from }}</span>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-if="$v.form.date_to.$dirty"
              id="invoice-finalize-date_to-feedback"
            >
              <span v-if="!$v.form.date_to.required">Please enter a end date.</span>
              <span v-if="!$v.form.date_to.serverFailed">{{ serverErrors.date_to }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <sfs-download-button
            v-b-tooltip.hover
            label="Download Invoices to Review"
            :provider-params="form"
            filename="Invoices.csv"
            :file-provider="$invoicesAPI.getSpreadsheet"
            title="Review Invoices"
          />
        </b-col>
      </b-row>

      <hr class="mt-4">

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            id="invoice-finalize-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            id="invoice-finalize-submit-button"
            block

            type="submit"
            variant="primary"
          >
            Finalize
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'InvoicesFinalizeModal',
  mixins: [formMixin, formModalMixin],
  props: {
    date_from: String,
    date_to: String,
  },
  data() {
    return {
      form: {
        date_from: this.date_from,
        date_to: this.date_to,
        period: [
          this.date_from && this.$options.filters.dateFormat(this.date_from),
          this.date_to && this.$options.filters.dateFormat(this.date_to),
        ],
      },
      fieldLabels: {
        period: 'Billing Period *',
      },
      modalId: 'invoice-finalize-modal',
    };
  },
  validations: {
    form: {
      period: {},
      date_from: {
        required,
        serverFailed() {
          return !this.hasServerErrors('date_from');
        },
      },
      date_to: {
        required,
        serverFailed() {
          return !this.hasServerErrors('date_to');
        },
      },
    },
  },
  methods: {
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }
      this.$invoicesAPI
        .finalize({ ...this.form, organization_id: this.$store.getters.currentOrganization?.id })
        .then(() => {
          this.resetServerErrors();
          this.$v.$reset();
          this.$emit('on-save');
          this.closeModal();
        })
        .catch((error) => {
          this.processServerErrors(error.response.data.errors);
        });
    },
    onPeriodChange(dates) {
      this.form.date_from = dates[0] || '';
      this.form.date_to = dates[1] || '';
    },
  },
};
</script>
