<template>
  <div v-if="client">
    <base-page-heading
      :title="client.name"
      show-back-button
    >
      <template #secondary-title>
        <sfs-active-badge :active="!client.deleted_at" />
      </template>
      <template #extra>
        <b-button
          v-if="$can('update', 'Client')"
          variant="primary"
          v-b-modal.client-edit-modal
          class="mr-2"
          v-b-tooltip.hover
          title="Edit the Client"
        >
          <i class="fa fa-pencil-alt mr-1" /> Edit
        </b-button>

        <net-suite-form-popover
          v-if="$can('read', 'IntegrationReference')"
          :reference-id="client.id"
          reference-type="Client"
          :current-integration-reference-id="client.netsuite_reference_id"
        />

        <b-dropdown
          variant="outline-secondary"
          no-caret
          right
        >
          <template #button-content>
            <i class="fa fa-ellipsis-v" />
          </template>

          <b-dropdown-item
            v-if="$can('destroy', 'Client')"
            @click="toggleActive"
          >
            <i
              v-if="!client.deleted_at"
              class="fa fa-trash mr-1"
            />
            <i
              v-else
              class="fa fa-undo mr-1"
            />
            {{ toggleActiveTooltipText }}
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('read', 'Version')"
            :to="{ name: 'versions', query: { model_id: client.id, model: 'Client' } }"
          >
            <i class="fa fa-glasses mr-1" />
            Audit Log
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </base-page-heading>
    <div class="content pt-5">
      <b-row>
        <b-col md="6">
          <b-card class="mb-3">
            <b-row>
              <b-col cols="12">
                <b-row class="mb-3">
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    Code
                  </b-col>
                  <b-col class="text-right">
                    {{ client.code }}
                  </b-col>
                </b-row>

                <b-row
                  v-if="client.customer_identifier"
                  class="mb-3"
                >
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    Customer ID
                  </b-col>
                  <b-col class="text-right">
                    {{ client.customer_identifier }}
                  </b-col>
                </b-row>

                <b-row
                  v-if="client.managers && client.managers.length > 0"
                  class="mb-3"
                >
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    Managers
                  </b-col>
                  <b-col class="text-right">
                    <b-link
                      v-for="(manager, index) in client.managers"
                      :key="index"
                      :to="{ name: 'user', params: { id: manager.id } }"
                    >
                      {{ manager.name }}<span v-if="index != client.managers.length - 1">,</span>
                    </b-link>
                  </b-col>
                </b-row>

                <b-row
                  v-if="client.address"
                  class="mb-3"
                >
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    Address
                  </b-col>
                  <b-col class="text-right">
                    {{ client.address }}
                  </b-col>
                </b-row>

                <b-row
                  v-if="client.city"
                  class="mb-3"
                >
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    City
                  </b-col>
                  <b-col class="text-right">
                    {{ client.city }}
                  </b-col>
                </b-row>

                <b-row
                  v-if="client.state"
                  class="mb-3"
                >
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    State
                  </b-col>
                  <b-col class="text-right">
                    {{ client.state }}
                  </b-col>
                </b-row>

                <b-row
                  v-if="client.zip_code"
                  class="mb-3"
                >
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    Zip Code
                  </b-col>
                  <b-col class="text-right">
                    {{ client.zip_code }}
                  </b-col>
                </b-row>

                <h4 class="mb-3">
                  Billing
                </h4>

                <b-row
                  v-if="client.contact_name"
                  class="mb-3"
                >
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    Contact Name
                  </b-col>
                  <b-col class="text-right">
                    {{ client.contact_name }}
                  </b-col>
                </b-row>

                <b-row
                  v-if="client.purchase_order_number"
                  class="mb-3"
                >
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    Billing PO #
                  </b-col>
                  <b-col class="text-right">
                    {{ client.purchase_order_number }}
                  </b-col>
                </b-row>

                <b-row
                  v-if="client.attention_to"
                  class="mb-3"
                >
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    Attention To
                  </b-col>
                  <b-col class="text-right">
                    {{ client.attention_to }}
                  </b-col>
                </b-row>

                <b-row class="mb-3">
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    {{ $store.getters.translate('client', 'Client') }} is exempt from paying taxes
                  </b-col>

                  <b-col class="text-right">
                    <b-badge
                      v-if="client.tax_exempt"
                      variant="warning"
                    >
                      Yes
                    </b-badge>
                    <b-badge
                      v-else
                      variant="secondary"
                    >
                      No
                    </b-badge>
                  </b-col>
                </b-row>

                <b-row class="mb-3">
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    Automatic sending of Invoices disabled
                  </b-col>

                  <b-col class="text-right">
                    <b-badge
                      v-if="client.disable_auto_invoice_emails"
                      variant="warning"
                    >
                      Yes
                    </b-badge>
                    <b-badge
                      v-else
                      variant="secondary"
                    >
                      No
                    </b-badge>
                  </b-col>
                </b-row>

                <b-row class="mb-3">
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    {{ $store.getters.translate('client', 'Client') }} requires a physical copy of their Invoices
                  </b-col>

                  <b-col class="text-right">
                    <b-badge
                      v-if="client.send_physical_invoice"
                      variant="warning"
                    >
                      Yes
                    </b-badge>
                    <b-badge
                      v-else
                      variant="secondary"
                    >
                      No
                    </b-badge>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>

          <b-row class="mb-3">
            <b-col md="6">
              <sfs-dashboard-card
                title="Technicians"
                icon-class="far fa-user fa-2x"
                :content="totalTechnicians.toString()"
                :href="{ name: 'users', query: { client_id: client.id } }"
                href-content="View technicians"
              />
            </b-col>

            <b-col md="6">
              <sfs-dashboard-card
                title="Locations"
                icon-class="fa fa-store-alt fa-2x"
                :content="totalLocations.toString()"
                :href="{ name: 'locations', query: { client_id: client.id } }"
                href-content="View locations"
              />
            </b-col>
          </b-row>
        </b-col>

        <b-col md="6">
          <b-row class="mb-3">
            <b-col>
              <b-card>
                <b-row class="mb-3">
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    <h4>
                      Settings
                    </h4>
                  </b-col>
                  <b-col class="text-right">
                    <b-button
                      v-if="$can('update', 'Client')"
                      variant="outline-secondary"
                      v-b-modal.client-settings-modal
                      v-b-tooltip.hover
                      title="Edit the Client settings"
                    >
                      <i class="fa fa-pencil-alt mr-1" /> Edit
                    </b-button>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    Generate invoices for Work Orders individually
                  </b-col>

                  <b-col class="text-right">
                    <b-badge
                      v-if="client.invoice_work_orders_individually"
                      variant="warning"
                    >
                      Yes
                    </b-badge>
                    <b-badge
                      v-else
                      variant="secondary"
                    >
                      No
                    </b-badge>
                  </b-col>
                </b-row>

                <b-row class="mb-3">
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    All Work Order Charges require a service
                  </b-col>

                  <b-col class="text-right">
                    <b-badge
                      v-if="client.work_order_charges_require_service"
                      variant="success"
                    >
                      Yes
                    </b-badge>
                    <b-badge
                      v-else
                      variant="secondary"
                    >
                      No
                    </b-badge>
                  </b-col>
                </b-row>

                <b-row class="mb-3">
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    <h4>
                      No Shows
                    </h4>
                  </b-col>
                </b-row>

                <div v-if="allowsNoShow">
                  <b-row
                    class="mb-3"
                  >
                    <b-col
                      cols="auto"
                      class="mr-auto"
                    >
                      Original Work Orders will be completed
                    </b-col>
                    <b-col class="text-right">
                      <b-badge
                        v-if="client.complete_work_orders"
                        variant="success"
                      >
                        Yes
                      </b-badge>
                      <b-badge
                        v-else
                        variant="secondary"
                      >
                        No
                      </b-badge>
                    </b-col>
                  </b-row>

                  <b-row
                    v-if="client.no_show_service"
                    class="mb-3"
                  >
                    <b-col
                      cols="auto"
                      class="mr-auto"
                    >
                      No Show Service
                    </b-col>
                    <b-col class="text-right">
                      <b-link :to="{ name: 'service', params: { id: client.no_show_service.id } }">
                        {{ client.no_show_service.name }}
                      </b-link>
                    </b-col>
                  </b-row>

                  <b-row
                    class="mb-3"
                  >
                    <b-col
                      cols="auto"
                      class="mr-auto"
                    >
                      Amount Paid to Technician (Cost)
                    </b-col>
                    <b-col class="text-right">
                      <money-format
                        :value="client.no_show_cost.amount"
                        :currency-code="client.no_show_cost.currency"
                        subunits-value
                      />
                    </b-col>
                  </b-row>

                  <b-row
                    class="mb-3"
                  >
                    <b-col
                      cols="auto"
                      class="mr-auto"
                    >
                      Amount Paid to Client (Price)
                    </b-col>
                    <b-col class="text-right">
                      <money-format
                        :value="client.no_show_price.amount"
                        :currency-code="client.no_show_price.currency"
                        subunits-value
                      />
                    </b-col>
                  </b-row>
                </div>
                <b-badge
                  v-else
                  variant="secondary"
                >
                  Disabled
                </b-badge>
              </b-card>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col>
              <contact-infos-list
                :list-func="$contactInfosAPI.getClientsContactInfos"
                :create-func="$contactInfosAPI.createClientsContactInfos"
                :reference-id="client.id"
                reference-type="Client"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-tabs
            content-class="pt-4"
            v-model="activeTab"
          >
            <template #tabs-end>
              <div
                v-show="activeTab === 0"
                class="ml-auto"
              >
                <b-button
                  v-if="$can('create', 'Location')"
                  v-b-modal.location-add-modal
                  variant="outline-secondary"
                  v-b-tooltip.hover
                  title="Create a new Location for this Client"
                >
                  <i class="fa fa-plus" />
                  Add Location
                </b-button>
              </div>

              <div
                v-show="activeTab === 1"
                class="ml-auto"
              >
                <b-button
                  v-if="$can('create', 'AdditionalCharge')"
                  variant="outline-secondary"
                  class="ml-2"
                  v-b-modal.additional-charges-add-modal
                  v-b-tooltip.hover
                  title="Create a new Additional Charge for this Client"
                >
                  <i class="fa fa-plus" />
                  Add Charge
                </b-button>
              </div>
            </template>
            <b-tab
              active
              title="Locations"
            >
              <locations-table
                :filters="{ client_id: client.id, active: true }"
                :columns="locationColumns"
                :append-to-query="false"
                :show-per-page="false"
              />
            </b-tab>

            <b-tab title="Additional Charges">
              <additional-charges-list-filter
                :columns="additionalChargesColumns"
                :initial-filters="additionalChargesFilters"
                @filters-applied="applyAdditionalChargesFilters"
              />

              <additional-charges-table
                :filters="additionalChargesFilters"
                :columns="additionalChargesColumns"
                :key="additionalChargesTableKey"
                :client-id="client.id"
                :append-to-query="false"
                :show-per-page="false"
              />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </div>

    <!-- Modals -->
    <clients-edit-modal
      v-if="$can('update', 'Client')"
      v-model="client"
    />
    <clients-settings-modal
      v-if="$can('update', 'Client')"
      v-model="client"
    />
    <locations-new-modal
      v-if="$can('create', 'Location')"
      :client_ids="[client.id]"
    />
    <additional-charges-new-modal
      v-if="$can('create', 'AdditionalCharge')"
      :client="client"
      @on-save="refreshAdditionalCharges"
    />
  </div>
</template>

<script>
import ClientsEditModal from '@/components/admin/clients/EditModal.vue';
import ClientsSettingsModal from '@/components/admin/clients/SettingsModal.vue';
import LocationsTable from '@/components/admin/locations/Table.vue';
import AdditionalChargesTable from '@/components/admin/additionalCharges/Table.vue';
import ContactInfosList from '@/components/admin/contactInfos/CardList.vue';
import LocationsNewModal from '@/components/admin/locations/NewModal.vue';
import AdditionalChargesListFilter from '@/components/admin/additionalCharges/ListFilter.vue';
import AdditionalChargesNewModal from '@/components/admin/additionalCharges/NewModal.vue';
import NetSuiteFormPopover from '@/components/admin/integrationReferences/NetSuiteFormPopover.vue';
import { CLIENT_LOCATION_TABLE_NAME } from '@/constants/locations';
import { ADDITIONAL_CHARGES_TABLE_NAME } from '@/constants/additionalCharges';
import { DateTime } from 'luxon';

export default {
  name: 'ClientsShow',
  components: {
    ClientsEditModal,
    ClientsSettingsModal,
    LocationsTable,
    AdditionalChargesTable,
    LocationsNewModal,
    ContactInfosList,
    AdditionalChargesListFilter,
    AdditionalChargesNewModal,
    NetSuiteFormPopover,
  },
  data() {
    return {
      client: null,
      clientLocationsTableName: CLIENT_LOCATION_TABLE_NAME,
      additionalChargesTableName: ADDITIONAL_CHARGES_TABLE_NAME,
      totalLocations: 0,
      totalTechnicians: 0,
      activeTab: 0,
      additionalChargesTableKey: Date.now() + 1,
      additionalChargesFilters: {
        text: '',
        description: '',
        client: null,
        service: null,
        category: '',
        active: true,
      },
    };
  },
  computed: {
    hasAnyAddressFields() {
      return (
        (this.client.contact_name || '').length > 0 ||
        (this.client.attention_to || '').length > 0 ||
        (this.client.address || '').length > 0 ||
        (this.client.city || '').length > 0 ||
        (this.client.state || '').length > 0 ||
        (this.client.zip_code || '').length > 0
      );
    },
    allowsNoShow() {
      return this.client.no_show_cost.amount != 0 || this.client.no_show_price.amount != 0 || this.client.no_show_service;
    },
    toggleActiveTooltipText() {
      return !this.client.deleted_at ? 'Deactivate' : 'Reactivate';
    },
    toggleActiveConfirmText() {
      return !this.client.deleted_at
        ? 'Are you sure you want to deactivate the Client?'
        : 'Are you sure you want to reactivate the Client?';
    },
    locationColumns() {
      return this.$store.getters.columnsForTable(this.clientLocationsTableName);
    },
    additionalChargesColumns() {
      return this.$store.getters.columnsForTable(this.additionalChargesTableName);
    },
  },
  mounted() {
    this.getClient();
    this.getTechnicians();
    this.getLocations();
  },
  methods: {
    getClient() {
      this.$clientsAPI
        .get(this.$route.params.id)
        .then((client) => {
          this.client = client;
        })
        .catch(() => {
          this.$router.push('/404');
        });
    },
    getTechnicians() {
      this.$usersAPI.getUsers({ client_id: this.$route.params.id, active: true }).then((data) => {
        this.totalTechnicians = data.total;
      });
    },
    getLocations() {
      this.$locationsAPI.getLocations({ client_id: this.$route.params.id, active: true }).then((data) => {
        this.totalLocations = data.total;
      });
    },
    toggleActive() {
      this.$swal({
        text: this.toggleActiveConfirmText,
        preConfirm: () => this.$clientsAPI.update(this.$route.params.id, { deleted_at: (!this.client.deleted_at ? DateTime.now().toISODate() : null) }),
      }).then((result) => {
        if (result.value) {
          this.client = result.value;
        }
        result.timer;
      });
    },
    refreshAdditionalCharges() {
      this.additionalChargesTableKey++;
    },
    applyAdditionalChargesFilters({ filters }) {
      this.additionalChargesFilters = Object.assign({}, filters);
    },
  },
};
</script>
