import mutations from '@/store/modules/auth/mutations.js';
import actions from '@/store/modules/auth/actions.js';
import getters from '@/store/modules/auth/getters.js';

export default {
  state() {
    return {
      token: null,
      impersonatingToken: null,
      user: null,
      impersonatingUser: null,
      category: null,
      rules: [],
      permissions: [],
      organization: null,
      organizationTerminology: null,
      client: null,
      userListColumns: null,
      userPerPage: null,
      organizationListColumns: null,
    };
  },
  mutations,
  actions,
  getters,
};
