/*
 * Starter Router
 */

// Vue and Vue Router
import Vue from 'vue';
import Router from 'vue-router';

// Lodash helpers
import { startsWith as _startsWith, split as _split, isNil as _isNil } from 'lodash';
import { QR_CODE_REDIRECTION_PATH } from '@/constants';

// Register Vue Router
Vue.use(Router);

// Main layouts
import AdminLayout from '@/layouts/Admin.vue';
import PublicLayout from '@/layouts/Public.vue';
import TechnicianLayout from '@/layouts/Technician';

// Public pages
import Login from '@/pages/auth/Login.vue';
import Logout from '@/pages/auth/Logout.vue';
import ForgotPassword from '@/pages/auth/ForgotPassword.vue';
import NewPassword from '@/pages/auth/NewPassword.vue';
import WorkOrdersUploadForm from '@/pages/qrCodes/WorkOrdersUploadForm.vue';
import WorkOrdersUploadInvalidCode from '@/pages/qrCodes/InvalidCode.vue';
import WorkOrdersUploadUnprocessable from '@/pages/qrCodes/Unprocessable.vue';

// Technician pages
import TechnicianDashboard from '@/pages/technician/Dashboard.vue';
import technicianWorkOrdersRoutes from '@/router/technician/workOrders';
import technicianUsersRoutes from '@/router/technician/users';
import technicianUserPaymentsRoutes from '@/router/technician/technicianPayments';

// Error pages
import errorRoutes from '@/router/admin/errors';

// Admin pages
import AdminDashboard from '@/pages/admin/Dashboard.vue';
import clientsRoutes from '@/router/admin/clients';
import clientFeedbacksRoutes from '@/router/admin/clientFeedbacks';
import glCodesRoutes from '@/router/admin/glCodes';
import importsRoutes from '@/router/admin/imports';
import insuranceCertificatesRoutes from '@/router/admin/insuranceCertificates';
import invoicesRoutes from '@/router/admin/invoices';
import locationsRoutes from '@/router/admin/locations';
import quotesRoutes from '@/router/admin/quotes';
import reportsRoutes from '@/router/admin/reports';
import servicesRoutes from '@/router/admin/services';
import supplyOrdersRoutes from '@/router/admin/supplyOrders';
import taxRatesRoutes from '@/router/admin/taxRates';
import technicianPaymentsRoutes from '@/router/admin/technicianPayments';
import usersRoutes from '@/router/admin/users';
import vendorsRoutes from '@/router/admin/vendors';
import versionsRoutes from '@/router/admin/versions';
import walkthroughsRoutes from '@/router/admin/walkthroughs';
import workOrdersRoutes from '@/router/admin/workOrders';
import delayedJobsRoutes from '@/router/admin/delayedJobs';

// vuex store
import store from '@/store/index.js';

import handlePaginationRouteProps from '@/utils/handlePaginationRouteProps.js';
import { prepareAuthenticatedEmployeeRoutes, prepareTechnicianRoutes } from '@/utils/prepareRoutes.js';

// Router Configuration
const router = new Router({
  linkActiveClass: 'active',
  linkExactActiveClass: '',
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/login',
      component: PublicLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          component: Login,
          meta: { public: true },
        },
        {
          path: '/logout',
          name: 'logout',
          component: Logout,
        },
        {
          path: '/forgot_password',
          name: 'forgot-password',
          component: ForgotPassword,
          meta: { public: true },
        },
        {
          path: '/new_password',
          name: 'new-password',
          component: NewPassword,
          meta: { public: true },
          props: handlePaginationRouteProps,
        },
        {
          path: '/work_orders/:id/upload',
          name: 'qr-code-work-orders',
          component: WorkOrdersUploadForm,
          meta: { public: false },
        },
        {
          path: '/work_orders/invalid',
          name: 'qr-code-work-orders-invalid',
          component: WorkOrdersUploadInvalidCode,
          meta: { public: false },
        },
        {
          path: '/work_orders/unprocessable',
          name: 'qr-code-work-orders-unprocessable',
          component: WorkOrdersUploadUnprocessable,
          meta: { public: false },
        },
      ],
    },
    {
      path: '/admin',
      redirect: '/admin/dashboard',
      component: AdminLayout,
      children: prepareAuthenticatedEmployeeRoutes([
        {
          path: 'dashboard',
          name: 'admin-dashboard',
          component: AdminDashboard,
        },
        ...clientFeedbacksRoutes,
        ...clientsRoutes,
        ...glCodesRoutes,
        ...importsRoutes,
        ...insuranceCertificatesRoutes,
        ...invoicesRoutes,
        ...locationsRoutes,
        ...quotesRoutes,
        ...reportsRoutes,
        ...servicesRoutes,
        ...supplyOrdersRoutes,
        ...taxRatesRoutes,
        ...technicianPaymentsRoutes,
        ...usersRoutes,
        ...vendorsRoutes,
        ...versionsRoutes,
        ...walkthroughsRoutes,
        ...workOrdersRoutes,
        ...delayedJobsRoutes,
      ]),
    },
    {
      path: '/technician',
      redirect: '/technician/dashboard',
      component: TechnicianLayout,
      children: prepareTechnicianRoutes([
        {
          path: 'dashboard',
          name: 'technician-dashboard',
          component: TechnicianDashboard,
        },
        ...technicianWorkOrdersRoutes,
        ...technicianUsersRoutes,
        ...technicianUserPaymentsRoutes,
      ]),
    },
    ...errorRoutes,
  ],
});

const setPageTitle = (path) => {
  if (_startsWith(path.toLowerCase(), '/admin')) {
    document.title = 'Scopes Management App';
  } else if (_startsWith(path.toLowerCase(), '/technician')) {
    document.title = 'Scopes Technician App';
  } else {
    document.title = 'Scopes Facility Services';
  }
};

router.beforeEach(function (to, _, next) {
  setPageTitle(to.path);
  if (_startsWith(to.hash.toLowerCase(), QR_CODE_REDIRECTION_PATH)) {
    next({ path: `/work_orders/${_split(to.hash, QR_CODE_REDIRECTION_PATH).pop()}/upload` });
  } else if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next({ name: 'login' });
  } else if (to.meta.public && store.getters.isAuthenticated) {
    next({ name: store.getters.indexPage });
  } else if (to.meta.requiresAuth && store.getters.isAuthenticated && _isNil(store.getters.category)) {
    next({ name: 'logout'});
  } else if (to.meta.requiresAuth && store.getters.isAuthenticated && _startsWith(to.path.toLowerCase(), '/admin') && !store.getters.isEmployee) {
    next({ name: 'not-found'} );
  }
  else {
    next();
  }
});

export default router;
