import { ACTIVE_TABLE_FILTER_OPTIONS } from '@/constants';
import { LOCATION_FLOOR_TYPE_FORMAT_OPTIONS } from '@/constants/locations';
import { SET_CLIENT_MUTATION } from '@/constants/mutations.js';
import { isEqual as _isEqual, isUndefined as _isUndefined } from 'lodash';

export default {
  props: {
    totalRows: {
      type: Number,
      default: 0,
    },
    initialFilters: {
      type: Object,
      default: () => new Object(),
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      displayedColumns: this.columns,
      availableColumns: [],
      appliedFilters: {},
      filters: { ...this.initialFilters },
      activeOptions: ACTIVE_TABLE_FILTER_OPTIONS,
      user: null,
      client: null,
      manager: null,
      technician: null,
      subTechnician: null,
      location: null,
      service: null,
      assignedTo: null,
      doneBy: null,
      vendor: null,
      roles: null,
      parentWorkOrder: null,
    };
  },
  methods: {
    applyFilters() {
      this.appliedFilters = Object.assign({}, this.filters);
      this.appliedFilters.user_id = this.user ? this.user.name : '';
      this.appliedFilters.client_id = this.client ? this.client.code : '';
      this.appliedFilters.manager_id = this.manager ? this.manager.name : '';
      this.appliedFilters.sub_technician_id = this.subTechnician ? this.subTechnician.name : '';
      this.appliedFilters.technician_id = this.technician ? this.technician.name : '';
      this.appliedFilters.location_id = this.location ? this.location.store_number : '';
      this.appliedFilters.location_id = this.location && this.location.name ? `${this.appliedFilters.location_id} - ${this.location.name}` : this.appliedFilters.location_id;
      this.appliedFilters.service_id = this.service ? this.service.name : '';
      this.appliedFilters.assigned_to_id = this.assignedTo ? this.assignedTo.name : '';
      this.appliedFilters.done_by_id = this.doneBy ? this.doneBy.name : '';
      this.appliedFilters.vendor_id = this.vendor ? this.vendor.name : '';
      this.appliedFilters.parent_id = this.parentWorkOrder ? this.parentWorkOrder.number : '';
      this.appliedFilters.floor_type = LOCATION_FLOOR_TYPE_FORMAT_OPTIONS[this.filters.floor_type];
      this.appliedFilters.start_date = this.filters.start_date ? `> ${this.filters.start_date}` : '';
      this.appliedFilters.end_date = this.filters.end_date ? `< ${this.filters.end_date}` : '';

      [
        'status',
        'sub_status',
        'completion_source',
        'invoice_delivery_method',
        'category',
        'charge_category',
        'service_category'].forEach((humanizedFilter) => {
          this.appliedFilters[humanizedFilter] = this.humanize(this.filters[humanizedFilter] || '');
        });

      [
        'active',
        'emailed',
        'approved',
        'verified',
        'send_physical_invoice',
        'backordered',
        'technician_paid',
        'qr_code_access',
        'technician_portal_access',
        'reviewed',
        'finalized',
        'invoiced',
        'emailed',
        'emailable',
        'no_service',
        'check_in_distance'].forEach((booleanFilter) => {
          if (this.filters[booleanFilter] != null) {
            this.appliedFilters[booleanFilter] = ['true', true].includes(this.filters[booleanFilter]) ? 'Yes' : 'No';
          }
        });

      this.appliedFilters.role_ids = this.roles ? this.roles.map((role) => role.name).join(', ') : '';
      this.appliedFilters.statuses = this.filters.statuses ? this.filters.statuses.map((status) => this.humanize(status)).join(', ') : '';
      this.appliedFilters.cancellation_reasons = this.filters.cancellation_reasons ? this.filters.cancellation_reasons.map((reason) => this.humanize(reason)).join(', ') : '';
      this.appliedFilters.completion_sources = this.filters.completion_sources ? this.filters.completion_sources.map((reason) => this.humanize(reason)).join(', ') : '';
      this.appliedFilters.services_provided = this.filters.services_provided ? this.filters.services_provided.map((reason) => this.humanize(reason)).join(', ') : '';

      this.$emit('filters-applied', {
        columns: this.displayedColumns,
        filters: this.filters,
      });
    },
    humanize(words) {
      return this.$options.filters.capitalizeFormat(this.$options.filters.humanizedFormat(words));
    },
    onFilterCleared(key) {
      [{ filterKey: 'user_id', objectKey: 'user' },
        { filterKey: 'client_id', objectKey: 'client' },
        { filterKey: 'manager_id', objectKey: 'manager' },
        { filterKey: 'technician_id', objectKey: 'technician' },
        { filterKey: 'sub_technician_id', objectKey: 'subTechnician' },
        { filterKey: 'location_id', objectKey: 'location' },
        { filterKey: 'service_id', objectKey: 'service' },
        { filterKey: 'assigned_to_id', objectKey: 'assignedTo' },
        { filterKey: 'done_by_id', objectKey: 'doneBy' },
        { filterKey: 'vendor_id', objectKey: 'vendor' },
        { filterKey: 'parent_id', objectKey: 'parentWorkOrder' },
        { filterKey: 'role_ids', objectKey: 'roles' }].forEach(({ filterKey, objectKey }) => {
          if (key == filterKey) {
            this[objectKey] = null;
          }
        });

      if (key == 'client_id') {
        this.$store.commit(SET_CLIENT_MUTATION, { client: null });
        this.client = null;
      }

      if (key == 'scheduled_between') {
        this.filters.date_bucket_start_from = null;
        this.filters.date_bucket_end_to = null;
      }
      if (key == 'completed_between') {
        this.filters.checked_out_at_from = null;
        this.filters.checked_out_at_to = null;
      }
      if (key == 'date_between') {
        this.filters.date_to = null;
        this.filters.date_from = null;
      }
      if (key == 'due_date_between') {
        this.filters.due_date_to = null;
        this.filters.due_date_from = null;
      }
      this.filters[key] = null;
      this.applyFilters();
    },
    onFiltersApplied(displayedColumns) {
      this.displayedColumns = displayedColumns;
      this.applyFilters();
    },
  },
  watch: {
    initialFilters: {
      deep: true,
      handler(newFilters, oldFilters) {
        if (!_isEqual(oldFilters, newFilters) && !_isUndefined(newFilters)) {
          this.filters = Object.assign(this.filters, newFilters);
          this.applyFilters();
        }
      }
    },
  },
};
