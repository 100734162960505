import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { technicianPath } from '@/api/helpers/apiPath';

export default {
  getUsers(params) {
    return axios.get(technicianPath('/users?') + prepareQueryString(params)).then((response) => {
      return {
        users: response.data.map((user) => an('User', user)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  get(id) {
    return axios.get(technicianPath(`/users/${id}`)).then((response) => an('User', response.data));
  },
  update(id, data) {
    return axios
      .patch(technicianPath(`/users/${id}`), {
        ...data,
      })
      .then((response) => an('User', response.data));
  },
  create(data) {
    return axios
      .post(technicianPath('/users'), {
        ...data,
      })
      .then((response) => an('User', response.data));
  },
  impersonate(id) {
    return axios.get(technicianPath(`/users/${id}/impersonate`));
  },
  getLocaleOptions() {
    return axios.get(technicianPath('/users/locale_options'));
  },
  getTimeZoneOptions() {
    return axios.get(technicianPath('/users/time_zone_options'));
  },
};
