
export const CUSTOM_FIELD_TYPE_STRING = 'string';
export const CUSTOM_FIELD_TYPE_TEXT = 'text';
export const CUSTOM_FIELD_TYPE_DATE = 'date';
export const CUSTOM_FIELD_TYPE_DATETIME = 'datetime';
export const CUSTOM_FIELD_TYPE_BOOLEAN = 'boolean';
export const CUSTOM_FIELD_TYPE_RATING = 'rating';

export const WORK_ORDER_MODEL_TYPE = 'WorkOrder';
export const LOCATION_MODEL_TYPE = 'Location';
export const CLIENT_FEEDBACK_MODEL_TYPE = 'ClientFeedback';