<template>
  <div v-if="supplyOrder">
    <base-page-heading
      :title="title"
      show-back-button
    >
      <template #secondary-title>
        <status-badge
          :status="supplyOrder.status"
          :status-variants="statusVariants"
          :sub-status="supplyOrder.backordered ? 'backordered' : null"
        />
      </template>
      <template #extra>
        <b-button
          v-if="$can('confirm', supplyOrder)"
          :key="`${supplyOrderKey}-confirm-button`"
          v-b-tooltip.hover
          variant="primary"
          class="mr-2"
          title="Confirm the Supply Order"
          v-b-modal.supply-order-confirm-modal
        >
          <i class="fa fa-check mr-1" />
          Confirm
        </b-button>
        <b-button
          v-if="$can('invoice', supplyOrder)"
          :key="`${supplyOrderKey}-invoice-button`"
          variant="primary"
          v-b-modal.supply-order-invoice-modal
          class="mr-2"
        >
          <i class="fa fa-check mr-1" />
          Invoice
        </b-button>
        <b-button
          v-if="$can('update', 'SupplyOrder')"
          variant="primary"
          v-b-modal.supply-order-edit-modal
          class="mr-2"
        >
          <i class="fa fa-pencil-alt mr-1" /> Edit
        </b-button>

        <sfs-download-button
          :filename="`${supplyOrder.number}.pdf`"
          label="Summary"
          :provider-params="{ id: supplyOrder.id }"
          :file-provider="$supplyOrdersAPI.getPDF"
          v-b-tooltip.hover
          title="Download the Summary PDF"
          class="mr-2"
        />

        <b-button
          variant="outline-secondary"
          v-b-modal.purchase-order-email-modal
          v-b-tooltip.hover
          title="Email the Purchase Order"
          class="mr-2"
        >
          <i class="fa fa-envelope" />
        </b-button>

        <b-dropdown
          variant="outline-secondary"
          no-caret
          right
        >
          <template #button-content>
            <i class="fa fa-ellipsis-v" />
          </template>

          <b-dropdown-item
            v-if="$can('update', 'SupplyOrder')"
            @click="toggleActive"
          >
            <i
              v-if="!supplyOrder.deleted_at"
              class="fa fa-trash mr-1"
            />
            <i
              v-else
              class="fa fa-undo mr-1"
            />
            {{ toggleActiveTooltipText }}
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('read', 'Version')"
            :to="{ name: 'versions', query: { model_id: supplyOrder.id, model: 'SupplyOrder' } }"
          >
            <i class="fa fa-glasses mr-1" />
            Audit Log
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </base-page-heading>
    <div class="content pt-5">
      <b-row
        v-if="location.supply_order_internal_notes"
        class="mb-3"
      >
        <b-col>
          <b-alert
            show
            variant="warning"
            class="mb-0"
          >
            <h5 class="mb-0 alert-heading">
              Note:
            </h5>
            <div class="text-prewrap">
              {{ location.supply_order_internal_notes }}
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col lg="6">
          <b-card>
            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                {{ clientTranslation }}
              </b-col>

              <b-col class="text-right">
                <b-link :to="{ name: 'client', params: { id: client.id } }">
                  {{ client.name }}
                </b-link>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Location
              </b-col>
              <b-col class="text-right">
                <b-link
                  :to="{ name: 'location', params: { id: supplyOrder.location_id } }"
                  v-b-tooltip.hover.window
                  :title="`${supplyOrder.location.street}, ${supplyOrder.location.city}, ${supplyOrder.location.state}, ${supplyOrder.location.zip_code}`"
                >
                  {{ supplyOrder.location.store_number }}
                  <span v-if="supplyOrder.location.name">- {{ supplyOrder.location.name }}</span>
                </b-link>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Address
              </b-col>
              <b-col class="text-right">
                <div>{{ supplyOrder.location.street }}</div>
                <div>
                  {{
                    {
                      city: supplyOrder.location.city,
                      state: supplyOrder.location.state,
                      zip_code: supplyOrder.location.zip_code,
                    } | locationFormat
                  }}
                </div>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Billable Total
              </b-col>
              <b-col class="text-right">
                <money-format
                  :value="supplyOrder.price.amount"
                  :currency-code="supplyOrder.price.currency"
                  subunits-value
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Margin
              </b-col>
              <b-col class="text-right">
                <money-format
                  :value="margin"
                  :currency-code="supplyOrder.price.currency"
                  subunits-value
                />
                <div>{{ marginPercentage }}</div>
              </b-col>
            </b-row>

            <b-row
              v-if="supplyOrder.delivery_notes"
              class="mt-3"
            >
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Delivery Notes / Special Instructions
              </b-col>
            </b-row>

            <b-row
              v-if="supplyOrder.delivery_notes"
              class="mt-1"
            >
              <b-col
                cols="auto"
                class="mr-auto"
              >
                <em>{{ supplyOrder.delivery_notes }}</em>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col
          lg="5"
          offset-lg="1"
        >
          <b-card>
            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                <h4>
                  Vendor
                </h4>
              </b-col>
              <b-col class="text-right">
                <sfs-download-button
                  :filename="`Purchase Order ${supplyOrder.number}.pdf`"
                  label="Purchase Order"
                  :provider-params="{ id: supplyOrder.id }"
                  :file-provider="$supplyOrdersAPI.getPurchaseOrderPDF"
                  v-b-tooltip.hover
                  title="Download the Purchase Order"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="auto"
                class="mr-auto"
              />
              <b-col class="text-right">
                <b-link :to="{ name: 'vendor', params: { id: vendor.id } }">
                  {{ vendor.name }}
                </b-link>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              />

              <b-col class="text-right">
                <div>{{ vendor.address }}</div>
                <div>
                  {{
                    {
                      city: vendor.city,
                      state: vendor.state,
                    } | locationFormat
                  }}
                </div>
                <div>{{ vendor.zip_code }}</div>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Date
              </b-col>

              <b-col class="text-right">
                {{ supplyOrder.date | dateFormat }}
              </b-col>
            </b-row>

            <b-row
              v-if="supplyOrder.confirmed_at"
              class="mb-3"
            >
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Confirmed At
              </b-col>

              <b-col class="text-right">
                {{ supplyOrder.confirmed_at | dateTimeFormat }}
              </b-col>
            </b-row>

            <b-row
              v-if="supplyOrder.vendor_number"
              class="mb-3"
            >
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Vendor Invoice Number
              </b-col>

              <b-col class="text-right">
                {{ supplyOrder.vendor_number }}
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Misc Fee
              </b-col>
              <b-col class="text-right">
                <money-format
                  :value="vendor.additional_fee.amount"
                  :currency-code="vendor.additional_fee.currency"
                  subunits-value
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Supplies total
              </b-col>
              <b-col class="text-right">
                <money-format
                  :value="supplyOrder.cost.amount - vendor.additional_fee.amount"
                  :currency-code="supplyOrder.cost.currency"
                  subunits-value
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Tax
              </b-col>
              <b-col class="text-right">
                <money-format
                  :value="supplyOrder.cost_tax.amount"
                  :currency-code="supplyOrder.cost_tax.currency"
                  subunits-value
                />
              </b-col>
            </b-row>

            <hr>

            <b-row>
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Vendor Total
              </b-col>
              <b-col class="text-right">
                <money-format
                  :value="supplyOrder.cost_tax.amount + supplyOrder.cost.amount"
                  :currency-code="supplyOrder.cost_tax.currency"
                  subunits-value
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12">
          <b-tabs
            v-model="activeTab"
            content-class="pt-4"
          >
            <template #tabs-end>
              <div
                v-show="activeTab === 1"
                class="ml-auto"
              >
                <b-button
                  v-if="$can('create', 'Document')"
                  v-b-modal.new-document-modal
                  variant="outline-secondary"
                >
                  <i class="fa fa-plus" />
                </b-button>
              </div>
            </template>
            <b-tab title="Items">
              <supply-order-items-list-filter
                :supply-order="supplyOrder"
                :initial-filters="filters"
                @filters-applied="applyFilters"
                @data-changed="refreshTable"
              />
              <supply-order-items-table
                :columns="supplyOrderItemsColumns"
                :supply-order-id="$route.params.id.toString()"
                :append-to-query="false"
                :filters="filters"
                :key="tableKey"
              />
            </b-tab>

            <b-tab title="Documents">
              <documents-table
                :columns="documentsColumns"
                :append-to-query="false"
                :show-per-page="false"
                :key="documentsKey"
                :list-provider="$documentsAPI.getSupplyOrderDocuments"
                :reference-id="supplyOrder.id"
              />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </div>

    <!-- Modals -->
    <supply-orders-edit-modal
      v-if="$can('update', 'SupplyOrder')"
      :key="`${tableKey}-edit-modal`"
      v-model="supplyOrder"
    />
    <purchase-order-email-modal
      v-model="supplyOrder"
    />
    <confirm-modal
      v-if="$can('confirm', supplyOrder)"
      :value="supplyOrder"
      @input="updateOrder"
    />
    <supply-orders-invoice-modal
      v-if="$can('invoice', supplyOrder)"
      :supply-order="supplyOrder"
      :number="supplyOrder.number"
      :date="invoiceDefaults.date"
      :due_date="invoiceDefaults.dueDate"
      :delivery_fee="{ amount: 1500 }"
      :invoice_format_id="$store.getters.currentOrganization.default_supply_order_invoice_format_id"
      :invoice_format="$store.getters.currentOrganization.default_supply_order_invoice_format"
    />
    <new-document-modal
      v-if="$can('update', 'SupplyOrder')"
      :reference-id="supplyOrder.id"
      :create-provider="$documentsAPI.createSupplyOrderDocument"
      @on-save="refreshDocuments()"
      internal
      :key="documentsKey"
    />
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import StatusBadge from '@/components/shared/StatusBadge.vue';
import SupplyOrdersEditModal from '@/components/admin/supplyOrders/EditModal.vue';
import SupplyOrdersInvoiceModal from '@/components/admin/supplyOrders/InvoiceModal.vue';
import SupplyOrderItemsTable from '@/components/admin/supplyOrderItems/Table.vue';
import SupplyOrderItemsListFilter from '@/components/admin/supplyOrderItems/ListFilter.vue';
import NewDocumentModal from '@/components/admin/documents/NewModal.vue';
import DocumentsTable from '@/components/admin/documents/Table.vue';
import PurchaseOrderEmailModal from '@/components/admin/supplyOrders/PurchaseOrderEmailModal.vue';
import ConfirmModal from '@/components/admin/supplyOrders/ConfirmModal.vue';
import { SUPPLY_ORDER_ITEM_TABLE_NAME } from '@/constants/supplyOrderItems';
import { SUPPLY_ORDER_STATUS_VARIANTS } from '@/constants/supplyOrders';
import { DOCUMENTS_TABLE_NAME } from '@/constants/documents';
import translationMixin from '@/mixins/translationMixin';

export default {
  name: 'SupplyOrdersShow',
  mixins: [translationMixin],
  components: {
    StatusBadge,
    SupplyOrdersEditModal,
    SupplyOrdersInvoiceModal,
    SupplyOrderItemsTable,
    SupplyOrderItemsListFilter,
    NewDocumentModal,
    DocumentsTable,
    PurchaseOrderEmailModal,
    ConfirmModal,
  },
  data() {
    return {
      supplyOrder: null,
      activeTab: 0,
      supplyOrderItemsTable: SUPPLY_ORDER_ITEM_TABLE_NAME,
      documentsTableName: DOCUMENTS_TABLE_NAME,
      tableKey: Date.now(),
      supplyOrderKey: `${Date.now()}`,
      vendorEmail: null,
      documentsKey: `documents-${Date.now()}`,
      filters: {
        text: this.text,
      },
      statusVariants: SUPPLY_ORDER_STATUS_VARIANTS,
    };
  },
  mounted() {
    this.getSupplyOrder();
  },
  computed: {
    title() {
      return `Supply Order ${this.supplyOrder.number}`;
    },
    invoiceDefaults() {
      return {
        date: DateTime.now().toISODate(),
        dueDate: DateTime.now().plus({ day: this.client?.payment_term_days || 0 }).toISODate(),
      };
    },
    client() {
      return this.supplyOrder.client;
    },
    location() {
      return this.supplyOrder.location;
    },
    vendor() {
      return this.supplyOrder.vendor;
    },
    supplyOrderItemsColumns() {
      return this.$store.getters.columnsForTable(this.supplyOrderItemsTable);
    },
    margin() {
      const amount = this.supplyOrder.price.amount - this.supplyOrder.cost.amount;
      return amount < 0 ? 0 : amount;
    },
    marginPercentage() {
      return this.margin === 0 && this.supplyOrder.price.amount === 0 ? '' : this.$options.filters.percentFormatFilter(this.margin / this.supplyOrder.price.amount);
    },
    documentsColumns() {
      return this.$store.getters.columnsForTable(this.documentsTableName);
    },
    toggleActiveTooltipText() {
      return !this.supplyOrder.deleted_at ? 'Cancel' : 'Uncancel';
    },
    toggleActiveConfirmText() {
      return !this.supplyOrder.deleted_at
        ? 'Are you sure you want to cancel the Order?'
        : 'Are you sure you want to uncancel the Order?';
    },
  },
  methods: {
    updateOrder(supplyOrder) {
      this.supplyOrder = supplyOrder;
      this.refreshTable();
      this.supplyOrderKey = `${Date.now()}`;
    },
    getSupplyOrder() {
      this.$supplyOrdersAPI
        .get(this.$route.params.id)
        .then((supplyOrder) => (this.supplyOrder = supplyOrder))
        .catch(() => {
          this.$router.push('/404');
        });
    },
    toggleActive() {
      this.$swal({
        text: this.toggleActiveConfirmText,
        preConfirm: () => this.$supplyOrdersAPI.update(this.$route.params.id, { deleted_at: (!this.supplyOrder.deleted_at ? DateTime.now().toISODate() : null) }),
      }).then((result) => {
        if (result.value) {
          this.supplyOrder = result.value;
        }
        result.timer;
      });
    },
    refreshTable() {
      this.tableKey = Date.now();
    },
    refreshDocuments() {
      this.documentsKey = `documents-${Date.now()}`;
    },
    applyFilters({ filters }) {
      this.filters = Object.assign({}, filters);
    },
  },
};
</script>
