import { PAGINATION_PER_PAGE, PAGINATION_START_PAGE } from '@/constants';

const ARRAY_PROPS = [
  { key: 'statuses', isInt: false },
  { key: 'completion_sources', isInt: false },
  { key: 'services_provided', isInt: false },
  { key: 'cancellation_reasons', isInt: false },
  { key: 'role_ids', isInt: true },
];

/**
 * This function returns a set of props containing all of the query params from a route.
 * If the page and per param are not included in the query, it will use a set of defaults,
 * as defined in `@/constants`.
 * @param {object} route the vue-router route to fetch the pagination query props from.
 */
export default function handlePaginationRouteProps(route) {
  ARRAY_PROPS.forEach(({ key, isInt }) => {
    prepareArrayProp(route, key, isInt);
  });

  return {
    ...route.query,
    sortDesc: route.query.sortDesc ? route.query.sortDesc === 'true' : true,
    currentPage: Number.parseInt(route.query.currentPage) || PAGINATION_START_PAGE,
    perPage: Number.parseInt(route.query.perPage) || PAGINATION_PER_PAGE,
  };
}

function prepareArrayProp(route, propKey, isInt = false) {
  if (isInt && Array.isArray(route.query[propKey])) {
    route.query[propKey] = route.query[propKey].map((roleId) => Number.parseInt(roleId));
  }
  else if (!Array.isArray(route.query[propKey]) && route.query[propKey] && route.query[propKey].length > 0) {
    route.query[propKey] = [route.query[propKey]];
  }
}
