import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const QUOTE_ITEM_MODEL_NAME = 'quote_items';
export const QUOTE_ITEM_TABLE_NAME = 'quoteItems';

// The columns available to be shown in a quoteItems table.
export const QUOTE_ITEM_TABLE_COLUMNS = {
  id: { label: 'ID', key: 'id' },
  quote_id: { label: 'Quote ID', key: 'quote_id' },
  service_id: { label: 'Service ID', key: 'service_id' },
  description: { label: 'Description', key: 'description' },
  quantity: { label: 'Quantity', key: 'quantity' },
  cost: { label: 'Cost', key: 'cost' },
  price: { label: 'Price', key: 'price' },
  taxes: { label: 'Estimated Tax', key: 'taxes' },
  actions: { label: 'Actions', key: 'actions' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const QUOTE_ITEM_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(QUOTE_ITEM_TABLE_COLUMNS);

// The default columns to be shown when the supplyOrderItems table loads.
export const QUOTE_ITEM_TABLE_DEFAULT_COLUMNS = [
  QUOTE_ITEM_TABLE_COLUMNS.description,
  QUOTE_ITEM_TABLE_COLUMNS.quantity,
  QUOTE_ITEM_TABLE_COLUMNS.cost,
  QUOTE_ITEM_TABLE_COLUMNS.price,
  QUOTE_ITEM_TABLE_COLUMNS.taxes,
  QUOTE_ITEM_TABLE_COLUMNS.actions,
];
