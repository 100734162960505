<template>
  <div v-if="workOrder">
    <base-page-heading
      :title="workOrder.number"
      show-back-button
    >
      <template #extra>
        <b-button
          v-if="$can('reassign', workOrder)"
          class="mr-2"
          v-b-modal.technician-work-order-reassign-modal
          variant="primary"
        >
          Reassign
        </b-button>
      </template>
    </base-page-heading>
    <div class="content">
      <b-row class="mb-3">
        <b-col
          md="7"
          lg="7"
        >
          <b-card class="h-100">
            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Status
              </b-col>
              <b-col class="text-right">
                <status-badge
                  :status="workOrder.status"
                  :status-variants="statusVariants"
                />
              </b-col>
            </b-row>

            <b-row
              v-if="workOrder.cancellation_reason"
              class="mb-3"
            >
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Cancellation Reason
              </b-col>
              <b-col class="text-right">
                {{ workOrder.cancellation_reason | humanizedFormat | capitalizeFormat }}
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Date Bucket
              </b-col>
              <b-col class="text-right">
                {{ workOrder | dateRangeFormat("date_bucket_start", "date_bucket_end") }}
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Location
              </b-col>
              <b-col class="text-right">
                <div
                  v-b-tooltip.hover
                  :title="`${workOrder.location.street}, ${workOrder.location.city}, ${workOrder.location.state}, ${workOrder.location.county}, ${workOrder.location.zip_code}`"
                >
                  {{ workOrder.location.store_number }}
                  <span v-if="workOrder.location.name">- {{ workOrder.location.name }}</span>
                </div>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Address
              </b-col>
              <b-col class="text-right">
                <div>{{ workOrder.location.street }}</div>
                <div>
                  {{
                    {
                      city: workOrder.location.city,
                      state: workOrder.location.state,
                      zip_code: workOrder.location.zip_code,
                    } | locationFormat
                  }}
                </div>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Client
              </b-col>
              <b-col class="text-right">
                <div>
                  {{ workOrder.client.name }}
                </div>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Description
              </b-col>
              <b-col class="text-right">
                {{ workOrder.description }}
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Maintenance Type
              </b-col>
              <b-col class="text-right">
                {{ workOrder.maintainance_type }}
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Completed By
              </b-col>
              <b-col class="text-right">
                <b-link :to="{ name: 'technician-user', params: { id: workOrder.done_by.id } }">
                  {{ workOrder.done_by.name }}
                </b-link>
              </b-col>
            </b-row>

            <b-row
              v-if="workOrder.completion_source"
              class="mt-3"
            >
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Completion Source
              </b-col>
              <b-col class="text-right">
                {{ workOrder.completion_source | humanizedFormat | capitalizeFormat }}
              </b-col>
            </b-row>
            <div
              v-if="user.id === workOrder.assigned_to.id"
            >
              <h4 class="mb-3 mt-3">
                Payment
              </h4>

              <b-row
                v-if="technicianPayments.length > 0"
                class="mb-3"
              >
                <b-col
                  cols="auto"
                  class="mr-auto"
                >
                  Date
                </b-col>
                <b-col class="text-right">
                  <div
                    v-for="(technicianPayment, index) in technicianPayments"
                    :key="index"
                  >
                    <b-link :to="{ name: 'technician-user-payment', params: { id: technicianPayment.id } }">
                      {{ technicianPayment.due_date | dateFormat }}
                    </b-link>
                  </div>
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col
                  cols="auto"
                  class="mr-auto"
                >
                  Status
                </b-col>
                <b-col class="text-right">
                  <status-badge
                    :status="workOrder.payment_status"
                    :status-variants="paymentStatusVariants"
                  />
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col
                  cols="auto"
                  class="mr-auto"
                >
                  Amount
                </b-col>
                <b-col class="text-right">
                  <money-format
                    :value="workOrder.cost.amount"
                    :currency-code="workOrder.cost.currency"
                    subunits-value
                  />
                </b-col>
              </b-row>
            </div>

            <b-row>
              <b-col class="text-right">
                Last updated {{ workOrder.updated_at | dateTimeFormat }}
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-right">
                Created on {{ workOrder.created_at | dateTimeFormat }}
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col
          md="5"
          lg="5"
        >
          <b-row class="mb-3">
            <b-col
              v-if="workOrder.checked_in_at"
              md="6"
            >
              <sfs-metric-card
                title="Check-in Date"
                icon-class="far fa-clock fa-2x"
              >
                <template #content>
                  <h4>
                    {{ workOrder.checked_in_at | dateTimeFormat(workOrder.location.time_zone) }}
                  </h4>
                </template>
              </sfs-metric-card>
            </b-col>

            <b-col
              v-if="workOrder.checked_out_at"
              md="6"
            >
              <sfs-metric-card
                title="Check-out Date"
                icon-class="far fa-clock fa-2x"
              >
                <template #content>
                  <h4>
                    {{ workOrder.checked_out_at | dateTimeFormat(workOrder.location.time_zone) }}
                  </h4>
                </template>
              </sfs-metric-card>
            </b-col>
          </b-row>

          <b-card>
            <b-tabs
              v-model="activeTab"
              content-class="pt-3"
            >
              <b-tab title="Photos">
                <b-nav
                  pills
                  class="mx-4 mb-3"
                >
                  <b-nav-item
                    @click="activePhotoType = 0"
                    :active="activePhotoType === 0"
                  >
                    Before Photos
                  </b-nav-item>
                  <b-nav-item
                    @click="activePhotoType = 1"
                    :active="activePhotoType === 1"
                  >
                    After Photos
                  </b-nav-item>
                  <b-nav-item
                    @click="activePhotoType = 2"
                    :active="activePhotoType === 2"
                  >
                    No Show Photos
                  </b-nav-item>
                </b-nav>

                <div v-show="activePhotoType === 0">
                  <photo-carousel-modal
                    modal-id="before-photos-modal"
                    :images="workOrder.before_photos"
                    :work-order="workOrder"
                    before-photos
                    @on-save="getWorkOrder"
                    @on-destroy="getWorkOrder"
                  />
                </div>

                <div v-show="activePhotoType === 1">
                  <photo-carousel-modal
                    modal-id="after-photos-modal"
                    :images="workOrder.after_photos"
                    :work-order="workOrder"
                    after-photos
                    @on-save="getWorkOrder"
                    @on-destroy="getWorkOrder"
                  />
                </div>

                <div v-show="activePhotoType === 2">
                  <photo-carousel-modal
                    modal-id="no-show-photos-modal"
                    :images="workOrder.no_show_photos"
                    :work-order="workOrder"
                    :before-photos="false"
                    :after-photos="false"
                    @on-save="getWorkOrder"
                    @on-destroy="getWorkOrder"
                  />
                </div>
              </b-tab>

              <b-tab title="Locations">
                <map-with-markers
                  :center="mapCenter"
                  :marker-distance="workOrder.check_in_distance"
                  :markers="[
                    { coord: { lat: parseFloat(workOrder.location.latitude), lng: parseFloat(workOrder.location.longitude) }, label: 'Work Order Location' },
                    { coord: { lat: parseFloat(workOrder.latitude), lng: parseFloat(workOrder.longitude) }, label: 'Check in Location' }
                  ]"
                />
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>

      <b-row v-if="hasSignature">
        <b-col>
          <b-card class="mb-3">
            <h4>
              Manager Review
            </h4>

            <b-row class="pb-3">
              <b-col
                md="7"
                class="border-right"
              >
                <b-row>
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    Manager Name
                  </b-col>

                  <b-col
                    cols="auto"
                  >
                    {{ workOrder.manager_review?.name }}
                  </b-col>
                </b-row>

                <b-row>
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    Manager Comment
                  </b-col>

                  <b-col
                    cols="auto"
                  >
                    {{ workOrder.manager_review?.comments }}
                  </b-col>
                </b-row>

                <b-row>
                  <b-col
                    cols="auto"
                    class="mr-auto p-3"
                  >
                    Rating
                  </b-col>

                  <b-col
                    cols="auto"
                  >
                    <star-rating
                      :rating="workOrder.manager_review?.rating"
                      :star-size="36"
                      :show-rating="false"
                      read-only
                      active-color="#084298"
                    />
                  </b-col>
                </b-row>
              </b-col>

              <b-col md="5">
                <b-row class="h-100">
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    <img
                      v-if="signatureUrl"
                      class="img-fluid img-signature"
                      :src="signatureUrl"
                    >
                    <p
                      v-else
                      class="pt-4"
                    >
                      Signature not found.
                    </p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <!-- Modals -->
    <reassign-modal
      :value="workOrder"
      @input="onWorkOrderChanged"
    />
  </div>
</template>

<script>
import StarRating from 'vue-star-rating';
import StatusBadge from '@/components/shared/StatusBadge.vue';
import PhotoCarouselModal from '@/components/technician/workOrders/PhotoCarouselModal.vue';
import ReassignModal from '@/components/technician/workOrders/ReassignModal.vue';
import { isNil as _isNil } from 'lodash';
import { TECHNICIAN_STATUS_VARIANTS } from '@/constants/workOrders';
import { STATUS_VARIANTS } from '@/constants/technicianPayments';
import MapWithMarkers from '@/components/shared/MapWithMarkers.vue';

export default {
  name: 'TechnicianWorkOrdersShow',
  components: {
    StarRating,
    StatusBadge,
    PhotoCarouselModal,
    ReassignModal,
    MapWithMarkers
  },
  data() {
    return {
      workOrder: null,
      statusVariants: TECHNICIAN_STATUS_VARIANTS,
      paymentStatusVariants: STATUS_VARIANTS,
      technicianPayments: [],
      activeTab: 0,
      activePhotoType: 0
    };
  },
  mounted() {
    this.getWorkOrder();
    this.getTechnicianPayments();
  },
  methods: {
    getWorkOrder() {
      this.$technicianWorkOrdersAPI
        .get(this.$route.params.id)
        .then((workOrder) => {
          this.workOrder = workOrder;
        })
        .catch(() => {
          this.$router.push('/404');
        });
    },
    getTechnicianPayments() {
      this.$technicianUserPaymentsAPI.getTechnicianPayments({ work_order_id: this.$route.params.id }).then((response) => {
        this.technicianPayments = response.technicianPayments;
      });
    },
    onWorkOrderChanged(workOrder) {
      this.workOrder = workOrder;
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    hasSignature() {
      return !_isNil(this.workOrder.manager_review);
    },
    signatureUrl() {
      return this?.workOrder?.manager_review?.signature?.url;
    }
  },
};
</script>
<style scoped>
.img-signature {
  max-height: 100px;
}
</style>
