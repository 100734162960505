<template>
  <b-input-group>
    <flat-pickr
      :id="pickerId"
      :value="value"
      :config="flatpickrConfig"
      :placeholder="placeholder"
      :disabled="disabled"
      :class="{ 'flat-pickr': true, 'form-control': true, ... withClass }"
      @input="(dates) => $emit('input', dates)"
      @on-change="(dates) => $emit('on-change', dates)"
    />
    <b-input-group-append
      class="flatpickr-icon-container"
      :readonly="disabled"
      @click="openFlatpickr"
    >
      <i class="si si-calendar" />
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import flatpickr from 'flatpickr';
import { uniqueId as _uniqueId } from 'lodash';
export default {
  name: 'DateRangePicker',
  props: {
    id: {
      type: String,
    },
    value: {
      type: [Array, String],
    },
    withClass: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    static: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
    }
  },
  data() {
    return {
      flatpickrConfig: { mode: 'range', dateFormat: 'M j, Y', static: this.static, clickOpens: !this.disabled },
      pickerId: this.id || `sfs-date-range-picker-${_uniqueId()}`,
    };
  },
  methods: {
    openFlatpickr() {
      if (!this.disabled) {
        const fp = flatpickr(`#${this.pickerId}`, this.flatpickrConfig);
        fp.open();
        fp.config.onChange.push((dates) => this.$emit('on-change', dates));
      }
    }
  },
};
</script>
