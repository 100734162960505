<template>
  <star-rating
    :id="ratingId"
    :name="name"
    :rating="value"
    :read-only="disabled"
    :show-rating="false"
    @rating-selected="(newRating) => $emit('input', newRating)"
    active-color="#084298"
    inactive-color="#DEE2E6"
    :rounded-corners="true"
    clearable
    :star-size="36"
    :padding="6"
    :max-rating="maxRating"
  />
</template>

<script>
import StarRating from 'vue-star-rating';
import { uniqueId as _uniqueId } from 'lodash';

export default {
  name: 'RatingPicker',
  components: {
    StarRating,
  },
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    value: {
      type: Number,
    },
    maxRating: {
      type: Number,
      default: 5,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ratingId: this.id || `sfs-rating-picker-${_uniqueId()}`,
    };
  },
};
</script>
