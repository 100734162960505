import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { technicianPath } from '@/api/helpers/apiPath';

export default {
  getLocations(params) {
    return axios.get(technicianPath('/locations?') + prepareQueryString(params)).then((response) => {
      return {
        locations: response.data.map((location) => an('Location', location)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  get(id) {
    return axios.get(technicianPath(`/locations/${id}`)).then((response) => an('Location', response.data));
  },
};
