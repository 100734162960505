import { CLIENT_FEEDBACK_TABLE_NAME, CLIENT_FEEDBACK_TABLE_DEFAULT_COLUMNS, CLIENT_FEEDBACK_TABLE_COLUMNS } from '@/constants/clientFeedbacks';
import { CLIENT_TABLE_NAME, CLIENT_TABLE_DEFAULT_COLUMNS, CLIENT_TABLE_COLUMNS } from '@/constants/clients';
import { DELAYED_JOB_TABLE_NAME, DELAYED_JOB_TABLE_DEFAULT_COLUMNS, DELAYED_JOB_TABLE_COLUMNS } from '@/constants/delayedJobs';
import { COMMUNICATION_TABLE_NAME, INSURANCE_CERTIFICATE_COMMUNICATION_TABLE_NAME, LIST_MODAL_COMMUNICATION_TABLE_NAME, COMMUNICATION_TABLE_DEFAULT_COLUMNS, COMMUNICATION_TABLE_COLUMNS } from '@/constants/communications';
import { DOCUMENTS_TABLE_NAME, DOCUMENTS_TABLE_DEFAULT_COLUMNS, DOCUMENTS_TABLE_COLUMNS } from '@/constants/documents';
import { GL_CODE_TABLE_NAME, GL_CODE_TABLE_DEFAULT_COLUMNS, GL_CODE_TABLE_COLUMNS } from '@/constants/glCodes';
import { IMPORT_TABLE_NAME, IMPORT_TABLE_DEFAULT_COLUMNS, IMPORT_TABLE_COLUMNS } from '@/constants/imports';
import { INSURANCE_CERTIFICATE_COVERAGES_TABLE_NAME, INSURANCE_CERTIFICATE_COVERAGES_TABLE_DEFAULT_COLUMNS, INSURANCE_CERTIFICATE_COVERAGES_TABLE_COLUMNS } from '@/constants/insuranceCertificateCoverages';
import { INSURANCE_CERTIFICATE_TABLE_NAME, INSURANCE_CERTIFICATE_TABLE_DEFAULT_COLUMNS, INSURANCE_CERTIFICATE_TABLE_COLUMNS } from '@/constants/insuranceCertificates';
import { INSURANCE_CERTIFICATE_VERIFICATIONS_TABLE_NAME, INSURANCE_CERTIFICATE_VERIFICATIONS_TABLE_DEFAULT_COLUMNS, INSURANCE_CERTIFICATE_VERIFICATIONS_TABLE_COLUMNS } from '@/constants/insuranceCertificateVerifications';
import { INVOICE_ITEM_TABLE_NAME, INVOICE_ITEM_TABLE_DEFAULT_COLUMNS, INVOICE_ITEM_TABLE_COLUMNS } from '@/constants/invoiceItems';
import { INVOICE_TABLE_NAME, INVOICE_TABLE_DEFAULT_COLUMNS, INVOICE_TABLE_COLUMNS } from '@/constants/invoices';
import { LOCATION_TABLE_NAME, CLIENT_LOCATION_TABLE_NAME, LOCATION_TABLE_DEFAULT_COLUMNS, CLIENT_LOCATION_TABLE_DEFAULT_COLUMNS, LOCATION_TABLE_COLUMNS } from '@/constants/locations';
import { QUOTE_TABLE_NAME, QUOTE_TABLE_DEFAULT_COLUMNS, QUOTE_TABLE_COLUMNS } from '@/constants/quotes';
import { RECURRING_CHARGE_TABLE_NAME, RECURRING_CHARGE_TABLE_DEFAULT_COLUMNS, RECURRING_CHARGE_TABLE_COLUMNS } from '@/constants/recurringCharges';
import { RECURRING_PAYMENT_TABLE_NAME, RECURRING_PAYMENT_TABLE_DEFAULT_COLUMNS, RECURRING_PAYMENT_TABLE_COLUMNS } from '@/constants/recurringPayments';
import { RECURRING_WORK_ORDER_TABLE_NAME, RECURRING_WORK_ORDER_TABLE_DEFAULT_COLUMNS, RECURRING_WORK_ORDER_TABLE_COLUMNS } from '@/constants/recurringWorkOrders';
import { SERVICE_TABLE_NAME, SERVICE_TABLE_DEFAULT_COLUMNS, SERVICE_TABLE_COLUMNS } from '@/constants/services';
import { VENDOR_SUPPLY_TABLE_NAME, CLIENT_SUPPLY_TABLE_NAME, VENDOR_SUPPLY_TABLE_DEFAULT_COLUMNS, CLIENT_SUPPLY_TABLE_DEFAULT_COLUMNS, SUPPLY_TABLE_COLUMNS } from '@/constants/supplies';
import { SUPPLY_ORDER_TABLE_NAME, SUPPLY_ORDER_TABLE_DEFAULT_COLUMNS, SUPPLY_ORDER_TABLE_COLUMNS } from '@/constants/supplyOrders';
import { ADDITIONAL_CHARGES_TABLE_NAME, ADDITIONAL_CHARGES_TABLE_DEFAULT_COLUMNS, ADDITIONAL_CHARGES_TABLE_COLUMNS } from '@/constants/additionalCharges';
import { TAX_RATE_TABLE_NAME, TAX_RATE_TABLE_DEFAULT_COLUMNS, TAX_RATE_TABLE_COLUMNS } from '@/constants/taxRates';
import { TECHNICIAN_PAYMENT_ITEM_TABLE_NAME, TECHNICIAN_PAYMENT_ITEM_TABLE_DEFAULT_COLUMNS, TECHNICIAN_PAYMENT_ITEM_TABLE_COLUMNS } from '@/constants/technicianPaymentItems';
import { TECHNICIAN_PAYMENT_TABLE_NAME, TECHNICIAN_PAYMENT_TABLE_DEFAULT_COLUMNS, TECHNICIAN_PAYMENT_TABLE_COLUMNS } from '@/constants/technicianPayments';
import { TECHNICIAN_SERVICES_TABLE_NAME, TECHNICIAN_SERVICES_TABLE_DEFAULT_COLUMNS, TECHNICIAN_SERVICES_TABLE_COLUMNS } from '@/constants/technicianServices';
import { USER_OWNERSHIPS_TABLE_NAME, USER_OWNERSHIPS_TABLE_DEFAULT_COLUMNS, USER_OWNERSHIPS_TABLE_COLUMNS } from '@/constants/userOwnerships';
import { USER_TABLE_NAME, USER_TABLE_DEFAULT_COLUMNS, USER_TABLE_COLUMNS } from '@/constants/users';
import { VENDOR_TABLE_NAME, VENDOR_TABLE_DEFAULT_COLUMNS, VENDOR_TABLE_COLUMNS } from '@/constants/vendors';
import { VERSION_TABLE_NAME, VERSION_TABLE_DEFAULT_COLUMNS, VERSION_TABLE_COLUMNS } from '@/constants/versions';
import { WALKTHROUGH_TABLE_NAME, WALKTHROUGH_TABLE_DEFAULT_COLUMNS, WALKTHROUGH_TABLE_COLUMNS } from '@/constants/walkthroughs';
import { WORK_ORDER_CHARGES_TABLE_NAME, WORK_ORDER_CHARGES_TABLE_DEFAULT_COLUMNS, WORK_ORDER_CHARGES_TABLE_COLUMNS } from '@/constants/workOrderCharges';
import { WORK_ORDER_TABLE_NAME, WORK_ORDER_TABLE_DEFAULT_COLUMNS, WORK_ORDER_TABLE_COLUMNS } from '@/constants/workOrders';
import { WORK_ORDER_PROFITABILITY_REPORT_TABLE_DEFAULT_COLUMNS, WORK_ORDER_PROFITABILITY_REPORT_TABLE_NAME, WORK_ORDER_PROFITABILITY_REPORT_TABLE_COLUMNS } from '@/constants/reports';
import { WORK_ORDER_REVENUE_REPORT_TABLE_DEFAULT_COLUMNS, WORK_ORDER_REVENUE_REPORT_TABLE_NAME, WORK_ORDER_REVENUE_REPORT_TABLE_COLUMNS } from '@/constants/reports';
import { CLIENT_FEEDBACK_LOCATION_SEARCH_REPORT_TABLE_DEFAULT_COLUMNS, CLIENT_FEEDBACK_LOCATION_SEARCH_REPORT_TABLE_NAME, CLIENT_FEEDBACK_LOCATION_SEARCH_REPORT_TABLE_COLUMNS } from '@/constants/reports';

// TECHNICIAN CONSTANTS
import { TECHNICIAN_WORK_ORDER_TABLE_NAME, TECHNICIAN_WORK_ORDER_TABLE_DEFAULT_COLUMNS, TECHNICIAN_WORK_ORDER_TABLE_COLUMNS } from '@/constants/workOrders';
import { TECHNICIAN_USER_TABLE_NAME, TECHNICIAN_USER_TABLE_DEFAULT_COLUMNS, TECHNICIAN_USER_TABLE_COLUMNS } from '@/constants/users';
import { TECHNICIAN_USER_PAYMENT_TABLE_NAME, TECHNICIAN_USER_PAYMENT_TABLE_DEFAULT_COLUMNS, TECHNICIAN_USER_PAYMENT_TABLE_COLUMNS } from '@/constants/technicianPayments';

// The default number of table items to show per page.
export const PAGINATION_PER_PAGE = 10;
// The default starting page for tables.
export const PAGINATION_START_PAGE = 1;

// The maximum number of results that can be fetched through at once when using an endpoint that utilizies elasticsearch.
export const PAGINATION_MAX_RESULTS = 10000;

export const ACTIVE_TABLE_FILTER_OPTIONS = [
  { text: 'Active', value: true },
  { text: 'Inactive', value: false },
  { text: 'Both', value: null },
];

// The current API version number of the backend.
export const API_VERSION = 'v1';
export const API_ADMIN_PREFIX = 'admin';
export const API_TECHNICIAN_PREFIX = 'technician';

export const USER_EDIT_PROFILE_MODAL_ID = 'edit-profile-modal';
export const USER_UPDATE_PASSWORD_MODAL_ID = 'update-password-modal';
export const USER_CHANGE_ORGANIZATION_MODAL_ID = 'change-organization-modal';
export const USER_DISABLE_TWO_FACTOR_AUTH_MODAL_ID = 'disable-mfa-auth-modal';
export const USER_REGISTER_TWO_FACTOR_AUTH_MODAL_ID = 'register-mfa-auth-modal';
export const QR_CODE_REDIRECTION_PATH = '#/upload-work-order/';

export const MAX_FILE_SIZE_BYTES = 5000000;

export const DAY_TIME_GROUPING = 'day';
export const WEEK_TIME_GROUPING = 'week';
export const MONTH_TIME_GROUPING = 'month';
export const QUARTER_TIME_GROUPING = 'quarter';

export const FONT_FAMILY = '"DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
export const CHART_COLOUR_PRIMARY = '#0D6EFD';
export const CHART_COLOUR_PRIMARY_DARK = '#0A58CA';
export const CHART_COLOUR_PRIMARY_LIGHT = '#9EC5FE';
export const CHART_COLOUR_ORANGE= '#FD7E14';
export const CHART_COLOUR_BROWN= '#AE6A20';
export const CHART_COLOUR_PALETTE = [CHART_COLOUR_PRIMARY, CHART_COLOUR_PRIMARY_DARK, CHART_COLOUR_PRIMARY_LIGHT, CHART_COLOUR_ORANGE, CHART_COLOUR_BROWN];

export const DEFAULT_TABLE_COLUMNS = {
  [CLIENT_FEEDBACK_TABLE_NAME]: {
    default: CLIENT_FEEDBACK_TABLE_DEFAULT_COLUMNS,
    columns: CLIENT_FEEDBACK_TABLE_COLUMNS,
  },
  [CLIENT_TABLE_NAME]: {
    default: CLIENT_TABLE_DEFAULT_COLUMNS,
    columns: CLIENT_TABLE_COLUMNS,
  },
  [DELAYED_JOB_TABLE_NAME]: {
    default: DELAYED_JOB_TABLE_DEFAULT_COLUMNS,
    columns: DELAYED_JOB_TABLE_COLUMNS,
  },
  [CLIENT_LOCATION_TABLE_NAME]: {
    default: CLIENT_LOCATION_TABLE_DEFAULT_COLUMNS,
    columns: LOCATION_TABLE_COLUMNS,
  },
  [CLIENT_SUPPLY_TABLE_NAME]: {
    default: CLIENT_SUPPLY_TABLE_DEFAULT_COLUMNS,
    columns: SUPPLY_TABLE_COLUMNS,
  },
  [COMMUNICATION_TABLE_NAME]: {
    default: COMMUNICATION_TABLE_DEFAULT_COLUMNS,
    columns: COMMUNICATION_TABLE_COLUMNS,
  },
  [DOCUMENTS_TABLE_NAME]: {
    default: DOCUMENTS_TABLE_DEFAULT_COLUMNS,
    columns: DOCUMENTS_TABLE_COLUMNS,
  },
  [GL_CODE_TABLE_NAME]: {
    default: GL_CODE_TABLE_DEFAULT_COLUMNS,
    columns: GL_CODE_TABLE_COLUMNS,
  },
  [IMPORT_TABLE_NAME]: {
    default: IMPORT_TABLE_DEFAULT_COLUMNS,
    columns: IMPORT_TABLE_COLUMNS,
  },
  [INSURANCE_CERTIFICATE_COVERAGES_TABLE_NAME]: {
    default: INSURANCE_CERTIFICATE_COVERAGES_TABLE_DEFAULT_COLUMNS,
    columns: INSURANCE_CERTIFICATE_COVERAGES_TABLE_COLUMNS,
  },
  [INSURANCE_CERTIFICATE_TABLE_NAME]: {
    default: INSURANCE_CERTIFICATE_TABLE_DEFAULT_COLUMNS,
    columns: INSURANCE_CERTIFICATE_TABLE_COLUMNS,
  },
  [INSURANCE_CERTIFICATE_VERIFICATIONS_TABLE_NAME]: {
    default: INSURANCE_CERTIFICATE_VERIFICATIONS_TABLE_DEFAULT_COLUMNS,
    columns: INSURANCE_CERTIFICATE_VERIFICATIONS_TABLE_COLUMNS,
  },
  [INVOICE_ITEM_TABLE_NAME]: {
    default: INVOICE_ITEM_TABLE_DEFAULT_COLUMNS,
    columns: INVOICE_ITEM_TABLE_COLUMNS,
  },
  [INVOICE_TABLE_NAME]: {
    default: INVOICE_TABLE_DEFAULT_COLUMNS,
    columns: INVOICE_TABLE_COLUMNS,
  },
  [LOCATION_TABLE_NAME]: {
    default: LOCATION_TABLE_DEFAULT_COLUMNS,
    columns: LOCATION_TABLE_COLUMNS,
  },
  [QUOTE_TABLE_NAME]: {
    default: QUOTE_TABLE_DEFAULT_COLUMNS,
    columns: QUOTE_TABLE_COLUMNS,
  },
  [RECURRING_CHARGE_TABLE_NAME]: {
    default: RECURRING_CHARGE_TABLE_DEFAULT_COLUMNS,
    columns: RECURRING_CHARGE_TABLE_COLUMNS,
  },
  [RECURRING_PAYMENT_TABLE_NAME]: {
    default: RECURRING_PAYMENT_TABLE_DEFAULT_COLUMNS,
    columns: RECURRING_PAYMENT_TABLE_COLUMNS,
  },
  [RECURRING_WORK_ORDER_TABLE_NAME]: {
    default: RECURRING_WORK_ORDER_TABLE_DEFAULT_COLUMNS,
    columns: RECURRING_WORK_ORDER_TABLE_COLUMNS,
  },
  [SERVICE_TABLE_NAME]: {
    default: SERVICE_TABLE_DEFAULT_COLUMNS,
    columns: SERVICE_TABLE_COLUMNS,
  },
  [SUPPLY_ORDER_TABLE_NAME]: {
    default: SUPPLY_ORDER_TABLE_DEFAULT_COLUMNS,
    columns: SUPPLY_ORDER_TABLE_COLUMNS,
  },
  [ADDITIONAL_CHARGES_TABLE_NAME]: {
    default: ADDITIONAL_CHARGES_TABLE_DEFAULT_COLUMNS,
    columns: ADDITIONAL_CHARGES_TABLE_COLUMNS,
  },
  [TAX_RATE_TABLE_NAME]: {
    default: TAX_RATE_TABLE_DEFAULT_COLUMNS,
    columns: TAX_RATE_TABLE_COLUMNS,
  },
  [TECHNICIAN_PAYMENT_ITEM_TABLE_NAME]: {
    default: TECHNICIAN_PAYMENT_ITEM_TABLE_DEFAULT_COLUMNS,
    columns: TECHNICIAN_PAYMENT_ITEM_TABLE_COLUMNS,
  },
  [TECHNICIAN_PAYMENT_TABLE_NAME]: {
    default: TECHNICIAN_PAYMENT_TABLE_DEFAULT_COLUMNS,
    columns: TECHNICIAN_PAYMENT_TABLE_COLUMNS,
  },
  [TECHNICIAN_SERVICES_TABLE_NAME]: {
    default: TECHNICIAN_SERVICES_TABLE_DEFAULT_COLUMNS,
    columns: TECHNICIAN_SERVICES_TABLE_COLUMNS,
  },
  [USER_OWNERSHIPS_TABLE_NAME]: {
    default: USER_OWNERSHIPS_TABLE_DEFAULT_COLUMNS,
    columns: USER_OWNERSHIPS_TABLE_COLUMNS,
  },
  [USER_TABLE_NAME]: {
    default: USER_TABLE_DEFAULT_COLUMNS,
    columns: USER_TABLE_COLUMNS,
  },
  [VENDOR_TABLE_NAME]: {
    default: VENDOR_TABLE_DEFAULT_COLUMNS,
    columns: VENDOR_TABLE_COLUMNS,
  },
  [VENDOR_SUPPLY_TABLE_NAME]: {
    default: VENDOR_SUPPLY_TABLE_DEFAULT_COLUMNS,
    columns: SUPPLY_TABLE_COLUMNS,
  },
  [VERSION_TABLE_NAME]: {
    default: VERSION_TABLE_DEFAULT_COLUMNS,
    columns: VERSION_TABLE_COLUMNS,
  },
  [WALKTHROUGH_TABLE_NAME]: {
    default: WALKTHROUGH_TABLE_DEFAULT_COLUMNS,
    columns: WALKTHROUGH_TABLE_COLUMNS,
  },
  [WORK_ORDER_CHARGES_TABLE_NAME]: {
    default: WORK_ORDER_CHARGES_TABLE_DEFAULT_COLUMNS,
    columns: WORK_ORDER_CHARGES_TABLE_COLUMNS,
  },
  [WORK_ORDER_TABLE_NAME]: {
    default: WORK_ORDER_TABLE_DEFAULT_COLUMNS,
    columns: WORK_ORDER_TABLE_COLUMNS,
  },
  [WORK_ORDER_PROFITABILITY_REPORT_TABLE_NAME]: {
    default: WORK_ORDER_PROFITABILITY_REPORT_TABLE_DEFAULT_COLUMNS,
    columns: WORK_ORDER_PROFITABILITY_REPORT_TABLE_COLUMNS,
  },
  [WORK_ORDER_REVENUE_REPORT_TABLE_NAME]: {
    default: WORK_ORDER_REVENUE_REPORT_TABLE_DEFAULT_COLUMNS,
    columns: WORK_ORDER_REVENUE_REPORT_TABLE_COLUMNS,
  },
  [CLIENT_FEEDBACK_LOCATION_SEARCH_REPORT_TABLE_NAME]: {
    default: CLIENT_FEEDBACK_LOCATION_SEARCH_REPORT_TABLE_DEFAULT_COLUMNS,
    columns: CLIENT_FEEDBACK_LOCATION_SEARCH_REPORT_TABLE_COLUMNS,
  },
  [INSURANCE_CERTIFICATE_COMMUNICATION_TABLE_NAME]: {

  },
  [LIST_MODAL_COMMUNICATION_TABLE_NAME]: {
    default: COMMUNICATION_TABLE_DEFAULT_COLUMNS,
    columns: COMMUNICATION_TABLE_COLUMNS,
  },
  [TECHNICIAN_WORK_ORDER_TABLE_NAME]: {
    default: TECHNICIAN_WORK_ORDER_TABLE_DEFAULT_COLUMNS,
    columns: TECHNICIAN_WORK_ORDER_TABLE_COLUMNS,
  },
  [TECHNICIAN_USER_TABLE_NAME]: {
    default: TECHNICIAN_USER_TABLE_DEFAULT_COLUMNS,
    columns: TECHNICIAN_USER_TABLE_COLUMNS,
  },
  [TECHNICIAN_USER_PAYMENT_TABLE_NAME]: {
    default: TECHNICIAN_USER_PAYMENT_TABLE_DEFAULT_COLUMNS,
    columns: TECHNICIAN_USER_PAYMENT_TABLE_COLUMNS,
  },
};
