<template>
  <sfs-base-list-filter
    title="Filter Search Data"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="availableColumns"
    :column-picker="false"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
    :advanced-filter="false"
    :popover-filter="true"
  >
    <template #quick-filters>
      <b-form-group
        label="Feedback received prior to"
        label-for="quick-filter-date"
        label-sr-only
      >
        <div class="d-flex">
          <sfs-date-picker
            id="quick-filter-feedback_last_received_at"
            :value="filters.feedback_last_received_at"
            placeholder="Feedback received prior to"
            @input="onFeedbackLastReceivedAtChanged"
          />
          <b-input-group-append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </b-input-group-append>
        </div>
        <small
          class="form-text text-muted text-nowrap"
        >
          Select a date to see Locations where feedback was received before the provided date.
        </small>
      </b-form-group>
    </template>

    <template #popover-filters>
      <b-row>
        <b-col>
          <b-form-group
            label="Client"
            label-for="advanced-filter-client_id"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <client-select
              id="advanced-filter-client_id"
              placeholder="All"
              :value="client"
              @input="onClientChange"
              :persist-state="true"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Location"
            label-for="advanced-filter-location_id"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <location-select
              id="advanced-filter-location"
              placeholder="All"
              :client-id="filters.client_id"
              :value="location"
              @input="onLocationChange"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>

    <template #right-tray>
      <sfs-download-button
        v-b-tooltip.hover
        :provider-params="filters"
        filename="Client Feedback Locations Report.csv"
        :file-provider="$analyticsAPI.getClientFeedbacksLocationsReportSpreadsheet"
        title="Download spreadsheet"
      />
    </template>
  </sfs-base-list-filter>
</template>
<script>
import ClientSelect from '@/components/admin/clients/Select.vue';
import LocationSelect from '@/components/admin/locations/Select.vue';
import listFilterMixin from '@/mixins/listFilterMixin';

import {
  CLIENT_FEEDBACK_LOCATION_SEARCH_REPORT_TABLE_NAME,
  CLIENT_FEEDBACK_LOCATION_SEARCH_REPORT_TABLE_COLUMN_OPTIONS,
  CLIENT_FEEDBACK_LOCATION_SEARCH_REPORT_TABLE_DEFAULT_COLUMNS,
}
from '@/constants/reports';
export default {
  name: 'WorkOrderListFilter',
  mixins: [listFilterMixin],
  components: {
    ClientSelect,
    LocationSelect,
  },
  data() {
    return {
      availableColumns: CLIENT_FEEDBACK_LOCATION_SEARCH_REPORT_TABLE_COLUMN_OPTIONS,
      tableName: CLIENT_FEEDBACK_LOCATION_SEARCH_REPORT_TABLE_NAME,
    };
  },
  mounted() {
    this.loadInitialFilters();
  },
  methods: {
    applyPresetFilter(filters, columns) {
      this.filters = filters;
      this.displayedColumns = columns;
      this.applyFilters();
    },
    onDefaultPresetApplied() {
      this.applyPresetFilter({}, CLIENT_FEEDBACK_LOCATION_SEARCH_REPORT_TABLE_DEFAULT_COLUMNS);
    },
    onClientChange(client) {
      this.filters.client_id = client ? client.id.toString() : '';
      this.client = client;
    },
    onLocationChange(location) {
      this.filters.location_id = location ? location.id.toString() : '';
      this.location = location;
    },
    onFeedbackLastReceivedAtChanged(date) {
      this.filters.feedback_last_received_at = date;
      this.applyFilters();
    },
    loadInitialFilters() {
      const loadClient = new Promise((resolve) => {
        if (this.initialFilters.client_id) {
          this.$clientsAPI.get(this.initialFilters.client_id).then((client) => {
            resolve(client);
          });
        } else {
          resolve(null);
        }
      });

      const loadLocation = new Promise((resolve) => {
        if (this.initialFilters.location_id) {
          this.$locationsAPI.get(this.initialFilters.location_id).then((location) => {
            resolve(location);
          });
        } else {
          resolve(null);
        }
      });
      // The order of the resolved promises is preserved so we are able to use
      // indexes to fetch the data.
      Promise.all([loadClient, loadLocation]).then((values) => {
        this.client = values[0];
        this.location = values[1];
        this.applyFilters();
      });
    },
  },
};
</script>
