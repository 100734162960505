import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const LOCATION_MODEL_NAME = 'locations';
export const LOCATION_TABLE_NAME = 'locations';
export const CLIENT_LOCATION_TABLE_NAME = 'clientLocations';

// Location label constants
export const LOCATION_NAME_LABEL = 'Name';
export const LOCATION_STORE_NUMBER_LABEL = 'Store Number';
export const LOCATION_CUSTOMER_IDENTIFIER_LABEL = 'Customer ID';
export const LOCATION_STOCK_ROOM_FOOTAGE_LABEL = 'Stock Room Area';
export const LOCATION_SALES_FLOOR_FOOTAGE_LABEL = 'Sales Floor Area';
export const LOCATION_FLOOR_TYPE_LABEL = 'Floor Type';
export const LOCATION_DISTRICT_LABEL = 'District';
export const LOCATION_STREET_LABEL = 'Street';
export const LOCATION_CITY_LABEL = 'City';
export const LOCATION_STATE_LABEL = 'State';
export const LOCATION_COUNTY_LABEL = 'County';
export const LOCATION_ZIP_CODE_LABEL = 'Zip Code';
export const LOCATION_MANAGER_ID_LABEL = 'Manager';
export const LOCATION_CLIENT_IDS_LABEL = 'Clients';

export const LOCATIONS_FORM_LABELS = {
  name: LOCATION_NAME_LABEL,
  store_number: LOCATION_STORE_NUMBER_LABEL,
  customer_identifier: LOCATION_CUSTOMER_IDENTIFIER_LABEL,
  stock_room_footage: LOCATION_STOCK_ROOM_FOOTAGE_LABEL,
  sales_floor_footage: LOCATION_SALES_FLOOR_FOOTAGE_LABEL,
  floor_type: LOCATION_FLOOR_TYPE_LABEL,
  district: LOCATION_DISTRICT_LABEL,
  street: LOCATION_STREET_LABEL,
  city: LOCATION_CITY_LABEL,
  county: LOCATION_COUNTY_LABEL,
  state: LOCATION_STATE_LABEL,
  zip_code: LOCATION_ZIP_CODE_LABEL,
  manager_id: LOCATION_MANAGER_ID_LABEL,
  client_ids: LOCATION_CLIENT_IDS_LABEL,
};

// The columns available to be shown in a locations table.
export const LOCATION_TABLE_COLUMNS = {
  store_number: { label: LOCATION_STORE_NUMBER_LABEL, key: 'store_number', sortable: true },
  name: { label: LOCATION_NAME_LABEL, key: 'name', sortable: true },
  customer_identifier: { label: LOCATION_CUSTOMER_IDENTIFIER_LABEL, key: 'customer_identifier' },
  floor_type: { label: LOCATION_FLOOR_TYPE_LABEL, key: 'floor_type', sortable: true },
  district: { label: LOCATION_DISTRICT_LABEL, key: 'district' },
  street: { label: LOCATION_STREET_LABEL, key: 'street' },
  city: { label: LOCATION_CITY_LABEL, key: 'city', sortable: true },
  state: { label: LOCATION_STATE_LABEL, key: 'state', sortable: true },
  county: { label: LOCATION_COUNTY_LABEL, key: 'county' },
  zip_code: { label: LOCATION_ZIP_CODE_LABEL, key: 'zip_code' },
  manager_id: { label: LOCATION_MANAGER_ID_LABEL, key: 'manager_id' },
  deleted_at: { label: 'Active', key: 'active' },
  clients: { label: 'Clients', key: 'clients' },
  client_id: { label: 'Client', key: 'client_id', filterOnly: true },
  technician_id: { label: 'Technician', key: 'technician_id', filterOnly: true },
  service_id: { label: 'Service', key: 'service_id', filterOnly: true },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const LOCATION_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(LOCATION_TABLE_COLUMNS);

// The default columns to be shown when the locations table loads.
export const LOCATION_TABLE_DEFAULT_COLUMNS = [
  LOCATION_TABLE_COLUMNS.store_number,
  LOCATION_TABLE_COLUMNS.clients,
  LOCATION_TABLE_COLUMNS.city,
  LOCATION_TABLE_COLUMNS.state,
  LOCATION_TABLE_COLUMNS.floor_type,
];

export const CLIENT_LOCATION_TABLE_DEFAULT_COLUMNS = [
  LOCATION_TABLE_COLUMNS.store_number,
  LOCATION_TABLE_COLUMNS.floor_type,
  LOCATION_TABLE_COLUMNS.city,
  LOCATION_TABLE_COLUMNS.state,
];

export const LOCATION_FLOOR_TYPE_FORMAT_OPTIONS = {
  bbt: 'BBT',
  carpet: 'Carpet',
  ceramic: 'Ceramic',
  concrete: 'Concrete',
  lvt: 'LVT',
  mma: 'MMA',
  terrazo: 'Terrazo',
  tile: 'Tile',
  vct: 'VCT',
  other: 'Other',
};