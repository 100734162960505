<template>
  <div v-if="quote">
    <base-page-heading
      :title="`Quote ${quote.number}`"
      show-back-button
    >
      <template #secondary-title>
        <status-badge
          :status="quote.status"
          :status-variants="statusVariants"
        />
      </template>
      <template #extra>
        <b-button
          v-if="$can('update', 'Quote')"
          variant="primary"
          v-b-modal.quote-edit-modal
          class="mr-2"
        >
          <i class="fa fa-pencil-alt mr-1" /> Edit
        </b-button>

        <b-button
          v-if="$can('propose', quote)"
          variant="outline-secondary"
          v-b-modal.quote-propose-modal
          class="mr-2"
          v-b-tooltip.hover
          title="Propose the Quote"
        >
          <i class="fa fa-share mr-1" /> Propose
        </b-button>

        <b-button
          v-if="$can('accept', quote) && isProposed"
          variant="outline-secondary"
          :key="`${quoteKey}-accept-button`"
          @click="accept"
          class="mr-2"
          v-b-tooltip.hover
          title="Accept the Quote"
        >
          <i class="fa fa-check mr-1" /> Accept
        </b-button>

        <sfs-download-button
          :filename="`${quote.number}.pdf`"
          :provider-params="{ id: quote.id }"
          :file-provider="$quotesAPI.getPDF"
          v-b-tooltip.hover
          title="Download the Quote"
          class="mr-2"
        />

        <b-dropdown
          variant="outline-secondary"
          no-caret
          right
        >
          <template #button-content>
            <i class="fa fa-ellipsis-v" />
          </template>

          <b-dropdown-item v-b-modal.quote-email-modal>
            <i class="fa fa-envelope mr-1" />
            Email
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('accept', quote) && !isProposed"
            :key="`${quoteKey}-accept-dropdown`"
            @click="accept"
          >
            <i class="fa fa-check mr-1" />
            Accept
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('decline', quote)"
            :key="`${quoteKey}-decline-dropdown`"
            @click="decline"
          >
            <i class="far fa-thumbs-down mr-1" />
            Decline
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('cancel', quote)"
            :key="`${quoteKey}-cancel-dropdown`"
            @click="cancel"
          >
            <i class="fa fa-trash mr-1" />
            Cancel
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('uncancel', quote)"
            :key="`${quoteKey}-uncancel-dropdown`"
            @click="reopen"
          >
            <i class="fa fa-undo mr-1" />
            Reopen
          </b-dropdown-item>

          <b-dropdown-divider />

          <b-dropdown-item
            v-if="$can('update', 'Quote')"
            :key="`${quoteKey}-link-dropdown`"
            v-b-modal.quote-link-modal
          >
            <i class="fa fa-link mr-1" />
            Link to Work Order
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('create', 'WorkOrder') && !quote.work_order_id"
            @click="() => $router.push({ name: 'new-work-order', query: { quote_id: quote.id } })"
          >
            <i class="fa fa-clone mr-1" />
            Convert to Work Order
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('read', 'Version')"
            :to="{ name: 'versions', query: { model_id: quote.id, model: 'Quote' } }"
          >
            <i class="fa fa-glasses mr-1" />
            Audit Log
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </base-page-heading>

    <div class="content pt-5">
      <b-row class="mb-5">
        <b-col
          md="12"
          lg="6"
        >
          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Date
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col>
                  {{ quote.date | dateFormat }}
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Due Date
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col v-if="quote.due_date">
                  {{ quote.due_date | dateFormat }}
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col class="text-prewrap">
                  Work Order
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col v-if="quote.work_order_id">
                  <b-link :to="{ name: 'work-order', params: { id: quote.work_order_id } }">
                    {{ quote.work_order.number }}
                  </b-link>
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col class="text-prewrap">
                  Customer ID
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col v-if="quote.customer_identifier">
                  {{ quote.customer_identifier }}
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <hr class="mt-4">

          <b-row>
            <b-col>
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col class="text-prewrap">
                  Service
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col v-if="quote.service">
                  <b-link :to="{ name: 'service', params: { id: quote.service.id } }">
                    {{ quote.service.name }}
                  </b-link>
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-row class="mb-3">
                <b-col class="text-prewrap">
                  <div>{{ quote.description }}</div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          md="12"
          lg="6"
        >
          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Location
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col>
                  <b-link
                    :to="{ name: 'location', params: { id: quote.location_id } }"
                    v-b-tooltip.hover.window
                    :title="`${quote.location.street}, ${quote.location.city}, ${quote.location.state}, ${quote.location.zip_code}`"
                  >
                    {{ quote.location.store_number }}
                    <span v-if="quote.location.name">- {{ quote.location.name }}</span>
                  </b-link>
                  <b-link
                    class="d-block"
                    :to="{ name: 'client', params: { id: quote.client.id } }"
                  >
                    {{ quote.client.name }}
                  </b-link>
                </b-col>
              </b-row>

              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Address
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col>
                  <div>{{ quote.location.street }}</div>
                  <div>
                    {{
                      {
                        city: quote.location.city,
                        state: quote.location.state,
                        zip_code: quote.location.zip_code,
                      } | locationFormat
                    }}
                  </div>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  {{ $store.getters.translate('work_orders.cost', 'Cost') }}
                </b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col>
                  <money-format
                    :value="quote.cost.amount"
                    :currency-code="quote.cost.currency"
                    subunits-value
                  />
                </b-col>
              </b-row>

              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Estimated Taxes
                </b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col>
                  <money-format
                    :value="quote.estimated_taxes.amount"
                    :currency-code="quote.estimated_taxes.currency"
                    subunits-value
                  />
                </b-col>
              </b-row>

              <hr class="mt-0 mb-3">

              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Total {{ $store.getters.translate('work_orders.price', 'Price') }}
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col>
                  <money-format
                    :value="quote.price.amount + quote.estimated_taxes.amount"
                    :currency-code="quote.price.currency"
                    subunits-value
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12">
          <b-tabs
            v-model="activeTab"
            content-class="pt-4"
          >
            <template #tabs-end>
              <div
                v-show="activeTab === 1"
                class="ml-auto"
              >
                <b-button
                  v-if="$can('create', 'Document')"
                  v-b-modal.new-document-modal
                  variant="outline-secondary"
                >
                  <i class="fa fa-plus" />
                </b-button>
              </div>
            </template>
            <b-tab title="Items">
              <quote-items-list-filter
                :quote="quote"
                :initial-filters="filters"
                @filters-applied="applyFilters"
                @data-changed="refreshItems"
              />
              <quote-items-table
                :columns="$store.getters.columnsForTable(quoteItemsTable)"
                :quote="quote"
                :append-to-query="false"
                :filters="filters"
                :key="itemsKey"
              />
            </b-tab>

            <b-tab title="Documents">
              <documents-table
                :columns="$store.getters.columnsForTable(documentsTableName)"
                :append-to-query="false"
                :show-per-page="false"
                :key="documentsKey"
                :list-provider="$documentsAPI.getQuoteDocuments"
                :reference-id="quote.id"
              />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </div>

    <!-- Modals -->
    <quotes-edit-modal
      v-if="$can('update', 'Quote')"
      :key="`${quoteKey}-edit-modal`"
      v-model="quote"
    />
    <email-modal
      :value="quote"
      :key="`${quoteKey}-email-modal`"
      @input="updateQuote"
    />
    <propose-modal
      :value="quote"
      :key="`${quoteKey}-propose-modal`"
      @input="updateQuote"
    />
    <link-modal
      v-if="$can('update', 'Quote')"
      :value="quote"
      @input="updateQuote"
    />
    <new-document-modal
      v-if="$can('update', 'Quote')"
      :reference-id="quote.id"
      :create-provider="$documentsAPI.createQuoteDocument"
      @on-save="refreshDocuments()"
      internal
      :key="documentsKey"
    />
  </div>
</template>

<script>
import StatusBadge from '@/components/shared/StatusBadge.vue';
import QuotesEditModal from '@/components/admin/quotes/EditModal.vue';
import LinkModal from '@/components/admin/quotes/LinkModal.vue';
import QuoteItemsTable from '@/components/admin/quoteItems/Table.vue';
import QuoteItemsListFilter from '@/components/admin/quoteItems/ListFilter.vue';
import NewDocumentModal from '@/components/admin/documents/NewModal.vue';
import DocumentsTable from '@/components/admin/documents/Table.vue';
import EmailModal from '@/components/admin/quotes/EmailModal.vue';
import ProposeModal from '@/components/admin/quotes/ProposeModal.vue';
import { QUOTE_ITEM_TABLE_NAME } from '@/constants/quoteItems';
import { QUOTE_STATUS_VARIANTS, QUOTE_STATUS_PROPOSED } from '@/constants/quotes';
import { DOCUMENTS_TABLE_NAME } from '@/constants/documents';
import translationMixin from '@/mixins/translationMixin';

export default {
  name: 'QuotesShow',
  mixins: [translationMixin],
  components: {
    LinkModal,
    StatusBadge,
    EmailModal,
    ProposeModal,
    QuotesEditModal,
    QuoteItemsTable,
    QuoteItemsListFilter,
    NewDocumentModal,
    DocumentsTable,
  },
  data() {
    return {
      quote: null,
      activeTab: 0,
      quoteItemsTable: QUOTE_ITEM_TABLE_NAME,
      documentsTableName: DOCUMENTS_TABLE_NAME,
      quoteKey: `${Date.now()}`,
      itemsKey: `items-${Date.now()}`,
      documentsKey: `documents-${Date.now()}`,
      filters: {
        text: this.text,
      },
      statusVariants: QUOTE_STATUS_VARIANTS,
    };
  },
  mounted() {
    this.getQuote();
  },
  computed: {
    margin() {
      const amount = this.quote.price.amount - this.quote.cost.amount;
      return amount < 0 ? 0 : amount;
    },
    marginPercentage() {
      return this.margin === 0 && this.quote.price.amount === 0 ? '' : this.$options.filters.percentFormatFilter(this.margin / this.quote.price.amount);
    },
    isProposed() {
      return this.quote.status == QUOTE_STATUS_PROPOSED;
    },
  },
  methods: {
    updateQuote(quote) {
      this.quote = quote;
      this.quoteKey = `${Date.now()}`;
      this.refreshItems();
    },
    getQuote() {
      this.$quotesAPI
        .get(this.$route.params.id)
        .then((quote) => (this.quote = quote))
        .catch(() => {
          this.$router.push('/404');
        });
    },
    refreshItems() {
      this.itemsKey = `items-${Date.now()}`;
    },
    refreshDocuments() {
      this.documentsKey = `documents-${Date.now()}`;
    },
    applyFilters({ filters }) {
      this.filters = Object.assign({}, filters);
    },
    accept() {
      this.$swal({
        text: `Are you sure you want to accept this Quote on behalf of the ${this.$store.getters.translate('client', 'Client')}?`,
        preConfirm: () => this.$quotesAPI.accept(this.quote.id),
      }).then((result) => {
        if (result.value) {
          this.quote = result.value;
        }
        result.timer;
      });
    },
    decline() {
      this.$swal({
        text: `Are you sure you want to decline this Quote on behalf of the ${this.$store.getters.translate('client', 'Client')}?`,
        preConfirm: () => this.$quotesAPI.decline(this.quote.id),
      }).then((result) => {
        if (result.value) {
          this.quote = result.value;
        }
        result.timer;
      });
    },
    cancel() {
      this.$swal({
        text: 'Are you sure you want to cancel this Quote?',
        preConfirm: () => this.$quotesAPI.cancel(this.quote.id),
      }).then((result) => {
        if (result.value) {
          this.quote = result.value;
        }
        result.timer;
      });
    },
    reopen() {
      this.$swal({
        text: 'Are you sure you want to reopen this Quote?',
        preConfirm: () => this.$quotesAPI.uncancel(this.quote.id),
      }).then((result) => {
        if (result.value) {
          this.quote = result.value;
        }
        result.timer;
      });
    },
  },
};
</script>
