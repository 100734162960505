import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const WORK_ORDER_MODEL_NAME = 'work_orders';
export const WORK_ORDER_TABLE_NAME = 'workOrders';

// Work Order label constants
export const WORK_ORDER_NUMBER_LABEL = 'Number';
export const WORK_ORDER_CUSTOMER_IDENTIFIER_LABEL = 'Customer Identifier';
export const WORK_ORDER_LOCATION_ID_LABEL = 'Location';
export const WORK_ORDER_CLIENT_ID_LABEL = 'Client';
export const WORK_ORDER_SERVICE_ID_LABEL = 'Service';
export const WORK_ORDER_DESCRIPTION_LABEL = 'Description';
export const WORK_ORDER_PRIORITY_LABEL = 'Priority';
export const WORK_ORDER_MAINTAINANCE_TYPE_LABEL = 'Maintenance Type';
export const WORK_ORDER_DATE_BUCKET_START_LABEL = 'Date Bucket Start';
export const WORK_ORDER_DATE_BUCKET_END_LABEL = 'Date Bucket End';
export const WORK_ORDER_CHECKED_IN_AT_LABEL = 'Check-in Date';
export const WORK_ORDER_CHECKED_OUT_AT_LABEL = 'Check-out Date';
export const WORK_ORDER_ASSIGNED_TO_ID_LABEL = 'Assigned Technician';
export const WORK_ORDER_DONE_BY_ID_LABEL = 'Completed By';
export const WORK_ORDER_STATUS_LABEL = 'Status';
export const WORK_ORDER_SUB_STATUS_LABEL = 'Sub Status';
export const WORK_ORDER_DATE_BUCKET_LABEL = 'Date Bucket';
export const WORK_ORDER_LATITUDE_LABEL = 'Latitude';
export const WORK_ORDER_LONGITUDE_LABEL = 'Longitude';
export const WORK_ORDER_CHECK_IN_DISTANCE = 'Check in Distance';
export const WORK_ORDER_PARTS_NEEDED_LABEL = 'Waiting on Parts';

export const WORK_ORDER_FORM_LABELS = {
  number: WORK_ORDER_NUMBER_LABEL,
  customer_identifier: WORK_ORDER_CUSTOMER_IDENTIFIER_LABEL,
  location_id: WORK_ORDER_LOCATION_ID_LABEL,
  client_id: WORK_ORDER_CLIENT_ID_LABEL,
  service_id: WORK_ORDER_SERVICE_ID_LABEL,
  service: WORK_ORDER_SERVICE_ID_LABEL,
  description: WORK_ORDER_DESCRIPTION_LABEL,
  priority: WORK_ORDER_PRIORITY_LABEL,
  maintainance_type: WORK_ORDER_MAINTAINANCE_TYPE_LABEL,
  date_bucket_start: WORK_ORDER_DATE_BUCKET_START_LABEL,
  date_bucket_end: WORK_ORDER_DATE_BUCKET_END_LABEL,
  checked_in_at: WORK_ORDER_CHECKED_IN_AT_LABEL,
  checked_out_at: WORK_ORDER_CHECKED_OUT_AT_LABEL,
  assigned_to_id: WORK_ORDER_ASSIGNED_TO_ID_LABEL,
  assigned_to: WORK_ORDER_ASSIGNED_TO_ID_LABEL,
  done_by_id: WORK_ORDER_DONE_BY_ID_LABEL,
  done_by: WORK_ORDER_DONE_BY_ID_LABEL,
  status: WORK_ORDER_STATUS_LABEL,
  sub_status: WORK_ORDER_SUB_STATUS_LABEL,
  date_bucket: WORK_ORDER_DATE_BUCKET_LABEL,
  check_in_distance: WORK_ORDER_CHECK_IN_DISTANCE,
  parts_needed: WORK_ORDER_PARTS_NEEDED_LABEL,
};

// The available Work Order statuses.
export const WORK_ORDER_STATUS_PENDING = 'pending';
export const WORK_ORDER_STATUS_UNASSIGNED = 'unassigned';
export const WORK_ORDER_STATUS_ASSIGNED = 'assigned';
export const WORK_ORDER_STATUS_CANCELLED = 'cancelled';
export const WORK_ORDER_STATUS_IN_PROGRESS = 'in_progress';
export const WORK_ORDER_STATUS_COMPLETED = 'completed';
export const WORK_ORDER_STATUS_INVOICED = 'invoiced';

// the available technician Work Order statuses.
export const TECHNICIAN_WORK_ORDER_STATUS_UPCOMING = 'upcoming';
export const TECHNICIAN_WORK_ORDER_STATUS_CURRENT = 'current';
export const TECHNICIAN_WORK_ORDER_STATUS_OVERDUE = 'overdue';
export const TECHNICIAN_WORK_ORDER_STATUS_IN_PROGRESS = 'in_progress';
export const TECHNICIAN_WORK_ORDER_STATUS_COMPLETED = 'completed';
export const TECHNICIAN_WORK_ORDER_STATUS_CANCELLED = 'cancelled';

const VARIANT_WARNING = 'warning';
const VARIANT_DANGER = 'danger';
const VARIANT_SUCCESS = 'success';
const VARIANT_SECONDARY = 'secondary';
const VARIANT_PRIMARY = 'primary';
const VARIANT_INFO = 'info';
const VARIANT_PURPLE = 'purple';

export const STATUS_VARIANTS = {
  [WORK_ORDER_STATUS_PENDING]: VARIANT_WARNING,
  [WORK_ORDER_STATUS_UNASSIGNED]: VARIANT_DANGER,
  [WORK_ORDER_STATUS_ASSIGNED]: VARIANT_PRIMARY,
  [WORK_ORDER_STATUS_IN_PROGRESS]: VARIANT_WARNING,
  [WORK_ORDER_STATUS_COMPLETED]: VARIANT_SUCCESS,
  [WORK_ORDER_STATUS_INVOICED]: VARIANT_PURPLE,
};

export const TECHNICIAN_STATUS_VARIANTS = {
  [TECHNICIAN_WORK_ORDER_STATUS_UPCOMING]: VARIANT_INFO,
  [TECHNICIAN_WORK_ORDER_STATUS_CURRENT]: VARIANT_PRIMARY,
  [TECHNICIAN_WORK_ORDER_STATUS_OVERDUE]: VARIANT_DANGER,
  [TECHNICIAN_WORK_ORDER_STATUS_IN_PROGRESS]: VARIANT_WARNING,
  [TECHNICIAN_WORK_ORDER_STATUS_COMPLETED]: VARIANT_SUCCESS,
  [TECHNICIAN_WORK_ORDER_STATUS_CANCELLED]: VARIANT_SECONDARY,
};
// The columns available to be shown in a work order table.
export const WORK_ORDER_TABLE_COLUMNS = {
  number: { label: WORK_ORDER_NUMBER_LABEL, key: 'number', sortable: true },
  status: { label: WORK_ORDER_STATUS_LABEL, key: 'status', sortable: true },
  sub_status: { label: WORK_ORDER_SUB_STATUS_LABEL, key: 'sub_status' },
  location_id: { label: WORK_ORDER_LOCATION_ID_LABEL, key: 'location_id', sortable: true },
  client_id: { label: WORK_ORDER_CLIENT_ID_LABEL, key: 'client_id' },
  service_id: { label: WORK_ORDER_SERVICE_ID_LABEL, key: 'service_id' },
  parent_id: { label: 'Linked to Work Order', key: 'parent_id' },
  cost: { label: 'Cost', key: 'cost' },
  price: { label: 'Price', key: 'price' },
  parts_needed: { label: WORK_ORDER_PARTS_NEEDED_LABEL, key: 'parts_needed' },
  description: { label: WORK_ORDER_DESCRIPTION_LABEL, key: 'description', sortable: true },
  priority: { label: WORK_ORDER_PRIORITY_LABEL, key: 'priority' },
  maintainance_type: { label: WORK_ORDER_MAINTAINANCE_TYPE_LABEL, key: 'maintainance_type' },
  date_bucket_start: { label: WORK_ORDER_DATE_BUCKET_START_LABEL, key: 'date_bucket_start' },
  date_bucket_end: { label: WORK_ORDER_DATE_BUCKET_END_LABEL, key: 'date_bucket_end' },
  checked_in_date: { label: WORK_ORDER_CHECKED_IN_AT_LABEL, key: 'checked_in_date', sortable: true },
  checked_out_date: { label: WORK_ORDER_CHECKED_OUT_AT_LABEL, key: 'checked_out_date', sortable: true },
  assigned_to_id: { label: WORK_ORDER_ASSIGNED_TO_ID_LABEL, key: 'assigned_to_id' },
  done_by_id: { label: WORK_ORDER_DONE_BY_ID_LABEL, key: 'done_by_id' },
  date_bucket: { label: WORK_ORDER_DATE_BUCKET_LABEL, key: 'date_bucket', sortable: true },
  customer_identifier: { label: WORK_ORDER_CUSTOMER_IDENTIFIER_LABEL, key: 'customer_identifier' },
  invoiced: { label: 'Invoiced', key: 'invoiced', filterOnly: true },
  technician_paid: { label: 'Technician Paid', key: 'technician_paid', filterOnly: true },
  no_service: { label: 'No Service', key: 'no_service', filterOnly: true },
  manager_id: { label: 'Manager', key: 'manager_id' },
  city: { label: 'City', key: 'city', filterOnly: true },
  county: { label: 'County', key: 'county', filterOnly: true },
  state: { label: 'State', key: 'state', filterOnly: true },
  assigned_to_state: { label: 'Technician State', key: 'assigned_to_state', filterOnly: true },
  statuses: { label: WORK_ORDER_STATUS_LABEL, key: 'statuses', filterOnly: true },
  checked_in_from: { label: 'Checked-In From', key: 'checked_in_from', filterOnly: true },
  checked_in_to: { label: 'Checked-In To', key: 'checked_in_to', filterOnly: true },
  checked_out_from: { label: 'Checked-Out From', key: 'checked_out_from', filterOnly: true },
  checked_out_to: { label: 'Checked-Out To', key: 'checked_out_to', filterOnly: true },
  date_bucket_start_from: { label: 'Date Bucket Start From', key: 'date_bucket_start_from', filterOnly: true },
  date_bucket_start_to: { label: 'Date Bucket Start To', key: 'date_bucket_start_to', filterOnly: true },
  date_bucket_end_from: { label: 'Date Bucket End From', key: 'date_bucket_end_from', filterOnly: true },
  date_bucket_end_to: { label: 'Date Bucket End To', key: 'date_bucket_end_to', filterOnly: true },
  invoice_date_from: { label: 'Invoiced From', key: 'invoice_date_from', filterOnly: true },
  invoice_date_to: { label: 'Invoiced To', key: 'invoice_date_to', filterOnly: true },
  square_footage: { label: 'Square Footage', key: 'square_footage' },
  completion_sources: { label: 'Completion Source', key: 'completion_sources', filterOnly: true },
  cancellation_reasons: { label: 'Cancellation Reason', key: 'cancellation_reasons', filterOnly: true },
  check_in_distance: { label: 'Check in Distance', key: 'check_in_distance' }
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const WORK_ORDER_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(WORK_ORDER_TABLE_COLUMNS);

// The default columns to be shown when the work order table loads.
export const WORK_ORDER_TABLE_DEFAULT_COLUMNS = [
  WORK_ORDER_TABLE_COLUMNS.number,
  WORK_ORDER_TABLE_COLUMNS.location_id,
  WORK_ORDER_TABLE_COLUMNS.client_id,
  WORK_ORDER_TABLE_COLUMNS.description,
  WORK_ORDER_TABLE_COLUMNS.date_bucket,
  WORK_ORDER_TABLE_COLUMNS.status,
  WORK_ORDER_TABLE_COLUMNS.assigned_to_id,
];

export const WORK_ORDER_TABLE_UNASSIGNED_COLUMNS = [
  WORK_ORDER_TABLE_COLUMNS.number,
  WORK_ORDER_TABLE_COLUMNS.date_bucket,
  WORK_ORDER_TABLE_COLUMNS.maintainance_type,
  WORK_ORDER_TABLE_COLUMNS.location,
  WORK_ORDER_TABLE_COLUMNS.manager_id,
];

export const WORK_ORDER_TABLE_AWAITING_DATE_ASSIGNMENT_COLUMNS = [
  ...WORK_ORDER_TABLE_UNASSIGNED_COLUMNS,
  WORK_ORDER_TABLE_COLUMNS.assigned_to_id,
];

export const WORK_ORDER_TABLE_SCHEDULED_COLUMNS = [...WORK_ORDER_TABLE_AWAITING_DATE_ASSIGNMENT_COLUMNS];

export const WORK_ORDER_TABLE_INVOICED_COLUMNS = [...WORK_ORDER_TABLE_SCHEDULED_COLUMNS];

// TECHNICIAN PROFILE CONSTANTS
export const TECHNICIAN_WORK_ORDER_TABLE_NAME = 'technicianWorkOrders';

// The columns available to be shown in the technician work order table.
export const TECHNICIAN_WORK_ORDER_TABLE_COLUMNS = {
  number: { label: WORK_ORDER_NUMBER_LABEL, key: 'number', sortable: true },
  status: { label: WORK_ORDER_STATUS_LABEL, key: 'status', sortable: true },
  client_id: { label: WORK_ORDER_CLIENT_ID_LABEL, key: 'client_id' },
  location_id: { label: WORK_ORDER_LOCATION_ID_LABEL, key: 'location_id', sortable: true },
  done_by_id: { label: 'Completed By', key: 'done_by_id' },
  description: { label: WORK_ORDER_DESCRIPTION_LABEL, key: 'description', sortable: true },
  maintainance_type: { label: WORK_ORDER_MAINTAINANCE_TYPE_LABEL, key: 'maintainance_type' },
  scheduled_between: { label: 'Scheduled Between', key: 'scheduled_between', filterOnly: true },
  completed_between: { label: 'Completed Between', key: 'completed_between', filterOnly: true },
  checkbox: { label: 'Reassign', key: 'checkbox' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const TECHNICIAN_WORK_ORDER_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(TECHNICIAN_WORK_ORDER_TABLE_COLUMNS);

// The default columns to be shown when the technician work order table loads.
export const TECHNICIAN_WORK_ORDER_TABLE_DEFAULT_COLUMNS = [
  TECHNICIAN_WORK_ORDER_TABLE_COLUMNS.checkbox,
  TECHNICIAN_WORK_ORDER_TABLE_COLUMNS.number,
  TECHNICIAN_WORK_ORDER_TABLE_COLUMNS.location_id,
  TECHNICIAN_WORK_ORDER_TABLE_COLUMNS.description,
  TECHNICIAN_WORK_ORDER_TABLE_COLUMNS.done_by_id,
];
