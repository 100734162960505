<template>
  <sfs-base-table
    :key="tableKey"
    :data-provider="$technicianServicesAPI.getTechnicianServices"
    provider-key="technicianServices"
    :columns="columns"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
    :append-to-query="false"
  >
    <template #cell(location_id)="data">
      <b-link
        :to="{ name: 'location', params: { id: data.item.location_id } }"
        v-b-tooltip.hover.window
        :title="`${data.item.location.street}, ${data.item.location.city}, ${data.item.location.state}, ${data.item.location.zip_code}`"
      >
        {{ data.item.location.store_number }}
      </b-link>
    </template>

    <template #cell(service_id)="data">
      <b-link :to="{ name: 'service', params: { id: data.item.service_id } }">
        {{ data.item.service.name }}
      </b-link>
    </template>

    <template #cell(assigned_to_id)="data">
      <b-link :to="{ name: 'user', params: { id: data.item.assigned_to_id } }">
        {{ data.item.assigned_to.name }}
      </b-link>
    </template>

    <template #cell(done_by_id)="data">
      <b-link
        v-if="data.item.done_by_id"
        :to="{ name: 'user', params: { id: data.item.done_by_id } }"
      >
        {{ data.item.done_by.name }}
      </b-link>
    </template>

    <template #cell(cost)="data">
      <money-format
        :value="data.item.cost.amount"
        :currency-code="data.item.cost.currency"
        subunits-value
      />
    </template>
    <template #cell(actions)="data">
      <b-button-group>
        <b-button
          v-if="$can('update', 'TechnicianService')"
          v-b-modal="`technician-service-${data.item.id}-edit-modal`"
          size="sm"
          variant="link"
        >
          <i class="fa fa-fw fa-pencil-alt" />
        </b-button>
        <b-button
          v-if="$can('destroy', 'TechnicianService')"
          size="sm"
          variant="link"
          @click="destroyTechnicianServiceDialog(data.item.id)"
        >
          <i class="fa fa-fw fa-times" />
        </b-button>
      </b-button-group>
      <edit-modal
        v-if="$can('update', 'TechnicianService')"
        :key="`technician-services-edit-modal-${data.item.id}`"
        :value="data.item"
        @input="refreshTable"
      />
    </template>
  </sfs-base-table>
</template>

<script>
import EditModal from '@/components/admin/technicianServices/EditModal.vue';
import tablePropsMixin from '@/mixins/tablePropsMixin';
import { TECHNICIAN_SERVICES_TABLE_DEFAULT_COLUMNS } from '@/constants/technicianServices';

export default {
  name: 'TechnicianServicesTable',
  components: {
    EditModal,
  },
  mixins: [tablePropsMixin],
  props: {
    columns: {
      type: Array,
      default: () => TECHNICIAN_SERVICES_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    return {
      technicianService: {},
    };
  },
  methods: {
    destroyTechnicianServiceDialog(id) {
      this.$swal({
        text: 'Once it\'s deleted, its gone forever!',
        preConfirm: () => this.$technicianServicesAPI.destroy(id),
      }).then((result) => {
        if (result.value) {
          this.refreshTable();
        }
        result.timer;
      });
    },
  },
};
</script>
