<template>
  <b-form-group
    :label-for="`${inputPrefix}-${customField.name}`"
    :class="validationClass"
  >
    <template #label>
      <div
        v-if="isBoolean"
        class="mb-2 pt-1"
      >
         &nbsp;
      </div>
      <div v-else>
        {{ customField.label }}
      </div>
    </template>

    <sfs-date-picker
      v-if="isDate"
      @input="handleInput"
      v-bind="{ ...sharedInputProps }"
    />

    <sfs-date-time-picker
      v-if="isDateTime"
      @input="handleInput"
      v-bind="{ ...sharedInputProps }"
      :time-zone="timeZone"
    />

    <b-form-checkbox
      v-if="isBoolean"
      @input="handleInput"
      :checked="value"
      :id="id"
      :class="validationClass"
      :disabled="!customField.editable"
    >
      {{ customField.label }}
    </b-form-checkbox>

    <b-form-input
      v-if="isString"
      @change="handleInput"
      v-bind="{ ...sharedInputProps }"
      type="text"
    />

    <rating-picker
      v-if="isRating"
      @input="handleInput"
      v-bind="{ ...sharedInputProps }"
      :max-rating="customField.max"
    />

    <b-form-textarea
      v-if="isText"
      @change="handleInput"
      v-bind="{ ...sharedInputProps }"
      rows="4"
    />

    <slot name="feedback" />
  </b-form-group>
</template>
<script>
import RatingPicker from '@/components/shared/inputs/RatingPicker.vue';
import { CUSTOM_FIELD_TYPE_STRING, CUSTOM_FIELD_TYPE_TEXT, CUSTOM_FIELD_TYPE_DATE, CUSTOM_FIELD_TYPE_DATETIME, CUSTOM_FIELD_TYPE_BOOLEAN, CUSTOM_FIELD_TYPE_RATING } from '@/constants/customFields';

export default {
  name: 'CustomFieldFormField',
  components: {
    RatingPicker,
  },
  props: {
    customField: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Date, Boolean, Number],
    },
    inputPrefix: {
      type: String,
      default: 'custom-input',
    },
    timeZone: {
      type: String,
      default: null,
    },
    validationClass: {
      type: Object,
      default: () => new Object(),
    },
  },
  computed: {
    sharedInputProps() {
      return {
        value: this.value,
        id: this.id,
        class: this.validationClass,
        disabled: !this.customField.editable,
      };
    },
    id() {
      return `${this.inputPrefix}-${this.customField.name}`;
    },
    isDate() {
      return this.customField.field_type === CUSTOM_FIELD_TYPE_DATE;
    },
    isDateTime() {
      return this.customField.field_type === CUSTOM_FIELD_TYPE_DATETIME;
    },
    isString() {
      return this.customField.field_type === CUSTOM_FIELD_TYPE_STRING;
    },
    isText() {
      return this.customField.field_type === CUSTOM_FIELD_TYPE_TEXT;
    },
    isBoolean() {
      return this.customField.field_type === CUSTOM_FIELD_TYPE_BOOLEAN;
    },
    isRating() {
      return this.customField.field_type === CUSTOM_FIELD_TYPE_RATING;
    },
  },
  methods: {
    handleInput(newVal) {
      this.$emit('input', newVal);
    },
  },
};
</script>
