<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          v-if="isLoaded"
          :items="records"
          :fields="columns"
          :busy="isLoadingData"
          :sort-by.sync="sorting.sortBy"
          :sort-desc.sync="sorting.sortDesc"
          @sort-changed="handleSortingChanged"
          :empty-text="emptyText"
          no-local-sorting
          show-empty
          responsive
          table
        >
          <template
            v-for="(_, slot) of $scopedSlots"
            #[slot]="scope"
          >
            <slot
              :name="slot"
              v-bind="scope"
            />
          </template>
        </b-table>
        <sfs-skeleton-table
          v-else
          :rows="skeletonRows || pagination.perPage"
          :columns="columns.length"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="auto"
        :class="{ 'mr-auto': true, 'ml-auto': !showPerPage }"
      >
        <b-pagination
          v-if="showPagination"
          v-model="pagination.currentPage"
          :total-rows="pagination.maxResults > totalRows ? totalRows : pagination.maxResults"
          :per-page="pagination.perPage"
          last-class="d-none"
        />
      </b-col>
      <sfs-pagination-dropdown
        v-if="showPerPage"
        container-class="col-auto"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-rows="totalRows"
        @per-page-changed="onPerPageChanged"
      />
    </b-row>
  </div>
</template>

<script>
import urlMixin from '@/mixins/urlMixin';
import tableMixin from '@/mixins/tableMixin';

export default {
  name: 'BaseTable',
  mixins: [urlMixin, tableMixin],
  props: {
    dataProvider: {
      type: Function,
      required: true,
    },
    dataId: {
      type: [String, Number],
    },
    providerKey: {
      type: String,
      default: 'records',
    },
    emptyText: {
      type: String,
      default: 'There are no records to show',
    },
    skeletonRows: {
      type: Number,
    }
  },
  methods: {
    getRecords() {
      (this.dataId ? this.dataProvider(this.dataId, this.params) : this.dataProvider(this.params))
        .then((response) => {
          this.totalRows = response.total;
          this.records = response[this.providerKey];
        })
        .catch(() => {
          this.records = [];
          this.totalRows = 0;
        })
        .finally(() => {
          this.loaded = true;
          this.isLoadingData = false;
          this.$emit('data-changed', this.records);
          this.$emit('total-rows-changed', this.totalRows);
        });
    },
  }
};
</script>
