<template>
  <div>
    {{ title }}
    <b-badge
      v-if="total > 0"
      class="ml-1 number-badge rounded-pill"
      :variant="variant"
    >
      {{ total > 10 ? '10+' : total }}
    </b-badge>
  </div>
</template>

<script>

export default {
  name: 'NumberBadge',
  props: {
    title: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      default: 'danger',
    },
    total: {
      type: Number,
      required: true,
    },
  },
};
</script>
<style scoped>
.number-badge {
  font-size: 0.7rem;
  border-radius: 1rem;
  margin-top: 0.05rem;
  padding: 0.4rem 0.6rem;
  vertical-align: text-top;
}
</style>
