<template>
  <div>
    <rating-picker
      v-if="isRating"
      disabled
      :max-rating="customField.max"
      :value="value"
    />
    <span v-if="isString || isText">
      {{ value }}
    </span>

    <span v-if="isDate">
      {{ value | dateFormat }}
    </span>

    <span v-if="isDateTime">
      {{ value | dateTimeFormat(timeZone) }}
    </span>

    <span v-if="isBoolean">
      {{ value ? 'Yes' : 'No' }}
    </span>
  </div>
</template>
<script>
import RatingPicker from '@/components/shared/inputs/RatingPicker.vue';
import { CUSTOM_FIELD_TYPE_STRING, CUSTOM_FIELD_TYPE_TEXT, CUSTOM_FIELD_TYPE_DATE, CUSTOM_FIELD_TYPE_DATETIME, CUSTOM_FIELD_TYPE_BOOLEAN, CUSTOM_FIELD_TYPE_RATING } from '@/constants/customFields';

export default {
  name: 'DisplayValueField',
  components: {
    RatingPicker,
  },
  props: {
    customField: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Date, Boolean, Number],
    },
    timeZone: {
      type: String,
      default: null,
    },
  },
  computed: {
    isDate() {
      return this.customField.field_type === CUSTOM_FIELD_TYPE_DATE;
    },
    isDateTime() {
      return this.customField.field_type === CUSTOM_FIELD_TYPE_DATETIME;
    },
    isString() {
      return this.customField.field_type === CUSTOM_FIELD_TYPE_STRING;
    },
    isText() {
      return this.customField.field_type === CUSTOM_FIELD_TYPE_TEXT;
    },
    isBoolean() {
      return this.customField.field_type === CUSTOM_FIELD_TYPE_BOOLEAN;
    },
    isRating() {
      return this.customField.field_type === CUSTOM_FIELD_TYPE_RATING;
    },
  },
};
</script>
