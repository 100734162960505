<template>
  <sfs-form-modal
    :id="modalId"
    title="Link to Work Order"
    @on-hide="resetForm"
  >
    <b-form @submit.stop.prevent="handleSubmit">
      <b-row>
        <b-col>
          <b-alert
            :show="hasBaseServerErrors()"
            variant="danger"
            dismissible
          >
            <p
              class="mb-0"
              v-for="(error, index) in baseServerErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </b-alert>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Work Order"
            label-for="work-order-link-parent_id"
          >
            <work-order-select
              id="work-order-link-parent_id"
              :select-class="{ 'is-invalid': $v.form.parent_id.$dirty && $v.form.parent_id.$error }"
              select-label="number"
              placeholder="Enter a Number"
              :filter-params="{ client_id: value.client_id }"
              :value="form.parent"
              @input="onParentChange"
            />
            <small class="form-text text-secondary">Find a Work Order to link to</small>
            <b-form-invalid-feedback
              v-if="$v.form.parent_id.$dirty"
              id="work-order-link-parent_id-feedback"
            >
              <span v-if="!$v.form.parent_id.required">Please enter a Work Order to link.</span>
              <span v-if="!$v.form.parent_id.serverFailed">{{ serverErrors.parent_id }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            id="work-order-link-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            id="work-order-link-submit-button"
            block
            type="submit"
            variant="primary"
          >
            Link
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';
import WorkOrderSelect from '@/components/admin/workOrders/Select.vue';

export default {
  name: 'WorkOrdersLinkModal',
  components: {
    WorkOrderSelect,
  },
  mixins: [formMixin, formModalMixin],
  props: {
    value: Object,
  },
  data() {
    return {
      form: {
        parent: null,
        parent_id: null,
      },
      modalId: 'work-order-link-modal',
    };
  },
  validations: {
    form: {
      parent_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('parent_id');
        },
      },
    },
  },
  methods: {
    onParentChange(parent) {
      this.form.parent_id = parent ? parent.id : null;
      this.form.parent = parent;
    },
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }
      this.$workOrdersAPI
        .update(this.value.id, this.form)
        .then(() => {
          this.resetServerErrors();
          this.$v.$reset();
          this.$emit('input', this.form.parent);
          this.closeModal();
        })
        .catch((error) => {
          this.processServerErrors(error.response.data.errors);
        });
    },
  },
};
</script>
