<template>
  <b-form @submit.stop.prevent="handleSubmit">
    <b-row>
      <b-col>
        <b-alert
          :show="hasBaseServerErrors()"
          variant="danger"
          dismissible
        >
          <p
            class="mb-0"
            v-for="(error, index) in baseServerErrors"
            :key="index"
          >
            {{ error }}
          </p>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.number"
          label-for="invoice-number"
        >
          <b-form-input
            id="invoice-number"
            v-model="$v.form.number.$model"
            placeholder="Optional"
            :state="$v.form.number.$dirty ? !$v.form.number.$error : null"
            type="text"
          />
          <small
            v-if="newForm"
            class="form-text text-muted"
          >
            If left blank the number will be automatically generated
          </small>
          <b-form-invalid-feedback
            v-if="$v.form.number.$dirty"
            id="invoice-number-feedback"
          >
            <span v-if="!$v.form.number.maxLength">
              The number must be less than {{ $v.form.number.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.number.serverFailed">{{ serverErrors.number }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.client_id"
          label-for="invoice-client_id"
        >
          <client-select
            id="invoice-client_id"
            :select-class="{ 'is-invalid': $v.form.client_id.$dirty && $v.form.client_id.$error }"
            :placeholder="`Select a ${$store.getters.translate('client', 'Client')}`"
            :disabled="!newForm"
            select-label="name"
            :value="form.client"
            @input="onClientChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.client_id.$dirty"
            id="invoice-client_id-feedback"
          >
            <span v-if="!$v.form.client_id.required">Please enter a {{ $store.getters.translate('client', 'client') }}.</span>
            <span v-if="!$v.form.client_id.serverFailed">{{ serverErrors.client_id }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.date"
          label-for="invoice-date"
        >
          <sfs-date-picker
            id="invoice-date"
            :value="form.date"
            @input="onDateChange"
            :class="{ 'is-invalid': $v.form.date.$dirty && $v.form.date.$error }"
            :with-class="{ 'is-invalid': $v.form.date.$dirty && $v.form.date.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.date.$dirty"
            id="invoice-date-feedback"
          >
            <span v-if="!$v.form.date.required">Please enter an invoice date.</span>
            <span v-if="!$v.form.date.serverFailed">{{ serverErrors.date }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          :label="fieldLabels.due_date"
          label-for="invoice-due_date"
        >
          <sfs-date-picker
            id="invoice-due_date"
            v-model="form.due_date"
            :class="{ 'is-invalid': $v.form.due_date.$dirty && $v.form.due_date.$error }"
            :with-class="{ 'is-invalid': $v.form.due_date.$dirty && $v.form.due_date.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.due_date.$dirty"
            id="invoice-due_date-feedback"
          >
            <span v-if="!$v.form.due_date.required">Please enter a due date.</span>
            <span v-if="!$v.form.due_date.serverFailed">{{ serverErrors.due_date }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.invoice_format_id"
          label-for="invoice-invoice_format_id"
        >
          <invoice-formats-select
            id="invoice-invoice_format_id"
            :select-class="{ 'is-invalid': $v.form.invoice_format_id.$dirty && $v.form.invoice_format_id.$error }"
            :value="form.invoice_format"
            @input="onInvoiceFormatChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.invoice_format_id.$dirty"
            id="invoice-invoice_format_id-feedback"
          >
            <span v-if="!$v.form.invoice_format_id.required">Please enter an invoice format.</span>
            <span v-if="!$v.form.invoice_format_id.serverFailed">{{ serverErrors.invoice_format_id }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.purchase_order_number"
          label-for="invoice-purchase_order_number"
        >
          <b-form-input
            id="invoice-purchase_order_number"
            placeholder="Optional"
            v-model="$v.form.purchase_order_number.$model"
            :state="$v.form.purchase_order_number.$dirty ? !$v.form.purchase_order_number.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.purchase_order_number.$dirty"
            id="invoice-purchase_order_number-feedback"
          >
            <span v-if="!$v.form.purchase_order_number.maxLength">
              The purchase order number must be less than {{ $v.form.purchase_order_number.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.purchase_order_number.serverFailed">{{ serverErrors.purchase_order_number }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <div v-if="newForm">
      <hr class="mb-4">
      <b-row class="mb-3">
        <b-col cols="12">
          <h4>Billing Options</h4>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group label-for="invoice-bill_work_orders">
            <b-form-checkbox
              id="invoice-bill_work_orders"
              v-model="$v.form.bill_work_orders.$model"
            >
              Invoice billable Work Orders
            </b-form-checkbox>
            <div class="font-size-sm text-secondary">
              This will invoice any Work Orders that are have not yet been billed.
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group label-for="invoice-bill_recurring_charges">
            <b-form-checkbox
              id="invoice-bill_recurring_charges"
              v-model="$v.form.bill_recurring_charges.$model"
            >
              Bill Recurring Charges
            </b-form-checkbox>
            <div class="font-size-sm text-muted">
              This will invoice any Recurring Charges that are billable for the given period.
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="form.bill_recurring_charges || form.bill_work_orders">
        <b-col cols="12">
          <b-form-group
            :label="fieldLabels.period"
            label-for="invoice-period"
            label-sr-only
          >
            <sfs-date-range-picker
              id="invoice-period"
              v-model="$v.form.period.$model"
              :with-class="{ 'is-invalid': ($v.form.start_date.$dirty && $v.form.start_date.$error) || ($v.form.end_date.$dirty && $v.form.end_date.$error) }"
              :class="{ 'is-invalid': ($v.form.start_date.$dirty && $v.form.start_date.$error) || ($v.form.end_date.$dirty && $v.form.end_date.$error) }"
              placeholder="Select the period to bill for"
              @on-change="onPeriodChange"
            />
            <b-form-invalid-feedback
              v-if="$v.form.start_date.$dirty"
              id="invoice-start_date-feedback"
            >
              <span v-if="!$v.form.start_date.required">Please enter a start date.</span>
              <span v-if="!$v.form.start_date.serverFailed">{{ serverErrors.start_date }}</span>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-if="$v.form.end_date.$dirty"
              id="invoice-end_date-feedback"
            >
              <span v-if="!$v.form.end_date.required">Please enter a end date.</span>
              <span v-if="!$v.form.end_date.serverFailed">{{ serverErrors.end_date }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          id="invoice-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          id="invoice-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, maxLength, requiredIf } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import ClientSelect from '@/components/admin/clients/Select.vue';
import InvoiceFormatsSelect from '@/components/admin/invoiceFormats/Select.vue';
import { DateTime } from 'luxon';

export default {
  name: 'TechnicianPaymentsForm',
  components: {
    ClientSelect,
    InvoiceFormatsSelect,
  },
  mixins: [formMixin],
  props: {
    newForm: {
      type: Boolean,
      default: true,
    },
    number: String,
    purchase_order_number: String,
    date: String,
    due_date: String,
    bill_work_orders: Boolean,
    bill_recurring_charges: Boolean,
    start_date: String,
    end_date: String,
    client_id: Number,
    client: Object,
    invoice_format_id: [String, Number],
    invoice_format: Object,
  },
  data() {
    return {
      form: {
        number: this.number,
        purchase_order_number: this.purchase_order_number,
        date: this.date,
        due_date: this.due_date,
        client_id: this.client_id,
        client: this.client,
        invoice_format_id: this.invoice_format_id,
        invoice_format: this.invoice_format,
        bill_work_orders: this.bill_work_orders,
        bill_recurring_charges: this.bill_recurring_charges,
        start_date: this.start_date,
        end_date: this.end_date,
        period: [
          this.start_date && this.$options.filters.dateFormat(this.start_date),
          this.end_date && this.$options.filters.dateFormat(this.end_date),
        ],
      },
      fieldLabels: {
        number: 'Number',
        date: 'Invoice Date',
        due_date: 'Due Date',
        client_id: `${this.$store.getters.translate('client', 'Client')}`,
        invoice_format_id: 'Invoice Format',
        purchase_order_number: `${this.$store.getters.translate('client', 'Client')} PO Number`,
        bill_work_orders: 'Invoice billable Work Orders',
        bill_recurring_charges: 'Bill Recurring Charges',
        period: 'Billing Period',
      },
    };
  },
  validations: {
    form: {
      number: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('number');
        },
      },
      purchase_order_number: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('purchase_order_number');
        },
      },
      date: {
        required,
        serverFailed() {
          return !this.hasServerErrors('date');
        },
      },
      due_date: {
        required,
        serverFailed() {
          return !this.hasServerErrors('due_date');
        },
      },
      client_id: {
        required: requiredIf(function () {
          return this.newForm;
        }),
        serverFailed() {
          return !this.hasServerErrors('client_id');
        },
      },
      invoice_format_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('invoice_format_id');
        },
      },
      bill_work_orders: {},
      bill_recurring_charges: {},
      period: {},
      start_date: {
        required: requiredIf(function () {
          return this.form.bill_recurring_charges;
        }),
        serverFailed() {
          return !this.hasServerErrors('start_date');
        },
      },
      end_date: {
        required: requiredIf(function () {
          return this.form.bill_recurring_charges;
        }),
        serverFailed() {
          return !this.hasServerErrors('end_date');
        },
      },
    },
  },
  watch: {
    'form.bill_work_orders': function (billWorkOrders) {
      if (billWorkOrders) {
        this.form.bill_recurring_charges = false;
      }
    },
    'form.bill_recurring_charges': function (billRecurringCharges) {
      if (billRecurringCharges) {
        this.form.bill_work_orders = false;
      }
    },
  },
  methods: {
    onClientChange(client) {
      this.form.client_id = client ? client.id : '';
      this.form.client = client;
      if (!this.form.invoice_format_id) {
        this.form.invoice_format = client.invoice_format;
        this.form.invoice_format_id = client.invoice_format_id;
      }
      this.updateDueDate();
    },
    onDateChange(date) {
      this.form.date = date;
      this.updateDueDate();
    },
    updateDueDate() {
      const paymentTermDays = this.form.client?.payment_term_days || 0;
      const date = this.form.date || DateTime.now().toISODate();
      if (!this.form.date) {
        this.form.date = date;
      }
      this.form.due_date = DateTime.fromISO(date).plus({ days: paymentTermDays }).toISODate();
    },
    onInvoiceFormatChange(invoiceFormat) {
      this.form.invoice_format_id = invoiceFormat ? invoiceFormat.id : '';
      this.form.invoice_format = invoiceFormat;
    },
    onPeriodChange(dates) {
      this.form.start_date = dates[0] || '';
      this.form.end_date = dates[1] || '';
    },
  },
};
</script>
