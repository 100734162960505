<template>
  <div>
    <base-page-heading :title="title">
      <template #extra>
        <div class="no-print">
          <b-link @click="() => handleDatesChanged(currentMonthDates)">
            Current Month
          </b-link>
          <span class="pl-1 pr-1">|</span>
          <b-link @click="() => handleDatesChanged(lastMonthDates)">
            Previous Month
          </b-link>
          <span class="pl-1 pr-1">|</span>
          <b-link @click="() => handleDatesChanged(lastSixMonthsDates)">
            Last 6 Months
          </b-link>
        </div>
      </template>
    </base-page-heading>
    <div class="content">
      <dicks-sporting-goods-performance-list-filter
        class="no-print"
        :columns="columns"
        :initial-filters="filters"
        @filters-applied="applyFilters"
        @selected-state-data-changed="handleStateChanged"
      />

      <b-card class="py-2 mb-4 mt-3">
        <b-row>
          <b-col class="text-center border-right">
            <h1 class="font-w400">
              {{ totalWorkOrders }}
            </h1>
            <span class="text-secondary">Total Work Orders</span>
          </b-col>
          <b-col class="text-center border-right">
            <h1 class="font-w400">
              {{ totalCompletedWorkOrders }}
            </h1>
            <span class="text-secondary">Completed Work Orders</span>
          </b-col>

          <b-col class="text-center border-right">
            <h1 class="font-w400">
              {{ avgFeedbackTotal }}
            </h1>
            <span class="text-secondary">Avg Score</span>
          </b-col>
        </b-row>
      </b-card>

      <b-card class="py-2">
        <b-row>
          <b-col sm="6">
            <pie-chart
              v-show="!loading"
              title="Score Breakdown"
              :fill-opacity="1"
              data-labels-enabled
              :legend-enabled="false"
              :colors="chartOptions.pieChartColours"
              :data-label-formatter="percentageLabelFormatter"
              :chart-height="chartOptions.defaultChartHeight"
              :skeleton-height="chartOptions.defaultChartHeight"
              :series="feedbackScoreBreakdownPercentilesSeries"
              :labels="feedbackScoreBreakdownPercentilesSeriesLabels"
            />
            <skeleton-chart
              v-show="loading"
              :height="chartOptions.defaultChartHeight"
              :width="chartOptions.defaultChartWidth"
            />
          </b-col>
          <b-col sm="6">
            <bar-chart
              v-show="!loading"
              :title="`Avg Score by ${groupByLabel}`"
              :fill-opacity="1"
              grid-enabled
              :legend-enabled="false"
              :y-axis-labels-enabled="false"
              :share-tooltip="true"
              bar-horiziontal
              bar-distributed
              data-labels-enabled
              :data-label-formatter="categoryLabelFormatter"
              series-type="category"
              :chart-height="chartOptions.defaultChartHeight"
              :skeleton-height="chartOptions.defaultChartHeight"
              :series="avgScoreByDistrictSeries"
              :categories="avgScoreByDistrictSeriesLabels"
            />
            <skeleton-chart
              v-show="loading"
              :height="chartOptions.defaultChartHeight"
              :width="chartOptions.defaultChartWidth"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <bar-chart
              v-show="!loading"
              :title="`Comparison by ${groupByLabel}`"
              :colors="['#084298', '#96459d', '#e05489', '#ff7f6d', '#ffbb5b', '#f9f871', '#d0a616']"
              :fill-opacity="1"
              grid-enabled
              stacked
              bar-horiziontal
              data-labels-enabled
              series-type="category"
              :chart-height="chartOptions.defaultChartHeight"
              :skeleton-height="chartOptions.defaultChartHeight"
              :series="avgScoreByQuestionSeries"
              :categories="avgScoreByQuestionSeriesLabels"
            />
            <skeleton-chart
              v-show="loading"
              :height="chartOptions.defaultChartHeight"
              :width="chartOptions.defaultChartWidth"
            />
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { isEqual as _isEqual, isUndefined as _isUndefined } from 'lodash';
import listMixin from '@/mixins/listMixin';
import { CHART_COLOUR_ORANGE, CHART_COLOUR_BROWN, CHART_COLOUR_PRIMARY_LIGHT, CHART_COLOUR_PRIMARY, CHART_COLOUR_PRIMARY_DARK } from '@/constants';

import DicksSportingGoodsPerformanceListFilter from '@/components/admin/reports/clientFeedbacks/DicksSportingGoodsPerformanceListFilter.vue';
import PieChart from '@/components/shared/apexcharts/PieChart';
import BarChart from '@/components/shared/apexcharts/BarChart';
import SkeletonChart from '@/components/shared/SkeletonChart.vue';


export default {
  name: 'DicksSportingGoodPerformance',
  components: {
    DicksSportingGoodsPerformanceListFilter,
    PieChart,
    BarChart,
    SkeletonChart,
  },
  mixins: [listMixin],
  props: {
    location_id: String,
    district: String,
    state: String,
    start_date: String,
    end_date: String,
  },
  data() {
    const lastSixMonthsStart = DateTime.now().minus({ month: 6 }).startOf('month').toISODate();
    const lastSixMonthsEnd = DateTime.now().toISODate();
    return {
      filters: {
        client_id: 793, // TODO: Hardcoding the client_id isn't create - not sure of a better solution here.
        location_id: this.location_id,
        district: this.district,
        state: this.state,
        start_date: this.start_date || this.$options.filters.dateFormat(lastSixMonthsStart),
        end_date: this.end_date || this.$options.filters.dateFormat(lastSixMonthsEnd),
        group_by: 'state',
      },
      chartOptions: {
        defaultChartHeight: '400px',
        defaultChartWidth: '100%',
        pieChartColours: [CHART_COLOUR_BROWN, CHART_COLOUR_ORANGE, CHART_COLOUR_PRIMARY_LIGHT, CHART_COLOUR_PRIMARY_DARK, CHART_COLOUR_PRIMARY],
      },
      loading: false,
      selectedStateData: null,
      lastSixMonthsDates: [lastSixMonthsStart, lastSixMonthsEnd],
      lastMonthDates: [DateTime.now().startOf('month').minus({ month: 1 }).toISODate(), DateTime.now().endOf('month').minus({ month: 1 }).toISODate()],
      currentMonthDates: [DateTime.now().startOf('month').toISODate(), DateTime.now().endOf('month').toISODate()],
      maxRatingScore: 0,
      totalWorkOrders: 0,
      totalCompletedWorkOrders: 0,
      avgFeedbackTotal: 0,
      feedbackScoreBreakdownPercentilesSeries: [],
      feedbackScoreBreakdownPercentilesSeriesLabels: [],
      avgScoreByDistrictSeries: [{ data: []}],
      avgScoreByDistrictSeriesLabels: [],
      avgScoreByQuestionSeries: [{ data: []}],
      avgScoreByQuestionSeriesLabels: [],
      avgScoreByDateSeries: [{ data: []}],
    };
  },
  methods: {
    handleStateChanged(newStateData) {
      this.selectedStateData = newStateData;
    },
    handleDatesChanged(dates) {
      this.applyFilters({ filters: { ...this.filters, start_date: this.$options.filters.dateFormat(dates[0]), end_date: this.$options.filters.dateFormat(dates[1]) }});
    },
    categoryLabelFormatter(_val, opts) {
      return this.avgScoreByDistrictSeriesLabels[opts.dataPointIndex];
    },
    percentageLabelFormatter(val, opts) {
      return [this.feedbackScoreBreakdownPercentilesSeriesLabels[opts.seriesIndex], val.toFixed(1) + '%'];
    },
    getDashboardData() {
      this.loading = true;
      this.$analyticsAPI.getClientFeedbacksDashboard(this.filters).then(({ data }) => {
        this.maxRatingScore = data.maxRatingScore;
        this.totalWorkOrders = data.total_work_orders;
        this.totalCompletedWorkOrders = data.total_completed_work_orders;
        this.avgFeedbackTotal = (data.avg_feedback_total || 0).toFixed(2);
        this.feedbackScoreBreakdownPercentilesSeries = Object.values(data.feedback_score_breakdown_percentiles_series);
        this.feedbackScoreBreakdownPercentilesSeriesLabels = Object.keys(data.feedback_score_breakdown_percentiles_series);
        this.avgScoreByDistrictSeries = [{ name: 'Avg Score', data: Object.values(data.avg_score_by_district_series) }];
        this.avgScoreByDistrictSeriesLabels = Object.keys(data.avg_score_by_district_series);
        const questionsData = {};
        Object.values(data.avg_score_by_question_series).forEach((questionSeries) => {
          Object.keys(questionSeries).forEach((question) => {
            questionsData[question] = questionsData[question] || [];
            questionsData[question].push(questionSeries[question].toFixed(1));
          });
        });
        this.avgScoreByQuestionSeries = Object.keys(questionsData).map((question) => ({ name: question, data: questionsData[question] }));
        this.avgScoreByQuestionSeriesLabels = Object.keys(data.avg_score_by_question_series);
        this.avgScoreByDateSeries = data.avg_score_by_date_series;
      }).finally(() => this.loading = false);
    },
  },
  computed: {
    title() {
      let title = 'Dick\'s Sporting Goods Performance';
      if (this.selectedStateData) {
        title += ` - ${this.selectedStateData.name}`;
      }
      return title;
    },
    groupByLabel() {
      return this.filters.group_by == 'state' ? 'State' : 'District';
    },
  },
  watch: {
    filters: {
      deep: true,
      immediate: true,
      handler(newFilters, oldFilters) {
        if (!_isEqual(oldFilters, newFilters) && !_isUndefined(newFilters)) {
          this.getDashboardData();
        }
      }
    }
  }
};
</script>
