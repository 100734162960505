<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          v-if="isLoaded"
          ref="users-technicians-table"
          :items="records"
          :fields="columns"
          :busy="isLoadingData"
          :sort-by.sync="sorting.sortBy"
          :sort-desc.sync="sorting.sortDesc"
          @sort-changed="handleSortingChanged"
          no-local-sorting
          show-empty
          responsive
          table
        >
          <template #cell(tax_name)="data">
            <b-link :to="{ name: 'tax-rates', query: { city: data.item.city, county: data.item.county, state: data.item.state } }">
              <div>{{ data.item.tax_name }}</div>
              <div class="text-muted">
                {{ formatPercentage(data.item.tax_rate) }}
              </div>
            </b-link>
          </template>
          <template #cell(taxable_sales)="data">
            <div>
              <money-format
                :value="Number(data.item.taxable_sales)"
                subunits-value
              />
            </div>
          </template>
          <template #cell(tax_amount)="data">
            <money-format
              :value="Number(data.item.tax_amount)"
              subunits-value
            />
          </template>
          <template #cell(total_sales)="data">
            <money-format
              :value="Number(data.item.total_sales)"
              subunits-value
            />
          </template>
        </b-table>
        <sfs-skeleton-table
          v-else
          :rows="pagination.perPage"
          :columns="columns.length"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="auto"
        :class="{ 'mr-auto': true, 'ml-auto': !showPerPage }"
      >
        <b-pagination
          v-if="showPagination"
          v-model="pagination.currentPage"
          :total-rows="pagination.maxResults > totalRows ? totalRows : pagination.maxResults"
          :per-page="pagination.perPage"
          last-class="d-none"
        />
      </b-col>
      <sfs-pagination-dropdown
        v-if="showPerPage"
        container-class="col-auto"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-rows="totalRows"
        @per-page-changed="onPerPageChanged"
      />
    </b-row>
  </div>
</template>

<script>
  import { Duration } from 'luxon';
  import urlMixin from '@/mixins/urlMixin';
  import tableMixin from '@/mixins/tableMixin';
  import translationMixin from '@/mixins/translationMixin';
  import { WORK_ORDER_TAX_REPORT_TABLE_DEFAULT_COLUMNS } from '@/constants/reports';

  export default {
    name: 'TaxReportTable',
    mixins: [urlMixin, tableMixin, translationMixin],
    props: {
      columns: {
        type: Array,
        default: () => WORK_ORDER_TAX_REPORT_TABLE_DEFAULT_COLUMNS,
      },
    },
    methods: {
      formatDuration(seconds) {
        const duration = Duration.fromObject({ seconds: seconds });
        return duration.toFormat('h:mm');
      },
      formatPercentage(rate) {
        return (rate * 100).toFixed(2) + '%'; // Convert to percentage and format to 2 decimal places
      },
      getRecords() {
        this.isLoadingData = true;
        this.$analyticsAPI
          .getTaxReport(this.params)
          .then((response) => {
            const { total, records } = response;
            this.totalRows = total;
            this.records = records;
          })
          .catch(() => {
            this.records = [];
          })
          .finally(() => {
            this.loaded = true;
            this.isLoadingData = false;
            this.$emit('data-changed', this.records);
          });
      },
    },
  };
</script>
