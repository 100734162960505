<template>
  <sfs-base-list-filter
    title="Filter Taxes"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="availableColumns"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
    :advanced-filter="false"
    :popover-filter="true"
    :column-picker="false"
  >
    <template #quick-filters>
      <b-form-group
        label="Date Range"
        label-for="quick-filter-dates"
        label-sr-only
      >
        <div class="d-flex">
          <sfs-date-range-picker
            id="quick-filter-dates"
            :value="datesRange"
            placeholder="Select Dates"
            @on-change="onDatesChange"
          />
          <b-input-group-append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </b-input-group-append>
        </div>
      </b-form-group>
    </template>

    <template #popover-filters>
      <b-row>
        <b-col>
          <b-form-group
            label="Client"
            label-for="quick-filter-client_id"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <client-select
              id="quick-filter-client_id"
              placeholder="All"
              :value="client"
              @input="onClientChange"
              :persist-state="true"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            class="mb-4"
            label="State"
            label-for="quick-filter-state"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-input
              id="quick-filter-state"
              v-model="filters.state"
              placeholder="All"
              type="text"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
    <template #right-tray>
      <sfs-download-button
        v-b-tooltip.hover
        :provider-params="filters"
        filename="Taxes.csv"
        :file-provider="$analyticsAPI.getTaxReportSpreadsheet"
        title="Download spreadsheet"
      />
    </template>
  </sfs-base-list-filter>
</template>

<script>
  import ClientSelect from '@/components/admin/clients/Select.vue';
  import listFilterMixin from '@/mixins/listFilterMixin';
  import {
    WORK_ORDER_TECHNICIAN_REPORT_TABLE_NAME,
    TECHNICIAN_REPORT_TABLE_COLUMN_OPTIONS,
    TECHNICIAN_REPORT_TABLE_DEFAULT_COLUMNS,
  }
  from '@/constants/reports';

  export default {
    name: 'TaxListFilter',
    mixins: [listFilterMixin],
    components: {
      ClientSelect,
    },
    data() {
      return {
        tableName: WORK_ORDER_TECHNICIAN_REPORT_TABLE_NAME,
        availableColumns: TECHNICIAN_REPORT_TABLE_COLUMN_OPTIONS,
      };
    },
    mounted() {
      this.loadInitialFilters();
    },
    methods: {
      applyPresetFilter(filters, columns) {
        this.filters = filters;
        this.displayedColumns = columns;
        this.applyFilters();
      },
      onDefaultPresetApplied() {
        this.applyPresetFilter({}, TECHNICIAN_REPORT_TABLE_DEFAULT_COLUMNS);
      },
      onClientChange(client) {
        this.filters.client_id = client ? client.id.toString() : '';
        this.client = client;
      },
      onDatesChange(dates) {
        if (dates[0] && dates[1]) {
          this.filters.start_date = dates[0] ? this.$options.filters.dateFormat(dates[0].toISOString()) : '';
          this.filters.end_date = dates[1] ? this.$options.filters.dateFormat(dates[1].toISOString()) : '';
          this.applyFilters();
        }
      },
      loadInitialFilters() {
        const loadClient = new Promise((resolve) => {
          if (this.initialFilters.client_id) {
            this.$clientsAPI.get(this.initialFilters.client_id).then((client) => {
              resolve(client);
            });
          } else {
            resolve(null);
          }
        });

        const loadService = new Promise((resolve) => {
          if (this.initialFilters.service_id) {
            this.$servicesAPI.get(this.initialFilters.service_id).then((service) => {
              resolve(service);
            });
          } else {
            resolve(null);
          }
        });
        // The order of the resolved promises is preserved so we are able to use
        // indexes to fetch the data.
        Promise.all([loadClient, loadService]).then((values) => {
          this.client = values[0];
          this.service = values[1];
          this.applyFilters();
        });
      },
    },
    computed: {
      datesRange() {
        return [this.filters.start_date, this.filters.end_date];
      }
    },
  };
</script>
