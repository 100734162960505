<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          v-if="isLoaded"
          ref="work-orders-table"
          :items="records"
          :fields="columns"
          :busy="isLoadingData"
          :sort-by.sync="sorting.sortBy"
          :sort-desc.sync="sorting.sortDesc"
          @sort-changed="handleSortingChanged"
          no-local-sorting
          show-empty
          responsive
          table
          selectable
          @row-selected="rowSelected"
        >
          <template #head(checkbox)>
            <b-form-checkbox
              class="checkbox-header"
              @change="isAllSelected ? clearAllRows() : selectAllRows()"
            />
          </template>
          <template #cell(checkbox)="data">
            <b-form-checkbox
              class="checkbox"
              v-model="selectedWorkOrders"
              :value="data.item"
            />
          </template>
          <template #cell(number)="data">
            <b-link :to="{ name: 'technician-work-order', params: { id: data.item.id } }">
              {{ data.item.number }}
            </b-link>
            <status-badge
              class="mb-1"
              :status="data.item.status"
              :status-variants="statusVariants"
            />
            <div class="text-nowrap">
              {{ data.item | dateRangeFormat("date_bucket_start", "date_bucket_end") }}
            </div>
          </template>
          <template #cell(description)="data">
            <div
              style="height: 5rem"
              class="overflow-auto"
            >
              {{ data.item.description }}
            </div>
          </template>
          <template #cell(date_bucket)="data">
            <div class="text-nowrap">
              {{ data.item | dateRangeFormat("date_bucket_start", "date_bucket_end") }}
            </div>
          </template>
          <template #cell(location_id)="data">
            <div
              class="text-nowrap"
              v-b-tooltip.hover
              :title="`${data.item.location.street}, ${data.item.location.city}, ${data.item.location.state}, ${data.item.location.county}, ${data.item.location.zip_code}`"
            >
              {{ data.item.location.store_number }} - {{ data.item.client.name }}
            </div>
            <div class="text-nowrap">
              {{ data.item.location.street }}
            </div>
            <div class="text-nowrap">
              {{ { city: data.item.location.city, state: data.item.location.state } | locationFormat }}
            </div>
          </template>
          <template #cell(checked_in_date)="data">
            <span v-if="data.item.checked_in_at">
              {{ data.item.checked_in_at | shortDateTimeFormat(data.item.location.time_zone) }}
            </span>
          </template>
          <template #cell(checked_out_date)="data">
            <span v-if="data.item.checked_out_at">
              {{ data.item.checked_out_at | shortDateTimeFormat(data.item.location.time_zone) }}
            </span>
          </template>
          <template #cell(done_by_id)="data">
            <b-link
              v-if="data.item.done_by_id"
              :to="{ name: 'technician-user', params: { id: data.item.done_by.id } }"
            >
              {{ data.item.done_by.name }}
            </b-link>
            <div v-if="data.item.checked_out_at">
              {{ data.item.checked_out_at | shortDateTimeFormat(data.item.location.time_zone) }}
            </div>
          </template>
          <template #cell(cost)="data">
            <money-format
              :value="data.item.cost.amount"
              :currency-code="data.item.cost.currency"
              subunits-value
            />
          </template>
          <template #row-details="data">
            <b-alert
              class="text-left mb-0"
              variant="danger"
              dismissible
              fade
              :show="data.item.hasError"
              @dismissed="dismissErrorMessage(data.item)"
            >
              Work Order couldn't be reassigned, please try again later.
            </b-alert>
          </template>
        </b-table>
        <sfs-skeleton-table
          v-else
          :rows="pagination.perPage"
          :columns="columns.length"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="auto"
        :class="{ 'mr-auto': true, 'ml-auto': !showPerPage }"
      >
        <b-pagination
          v-if="showPagination"
          v-model="pagination.currentPage"
          :total-rows="pagination.maxResults > totalRows ? totalRows : pagination.maxResults"
          :per-page="pagination.perPage"
          last-class="d-none"
        />
      </b-col>
      <sfs-pagination-dropdown
        v-if="showPerPage"
        container-class="col-auto"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-rows="totalRows"
        @per-page-changed="onPerPageChanged"
      />
    </b-row>
  </div>
</template>

<script>
import urlMixin from '@/mixins/urlMixin';
import tableMixin from '@/mixins/tableMixin';
import translationMixin from '@/mixins/translationMixin';
import { TECHNICIAN_WORK_ORDER_TABLE_DEFAULT_COLUMNS, TECHNICIAN_STATUS_VARIANTS } from '@/constants/workOrders';
import StatusBadge from '@/components/shared/StatusBadge.vue';

export default {
  name: 'TechnicianWorkOrdersTable',
  components: {
    StatusBadge,
  },
  mixins: [urlMixin, tableMixin, translationMixin],
  props: {
    columns: {
      type: Array,
      default: TECHNICIAN_WORK_ORDER_TABLE_DEFAULT_COLUMNS,
    },
    workOrdersWithErrors: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      statusVariants: TECHNICIAN_STATUS_VARIANTS,
      selectedWorkOrders: [],
      erroredRecords: [...this.workOrdersWithErrors],
      isAllSelected: false,
    };
  },
  methods: {
    getRecords() {
      this.isLoadingData = true;
      this.$technicianWorkOrdersAPI
        .getWorkOrders(this.params)
        .then((response) => {
          const { total, workOrders } = response;
          this.totalRows = total;
          this.records = workOrders.map((workOrder) => {
            const hasError = this.erroredRecords?.some(errorWorkOrder => errorWorkOrder.id === workOrder.id);
            return { ...workOrder, hasError, _showDetails: hasError };
          });
        })
        .catch(() => {
          this.records = [];
        })
        .finally(() => {
          this.loaded = true;
          this.isLoadingData = false;
          this.$emit('data-changed', this.records);
        });
    },
    rowSelected(items) {
      this.selectedWorkOrders = items;
      this.$emit('selected-changed', this.selectedWorkOrders);
    },
    selectAllRows() {
      this.isAllSelected = true;
      this.$refs['work-orders-table'].selectAllRows();
    },
    clearAllRows() {
      this.isAllSelected = false;
      this.$refs['work-orders-table'].clearSelected();
    },
    dismissErrorMessage(item) {
      this.records.forEach(recordItem => {
        if(recordItem.id === item.id) {
          recordItem.hasError = false;
          recordItem._showDetails = false;
          recordItem._rowVariant = undefined;
        }
      });

      this.erroredRecords = this.erroredRecords.filter((r) => r.id !== item.id);
    }
  },
};
</script>
