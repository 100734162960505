<template>
  <sfs-base-table
    :data-provider="$workOrdersAPI.getWorkOrders"
    provider-key="workOrders"
    :columns="translatedColumns(modelName, columns)"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
  >
    <template #cell(number)="data">
      <b-link :to="{ name: 'work-order', params: { id: data.item.id } }">
        {{ data.item.number }}
      </b-link>
    </template>
    <template #cell(description)="data">
      <text-with-popover
        v-if="data.item.description != data.item.service?.name"
        :popover-id="`work-order-${data.item.id}-description`"
        :text="data.item.description"
      />
      <b-link
        v-if="data.item.service"
        class="font-size-sm text-prewrap"
        :to="{ name: 'service', params: { id: data.item.service.id } }"
      >
        {{ data.item.service.name }}
      </b-link>
    </template>
    <template #cell(date_bucket)="data">
      <div class="text-nowrap">
        {{ data.item | dateRangeFormat("date_bucket_start", "date_bucket_end") }}
        <div
          v-if="data.item.completion_source"
          class="font-size-sm text-secondary"
        >
          Source: {{ data.item.completion_source | humanizedFormat | capitalizeFormat }}
        </div>
      </div>
    </template>
    <template #cell(location_id)="data">
      <b-link
        :to="{ name: 'location', params: { id: data.item.location_id } }"
        v-b-tooltip.hover.window
        :title="`${data.item.location.street}, ${data.item.location.city}, ${data.item.location.state}, ${data.item.location.zip_code}`"
      >
        {{ data.item.location.store_number }}
        <span v-if="data.item.location.name">- {{ data.item.location.name }}</span>
      </b-link>
      <div class="text-nowrap font-size-sm text-secondary">
        {{ { city: data.item.location.city, state: data.item.location.state } | locationFormat }}
      </div>
    </template>
    <template #cell(client_id)="data">
      <b-link :to="{ name: 'client', params: { id: data.item.client.id } }">
        {{ data.item.client.name }}
      </b-link>
    </template>
    <template #cell(service_id)="data">
      <b-link
        v-if="data.item.service"
        :to="{ name: 'service', params: { id: data.item.service.id } }"
      >
        {{ data.item.service.name }}
      </b-link>
    </template>
    <template #cell(parent_id)="data">
      <b-link
        v-if="data.item.parent_id"
        :to="{ name: 'work-order', params: { id: data.item.parent_id } }"
      >
        {{ data.item.parent_number }}
      </b-link>
    </template>
    <template #cell(date_bucket_start)="data">
      {{ data.item.date_bucket_start | dateFormat }}
    </template>
    <template #cell(date_bucket_end)="data">
      {{ data.item.date_bucket_end | dateFormat }}
    </template>
    <template #cell(checked_in_date)="data">
      <span v-if="data.item.checked_in_at">
        {{ data.item.checked_in_at | dateTimeFormat(data.item.location.time_zone) }}
      </span>
    </template>
    <template #cell(checked_out_date)="data">
      <span v-if="data.item.checked_out_at">
        {{ data.item.checked_out_at | dateTimeFormat(data.item.location.time_zone) }}
      </span>
    </template>
    <template #cell(assigned_to_id)="data">
      <b-link
        v-if="data.item.assigned_to"
        :to="{ name: 'user', params: { id: data.item.assigned_to.id } }"
      >
        {{ data.item.assigned_to.name }}
      </b-link>
      <br>
      <span
        v-if="data.item.done_by && data.item.done_by_id != data.item.assigned_to_id"
        class="font-size-sm"
      >
        <b-link :to="{ name: 'user', params: { id: data.item.done_by.id } }">
          {{ data.item.done_by.name }}
        </b-link>
      </span>
    </template>
    <template #cell(done_by_id)="data">
      <b-link
        v-if="data.item.done_by_id"
        :to="{ name: 'user', params: { id: data.item.done_by.id } }"
      >
        {{ data.item.done_by.name }}
      </b-link>
    </template>
    <template #cell(status)="data">
      <status-badge
        :status="data.item.status"
        :sub-status="data.item.sub_status"
        :status-variants="statusVariants"
      />
      <span
        v-if="data.item.cancellation_reason"
        class="font-size-sm text-secondary"
      >
        {{ data.item.cancellation_reason | humanizedFormat | capitalizeFormat }}
      </span>
    </template>
    <template #cell(check_in_distance)="data">
      <div class="text-nowrap">
        <b-badge
          v-if="!['', undefined, null].includes(data.item.check_in_distance)"
          :variant="data.item.check_in_distance <= 1 ? 'success' : 'danger'"
        >
          {{ data.item.check_in_distance }} mi
        </b-badge>
      </div>
    </template>
    <template #cell(manager_id)="data">
      <b-link
        v-if="data.item.manager"
        :to="{ name: 'user', params: { id: data.item.manager.id } }"
      >
        {{ data.item.manager.name }}
      </b-link>
    </template>
    <template #cell(cost)="data">
      <money-format
        :value="data.item.cost.amount"
        :currency-code="data.item.cost.currency"
        subunits-value
      />
    </template>
    <template #cell(price)="data">
      <money-format
        v-if="$can('read_price', 'WorkOrderCharge')"
        :value="data.item.price.amount"
        :currency-code="data.item.price.currency"
        subunits-value
      />
    </template>
    <template #cell(square_footage)="data">
      <div
        v-if="data.item.location.stock_room_footage"
        class="text-nowrap"
      >
        Stock Room: {{ data.item.location.stock_room_footage }} sq. ft
      </div>
      <div
        v-if="data.item.location.sales_floor_footage"
        class="text-nowrap"
      >
        Sales Floor: {{ data.item.location.sales_floor_footage }} sq. ft
      </div>
    </template>
  </sfs-base-table>
</template>

<script>
import tablePropsMixin from '@/mixins/tablePropsMixin';
import translationMixin from '@/mixins/translationMixin';
import { WORK_ORDER_TABLE_DEFAULT_COLUMNS, WORK_ORDER_MODEL_NAME, STATUS_VARIANTS } from '@/constants/workOrders';
import StatusBadge from '@/components/shared/StatusBadge.vue';
import TextWithPopover from '@/components/shared/TextWithPopover.vue';

export default {
  name: 'WorkOrdersTable',
  components: {
    StatusBadge,
    TextWithPopover,
  },
  mixins: [tablePropsMixin, translationMixin],
  props: {
    columns: {
      type: Array,
      default: WORK_ORDER_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    return {
      modelName: WORK_ORDER_MODEL_NAME,
      statusVariants: STATUS_VARIANTS,
    };
  },
};
</script>
