import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '@/api/helpers/apiPath';

export default {
  getUserOwnerships(params) {
    return axios.get(adminPath('/user_ownerships?') + prepareQueryString(params)).then((response) => {
      return {
        userOwnerships: response.data.map((userOwnership) => an('UserOwnership', userOwnership)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  get(id) {
    return axios.get(adminPath(`/user_ownerships/${id}`)).then((response) => an('UserOwnership', response.data));
  },
  create(data) {
    return axios
      .post(adminPath('/user_ownerships'), {
        ...data,
      })
      .then((response) => an('UserOwnership', response.data));
  },
  destroy(id) {
    return axios.delete(adminPath(`/user_ownerships/${id}`));
  },
};
