import List from '@/pages/admin/clientFeedbacks/List.vue';
import New from '@/pages/admin/clientFeedbacks/New.vue';
import handlePaginationRouteProps from '@/utils/handlePaginationRouteProps.js';

export default [
  {
    path: 'client_feedbacks',
    name: 'client-feedbacks',
    component: List,
    props: handlePaginationRouteProps,
  },
  {
    path: 'client_feedbacks/new',
    name: 'new-client-feedback',
    component: New,
    props: handlePaginationRouteProps,
  },
];
