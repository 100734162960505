import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '@/api/helpers/apiPath';

export default {
  getCharges(params) {
    return axios.get(adminPath('/recurring_charges?') + prepareQueryString(params)).then((response) => {
      return {
        recurringCharges: response.data.map((recurringCharge) => an('RecurringCharge', recurringCharge)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getLocationRecurringCharges(locationId, params) {
    return axios.get(adminPath(`/locations/${locationId}/recurring_charges?`) + prepareQueryString(params)).then((response) => {
      return {
        recurringCharges: response.data.map((recurringCharge) => an('RecurringCharge', recurringCharge)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  updateLocationRecurringChargesSortOrder(locationId, data) {
    return axios.put(adminPath(`/locations/${locationId}/recurring_charges/update_sort_order`), {
      ...data,
    }).then((response) => an('RecurringCharge', response.data));
  },
  update(id, data) {
    return axios
      .patch(adminPath(`/recurring_charges/${id}`), {
        ...data,
      })
      .then((response) => an('RecurringCharge', response.data));
  },
  create(locationId, data) {
    return axios
      .post(adminPath(`/locations/${locationId}/recurring_charges`), {
        ...data,
      })
      .then((response) => an('RecurringCharge', response.data));
  },
  getFrequencyOptions() {
    return axios.get(adminPath('/recurring_charges/frequency_options'));
  },
  getSpreadsheet(params) {
    return axios.get(adminPath('/recurring_charges.csv?') + prepareQueryString(params), { responseType: 'blob' });
  },
};
