<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          v-if="isLoaded"
          ref="client-feedbacks-location-search-table"
          :items="records"
          :fields="translatedColumns(modelName, columns)"
          :busy="isLoadingData"
          :sort-by.sync="sorting.sortBy"
          :sort-desc.sync="sorting.sortDesc"
          @sort-changed="handleSortingChanged"
          no-local-sorting
          show-empty
          responsive
          table
        >
          <template #cell(store_number)="data">
            <b-link
              :to="{ name: 'location', params: { id: data.item.id } }"
              v-b-tooltip.hover.window
              :title="`${data.item.street}, ${data.item.city}, ${data.item.state}, ${data.item.zip_code}`"
            >
              {{ data.item.store_number }}
            </b-link>
            <div
              v-if="data.item.name"
              class="font-size-sm text-secondary"
            >
              {{ data.item.name }}
            </div>
          </template>
          <template #cell(last_feedback_date)="data">
            <span v-if="data.item.last_feedback_date">{{ data.item.last_feedback_date | dateFormat }}</span>
            <span v-else>-</span>
          </template>
          <template #cell(actions)="data">
            <b-button
              @click="() => $router.push({ name: 'new-client-feedback', query: { location_id: data.item.id } })"
              size="sm"
              variant="link"
              v-b-tooltip.hover.window
              title="Gather Feedback"
            >
              <i class="far fa-comment-dots" />
            </b-button>
          </template>
        </b-table>
        <sfs-skeleton-table
          v-else
          :rows="pagination.perPage"
          :columns="columns.length"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="auto"
        :class="{ 'mr-auto': true, 'ml-auto': !showPerPage }"
      >
        <b-pagination
          v-if="showPagination"
          v-model="pagination.currentPage"
          :total-rows="pagination.maxResults > totalRows ? totalRows : pagination.maxResults"
          :per-page="pagination.perPage"
          last-class="d-none"
        />
      </b-col>
      <sfs-pagination-dropdown
        v-if="showPerPage"
        container-class="col-auto"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-rows="totalRows"
        @per-page-changed="onPerPageChanged"
      />
    </b-row>
  </div>
</template>

<script>
import urlMixin from '@/mixins/urlMixin';
import tableMixin from '@/mixins/tableMixin';
import translationMixin from '@/mixins/translationMixin';
import { CLIENT_FEEDBACK_LOCATION_SEARCH_REPORT_TABLE_DEFAULT_COLUMNS } from '@/constants/reports';
import { LOCATION_MODEL_NAME } from '@/constants/locations';

export default {
  name: 'LocationSearchTable',
  mixins: [urlMixin, tableMixin, translationMixin],
  props: {
    columns: {
      type: Array,
      default: CLIENT_FEEDBACK_LOCATION_SEARCH_REPORT_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    return {
      modelName: LOCATION_MODEL_NAME,
    };
  },
  methods: {
    getRecords() {
      this.isLoadingData = true;
      this.$analyticsAPI
        .getClientFeedbacksLocationsReport(this.params)
        .then((response) => {
          const { total, records } = response;
          this.totalRows = total;
          this.records = records;
        })
        .catch(() => {
          this.records = [];
        })
        .finally(() => {
          this.loaded = true;
          this.isLoadingData = false;
          this.$emit('data-changed', this.records);
        });
    },
  },
};
</script>
