import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '@/api/helpers/apiPath';

export default {
  getLocationRecurringWorkOrders(locationId, params) {
    return axios
      .get(adminPath(`/locations/${locationId}/recurring_work_orders?`) + prepareQueryString(params))
      .then((response) => {
        return {
          recurringWorkOrders: response.data.map((recurringWorkOrder) => an('RecurringWorkOrder', recurringWorkOrder)),
          total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
        };
      });
  },
  update(id, data) {
    return axios
      .patch(adminPath(`/recurring_work_orders/${id}`), {
        ...data,
      })
      .then((response) => an('RecurringWorkOrder', response.data));
  },
  create(locationId, data) {
    return axios
      .post(adminPath(`/locations/${locationId}/recurring_work_orders`), {
        ...data,
      })
      .then((response) => an('RecurringWorkOrder', response.data));
  },
  destroy(id) {
    return axios.delete(adminPath(`/recurring_work_orders/${id}`));
  },
  getFrequencyOptions() {
    return axios.get(adminPath('/recurring_work_orders/frequency_options'));
  },
  getMonthOptions() {
    return axios.get(adminPath('/recurring_work_orders/month_options'));
  },
  getSpreadsheet(params) {
    return axios.get(adminPath('/recurring_work_orders.csv?') + prepareQueryString(params), { responseType: 'blob' });
  },
};
