<template>
  <b-form @submit.stop.prevent="handleSubmit">
    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.store_number"
          label-for="location-store_number"
        >
          <b-form-input
            id="location-store_number"
            v-model="$v.form.store_number.$model"
            :state="$v.form.store_number.$dirty ? !$v.form.store_number.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.store_number.$dirty"
            id="location-store_number-feedback"
          >
            <span v-if="!$v.form.store_number.required">Please enter a location number.</span>
            <span v-if="!$v.form.store_number.maxLength">
              The store number must be less than {{ $v.form.store_number.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.store_number.serverFailed">{{ serverErrors.store_number }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.client_ids"
          label-for="location-client_ids"
        >
          <v-select
            id="location-client_ids"
            v-model="form.client_ids"
            :options="clientsOptions"
            label="name"
            :class="{ 'is-invalid': $v.form.client_ids.$dirty && $v.form.client_ids.$error }"
            :reduce="(client) => client.id"
            multiple
          >
            <template #option="client">
              <div>{{ client.name }}</div>
              <div class="font-size-sm text-secondary">
                <em>{{ client.code }}</em>
              </div>
            </template>
            <template #no-options="{ search, searching }">
              <template v-if="searching">
                No results found for
                <em>{{ search }}</em>
              </template>
              <em
                style="opacity: 0.7"
                v-else
              >Type to search for clients</em>
            </template>
            <template #selected-option="client">
              {{ client.name }} ({{ client.code }})
            </template>
          </v-select>
          <b-form-invalid-feedback
            v-if="$v.form.client_ids.$dirty"
            id="location-client_ids-feedback"
          >
            <span v-if="!$v.form.client_ids.required">Please enter a {{ $store.getters.translate('client', 'client') }}.</span>
            <span v-if="!$v.form.client_ids.serverFailed">{{ serverErrors.client_ids }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.street"
          label-for="location-street"
        >
          <b-form-input
            id="location-street"
            v-model="$v.form.street.$model"
            :state="$v.form.street.$dirty ? !$v.form.street.$error : null"
            type="text"
            placeholder="123 Fake Street"
          />
          <b-form-invalid-feedback
            v-if="$v.form.street.$dirty"
            id="location-street-feedback"
          >
            <span v-if="!$v.form.street.required">Please enter a street.</span>
            <span v-if="!$v.form.street.maxLength">
              The street must be less than {{ $v.form.street.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.street.serverFailed">{{ serverErrors.street }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.city"
          label-for="location-city"
        >
          <b-form-input
            id="location-city"
            v-model="$v.form.city.$model"
            :state="$v.form.city.$dirty ? !$v.form.city.$error : null"
            type="text"
            placeholder="New York"
          />
          <b-form-invalid-feedback
            v-if="$v.form.city.$dirty"
            id="location-city-feedback"
          >
            <span v-if="!$v.form.city.required">Please enter a city.</span>
            <span v-if="!$v.form.city.maxLength">
              The city must be less than {{ $v.form.city.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.city.serverFailed">{{ serverErrors.city }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.state"
          label-for="location-state"
        >
          <b-form-input
            id="location-state"
            v-model="$v.form.state.$model"
            :state="$v.form.state.$dirty ? !$v.form.state.$error : null"
            type="text"
            placeholder="NY"
          />
          <b-form-invalid-feedback
            v-if="$v.form.state.$dirty"
            id="location-state-feedback"
          >
            <span v-if="!$v.form.state.required">Please enter a state.</span>
            <span v-if="!$v.form.state.maxLength">
              The state must be less than {{ $v.form.state.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.state.serverFailed">{{ serverErrors.state }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.county"
          label-for="location-county"
        >
          <b-form-input
            id="location-county"
            v-model="$v.form.county.$model"
            :state="$v.form.county.$dirty ? !$v.form.county.$error : null"
            type="text"
            placeholder="Brooklyn"
          />
          <b-form-invalid-feedback
            v-if="$v.form.county.$dirty"
            id="location-county-feedback"
          >
            <span v-if="!$v.form.county.maxLength">
              The county must be less than {{ $v.form.county.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.county.serverFailed">{{ serverErrors.county }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.zip_code"
          label-for="location-zip_code"
        >
          <b-form-input
            id="location-zip_code"
            v-model="$v.form.zip_code.$model"
            :state="$v.form.zip_code.$dirty ? !$v.form.zip_code.$error : null"
            type="text"
            placeholder="55555"
          />
          <b-form-invalid-feedback
            v-if="$v.form.zip_code.$dirty"
            id="location-zip_code-feedback"
          >
            <span v-if="!$v.form.zip_code.required">Please enter a zip code.</span>
            <span v-if="!$v.form.zip_code.maxLength">
              The zip code must be less than {{ $v.form.zip_code.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.zip_code.serverFailed">{{ serverErrors.zip_code }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.district"
          label-for="location-district"
        >
          <b-form-input
            id="location-district"
            v-model="$v.form.district.$model"
            :state="$v.form.district.$dirty ? !$v.form.district.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.district.$dirty"
            id="location-district-feedback"
          >
            <!-- prettier-ignore -->
            <span v-if="!$v.form.district.maxLength">
              The district must be less than {{ $v.form.district.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.district.serverFailed">{{ serverErrors.district }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.name"
          label-for="location-name"
        >
          <b-form-input
            id="location-name"
            v-model="$v.form.name.$model"
            :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.name.$dirty"
            id="location-name-feedback"
          >
            <span v-if="!$v.form.name.maxLength">
              The name must be less than {{ $v.form.name.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.name.serverFailed">{{ serverErrors.name }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.manager_id"
          label-for="location-manager_id"
        >
          <employee-select
            id="location-manager_id"
            :select-class="{ 'is-invalid': $v.form.manager_id.$dirty && $v.form.manager_id.$error }"
            :value="form.manager"
            @input="onManagerChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.manager_id.$dirty"
            id="location-manager_id-feedback"
          >
            <span v-if="!$v.form.manager_id.serverFailed">{{ serverErrors.manager_id }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.floor_type"
          label-for="location-floor_type"
        >
          <floor-type-select
            id="location-floor_type"
            :select-class="{ 'is-invalid': $v.form.floor_type.$dirty && $v.form.floor_type.$error }"
            v-model="$v.form.floor_type.$model"
          />
          <b-form-invalid-feedback
            v-if="$v.form.floor_type.$dirty"
            id="location-floor_type-feedback"
          >
            <span v-if="!$v.form.floor_type.serverFailed">{{ serverErrors.floor_type }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.customer_identifier"
          label-for="location-customer_identifier"
        >
          <b-form-input
            id="location-customer_identifier"
            v-model="$v.form.customer_identifier.$model"
            :state="$v.form.customer_identifier.$dirty ? !$v.form.customer_identifier.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.customer_identifier.$dirty"
            id="location-customer_identifier-feedback"
          >
            <!-- prettier-ignore -->
            <span v-if="!$v.form.customer_identifier.maxLength">
              The customer identifier must be less than {{ $v.form.customer_identifier.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.customer_identifier.serverFailed">{{ serverErrors.customer_identifier }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.stock_room_footage"
          label-for="location-stock_room_footage"
        >
          <b-input-group append="sq. ft">
            <b-form-input
              id="location-stock_room_footage"
              v-model="$v.form.stock_room_footage.$model"
              :state="$v.form.stock_room_footage.$dirty ? !$v.form.stock_room_footage.$error : null"
              type="text"
            />
          </b-input-group>
          <b-form-invalid-feedback
            v-if="$v.form.stock_room_footage.$dirty"
            id="location-stock_room_footage-feedback"
          >
            <span v-if="!$v.form.stock_room_footage.integer">Please enter a number.</span>
            <span v-if="!$v.form.stock_room_footage.serverFailed">{{ serverErrors.stock_room_footage }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.sales_floor_footage"
          label-for="location-sales_floor_footage"
        >
          <b-input-group append="sq. ft">
            <b-form-input
              id="location-sales_floor_footage"
              v-model="$v.form.sales_floor_footage.$model"
              :state="$v.form.sales_floor_footage.$dirty ? !$v.form.sales_floor_footage.$error : null"
              type="text"
            />
          </b-input-group>
          <b-form-invalid-feedback
            v-if="$v.form.sales_floor_footage.$dirty"
            id="location-sales_floor_footage-feedback"
          >
            <span v-if="!$v.form.sales_floor_footage.integer">Please enter a number.</span>
            <span v-if="!$v.form.sales_floor_footage.serverFailed">
              {{ serverErrors.sales_floor_footage }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <h3>Supply Orders</h3>
    <b-row>
      <b-col cols="12">
        <b-form-group label-for="location-deliver_to_organization">
          <b-form-checkbox
            switch
            id="location-deliver_to_organization"
            v-model="$v.form.supply_order_deliver_to_organization.$model"
          >
            {{ fieldLabels.supply_order_deliver_to_organization }}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.supply_order_delivery_notes"
          label-for="location-delivery_notes"
        >
          <b-form-textarea
            id="location-delivery_notes"
            v-model="$v.form.supply_order_delivery_notes.$model"
            autocomplete="new-password"
            :state="$v.form.supply_order_delivery_notes.$dirty ? !$v.form.supply_order_delivery_notes.$error : null"
          />
          <b-form-invalid-feedback
            v-if="$v.form.supply_order_delivery_notes.$dirty"
            id="location-delivery_notes-feedback"
          >
            <span v-if="!$v.form.supply_order_delivery_notes.maxLength">
              The delivery notes must be less than {{ $v.form.supply_order_delivery_notes.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.supply_order_delivery_notes.serverFailed">{{ serverErrors.supply_order_delivery_notes }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.supply_order_internal_notes"
          label-for="location-internal_notes"
        >
          <b-form-textarea
            id="location-internal_notes"
            v-model="$v.form.supply_order_internal_notes.$model"
            autocomplete="new-password"
            :state="$v.form.supply_order_internal_notes.$dirty ? !$v.form.supply_order_internal_notes.$error : null"
          />
          <b-form-invalid-feedback
            v-if="$v.form.supply_order_internal_notes.$dirty"
            id="location-internal_notes-feedback"
          >
            <span v-if="!$v.form.supply_order_internal_notes.maxLength">
              The internal notes must be less than {{ $v.form.supply_order_internal_notes.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.supply_order_internal_notes.serverFailed">{{ serverErrors.supply_order_internal_notes }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          id="location-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          id="location-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, maxLength, integer } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import EmployeeSelect from '@/components/admin/users/EmployeeSelect.vue';
import FloorTypeSelect from '@/components/admin/locations/FloorTypeSelect.vue';

import { LOCATIONS_FORM_LABELS } from '@/constants/locations.js';
import pluralize from 'pluralize';

export default {
  name: 'LocationsForm',
  components: {
    EmployeeSelect,
    FloorTypeSelect,
  },
  mixins: [formMixin],
  props: {
    name: String,
    store_number: String,
    customer_identifier: String,
    stock_room_footage: [String, Number],
    sales_floor_footage: [String, Number],
    floor_type: String,
    district: String,
    street: String,
    city: String,
    state: String,
    county: String,
    zip_code: String,
    manager_id: [String, Number],
    manager: Object,
    client_ids: Array,
    supply_order_delivery_notes: String,
    supply_order_internal_notes: String,
    supply_order_deliver_to_organization: Boolean
  },
  data() {
    return {
      form: {
        name: this.name,
        store_number: this.store_number,
        customer_identifier: this.customer_identifier,
        stock_room_footage: this.stock_room_footage,
        sales_floor_footage: this.sales_floor_footage,
        floor_type: this.floor_type,
        district: this.district,
        street: this.street,
        city: this.city,
        state: this.state,
        county: this.county,
        zip_code: this.zip_code,
        manager_id: this.manager_id,
        manager: this.manager,
        client_ids: this.client_ids,
        supply_order_delivery_notes: this.supply_order_delivery_notes,
        supply_order_internal_notes: this.supply_order_internal_notes,
        supply_order_deliver_to_organization: this.supply_order_deliver_to_organization,
      },
      clientsOptions: [],
      fieldLabels: {
        ...LOCATIONS_FORM_LABELS,
        store_number: `${this.$store.getters.translate('locations.store_number', 'Store Number')} *`,
        client_ids: `${pluralize(this.$store.getters.translate('client', 'Client'))} *`,
        street: 'Street *',
        city: 'City *',
        state: 'State *',
        zip_code: 'Zip Code *',
        supply_order_delivery_notes: 'Delivery Notes',
        supply_order_internal_notes: 'Internal Notes (Employees only)',
        supply_order_deliver_to_organization: 'Supplies should be delivered to Organization'
      },
    };
  },
  validations: {
    form: {
      name: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('name');
        },
      },
      store_number: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('store_number');
        },
      },
      customer_identifier: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('customer_identifier');
        },
      },
      stock_room_footage: {
        integer,
        serverFailed() {
          return !this.hasServerErrors('stock_room_footage');
        },
      },
      sales_floor_footage: {
        integer,
        serverFailed() {
          return !this.hasServerErrors('sales_floor_footage');
        },
      },
      floor_type: {
        serverFailed() {
          return !this.hasServerErrors('floor_type');
        },
      },
      district: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('district');
        },
      },
      street: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('street');
        },
      },
      city: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('city');
        },
      },
      state: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('state');
        },
      },
      county: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('county');
        },
      },
      zip_code: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('zip_code');
        },
      },
      manager_id: {
        serverFailed() {
          return !this.hasServerErrors('manager_id');
        },
      },
      client_ids: {
        required,
        serverFailed() {
          return !this.hasServerErrors('client_ids');
        },
      },
      supply_order_delivery_notes: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('supply_order_delivery_notes');
        },
      },
      supply_order_internal_notes: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('supply_order_internal_notes');
        },
      },
      supply_order_deliver_to_organization: {},
    },
  },
  mounted() {
    this.getClientOptions();
  },
  methods: {
    getClientOptions() {
      this.$clientsAPI.getClients({ paginate: false, active: true }).then((response) => {
        this.clientsOptions = response.clients;
      });
    },
    onManagerChange(user) {
      this.form.manager_id = user ? user.id : '';
      this.form.manager = user;
    },
  },
};
</script>
