<template>
  <b-form
    @submit.stop.prevent="handleSubmit"
    class="text-left"
  >
    <b-row>
      <b-col cols="12">
        <b-form-group
          v-if="newForm"
          :label="fieldLabels.client_id"
          label-for="recurring-work-order-client_id"
        >
          <client-select
            name="recurring-work-order-client_id"
            :select-class="{ 'is-invalid': $v.form.client_id.$dirty && $v.form.client_id.$error }"
            :location-id="location_id"
            :value="form.client"
            @input="onClientChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.client_id.$dirty"
            class="recurring-work-order-client_id-feedback"
          >
            <span v-if="!$v.form.client_id.required">Please enter a {{ $store.getters.translate('client', 'client') }}.</span>
            <span v-if="!$v.form.client_id.serverFailed">
              {{ this.serverErrors.client_id && this.serverErrors.client_id }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          v-if="newForm"
          :label="fieldLabels.service_id"
          label-for="recurring-work-order-service_id"
        >
          <service-select
            :select-class="{ 'is-invalid': $v.form.service_id.$dirty && $v.form.service_id.$error }"
            name="recurring-work-order-service_id"
            :client-id="form.client_id"
            client-unassigned
            :value="form.service"
            @input="onServiceChange"
            :key="form.client_id"
          />
          <b-form-invalid-feedback
            v-if="$v.form.service_id.$dirty"
            class="recurring-work-order-service_id-feedback"
          >
            <span v-if="!$v.form.service_id.serverFailed">{{ this.serverErrors.service_id }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.description"
          label-for="recurring-work-order-description"
        >
          <b-form-textarea
            name="recurring-work-order-description"
            v-model="$v.form.description.$model"
            :state="$v.form.description.$dirty ? !$v.form.description.$error : null"
          />
          <b-form-invalid-feedback
            v-if="$v.form.description.$dirty"
            class="recurring-work-order-description-feedback"
          >
            <span v-if="!$v.form.description.required">Please enter a description.</span>
            <span v-if="!$v.form.description.serverFailed">{{ this.serverErrors.description }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.priority"
          label-for="recurring-work-order-priority"
        >
          <b-form-input
            name="recurring-work-order-priority"
            v-model="$v.form.priority.$model"
            :state="$v.form.priority.$dirty ? !$v.form.priority.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.priority.$dirty"
            class="recurring-work-order-priority-feedback"
          >
            <span v-if="!$v.form.priority.required">Please enter a priority.</span>
            <span v-if="!$v.form.priority.maxLength">
              The priority must be less than {{ $v.form.priority.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.priority.serverFailed">{{ this.serverErrors.priority }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.maintainance_type"
          label-for="recurring-work-order-maintainance_type"
        >
          <b-form-input
            name="recurring-work-order-maintainance_type"
            v-model="$v.form.maintainance_type.$model"
            :state="$v.form.maintainance_type.$dirty ? !$v.form.maintainance_type.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.maintainance_type.$dirty"
            class="recurring-work-order-maintainance_type-feedback"
          >
            <!-- prettier-ignore -->
            <span v-if="!$v.form.maintainance_type.maxLength">
              The maintenance type must be less than {{ $v.form.maintainance_type.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.maintainance_type.serverFailed">{{ this.serverErrors.maintainance_type }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.price"
          label-for="recurring-work-order-price"
        >
          <b-input-group prepend="$">
            <b-form-input
              name="recurring-work-order-price"
              v-model="$v.form.price.$model"
              :state="$v.form.price.$dirty ? !$v.form.price.$error : null"
              type="text"
            />
          </b-input-group>
          <b-form-invalid-feedback
            v-if="$v.form.price.$dirty"
            :state="$v.form.price.$dirty ? !$v.form.price.$error : null"
            class="recurring-work-order-price-feedback"
          >
            <span v-if="!$v.form.price.required">Please enter a price.</span>
            <span v-if="!$v.form.price.decimal">Please enter a number.</span>
            <span v-if="!$v.form.price.serverFailed">
              {{ this.serverErrors.price_cents }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.category"
          label-for="recurring-work-order-category"
        >
          <category-select
            name="recurring-work-order-category"
            v-model="form.category"
            :select-class="{ 'is-invalid': $v.form.category.$dirty && $v.form.category.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.category.$dirty"
            class="recurring-work-order-category-feedback"
          >
            <span v-if="!$v.form.category.required">Please enter a category.</span>
            <span v-if="!$v.form.category.maxLength">
              The category must be less than {{ $v.form.category.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.category.serverFailed">{{ serverErrors.category }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          class="recurring-work-order-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          class="recurring-work-order-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, maxLength, decimal, requiredIf } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import ClientSelect from '@/components/admin/clients/Select.vue';
import ServiceSelect from '@/components/admin/services/Select.vue';
import CategorySelect from '@/components/admin/workOrderCharges/CategorySelect.vue';

export default {
  name: 'RecurringChargesForm',
  components: {
    ClientSelect,
    ServiceSelect,
    CategorySelect,
  },
  mixins: [formMixin],
  props: {
    description: String,
    priority: String,
    maintainance_type: String,
    price: Object,
    frequencies: Object,
    category: String,
    client_id: Number,
    location_id: Number,
    service_id: Number,
    client: Object,
    service: Object,
    newForm: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      form: {
        description: this.description,
        priority: this.priority,
        maintainance_type: this.maintainance_type,
        price: this.price && this.price.amount ? this.price.amount / 100 : null,
        price_cents: this.price && this.price.amount ? this.price.amount : null,
        frequencies: {},
        category: this.category,
        client_id: this.client_id,
        service_id: this.service_id,
        client: this.client,
        service: this.service,
      },
      fieldLabels: {
        description: 'Description *',
        priority: 'Priority *',
        maintainance_type: 'Maintainance Type',
        price: 'Price *',
        category: 'Charge Category *',
        client_id: `${this.$store.getters.translate('client', 'Client')} *`,
        service_id: 'Service',
      },
    };
  },
  validations: {
    form: {
      description: {
        required,
        serverFailed() {
          return !this.hasServerErrors('description');
        },
      },
      priority: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('priority');
        },
      },
      maintainance_type: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('maintainance_type');
        },
      },
      price: {
        required,
        decimal,
        serverFailed() {
          return !this.hasServerErrors('price_cents');
        },
      },
      category: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('category');
        },
      },
      client_id: {
        required: requiredIf(function () {
          return this.newForm;
        }),
        serverFailed() {
          return !this.hasServerErrors('client_id');
        },
      },
      service_id: {
        serverFailed() {
          return !this.hasServerErrors('service_id');
        },
      },
    },
  },
  watch: {
    'form.price': function (newAmount) {
      this.form.price_cents = isNaN(newAmount) ? null : newAmount * 100;
    },
  },
  methods: {
    onClientChange(client) {
      this.form.client_id = client ? client.id : null;
      this.form.client = client;
    },
    onServiceChange(service) {
      this.form.service_id = service ? service.id : '';
      this.form.service = service;
    },
  },
};
</script>
