<template>
  <b-modal
    :id="modalId"
    title="Feedback Responses"
    size="xl"
    class="text-left"
    no-close-on-backdrop
    hide-footer
    static
    lazy
  >
    <template #modal-header-close>
      <i class="fa fa-close" />
    </template>
    <b-row v-if="loading">
      <b-col>
        <b-skeleton
          height="375px"
          width="100%"
          animation="wave"
        />
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col
        cols="12"
        sm="6"
        v-for="(customData, index) in customFieldValues"
        :key="index"
      >
        <b-row class="text-muted font-w500 mb-1">
          <b-col class="text-prewrap">
            {{ customData.custom_field?.label }}
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <display-value-field
              :custom-field="customData.custom_field"
              :value="customData.value"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr class="mt-4">
    <b-row>
      <b-col
        cols="3"
        offset="9"
      >
        <b-button
          variant="primary"
          block
          class="responses-modal-close-button"
          @click="closeModal"
        >
          Close
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import DisplayValueField from '@/components/admin/customFields/DisplayValueField.vue';

export default {
  name: 'ClientFeedbackResponseModal',
  components: {
    DisplayValueField,
  },
  props: {
    value: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      loading: true,
      customFieldValues: [],
      modalId: `client-feedbacks-${this.value.id}-custom-field-values-modal`,
    };
  },
  mounted() {
    this.$root.$on('bv::modal::show', (_, modalId) => {
      if (modalId == this.modalId) {
        this.loading = true;
        this.loadResponses();
      }
    });
  },
  methods: {
    closeModal() {
      this.$bvModal.hide(this.modalId);
    },
    loadResponses() {
      this.$customFieldsAPI.getClientFeedbackValues(this.value.id).then(({ customFieldValues }) => {
        this.customFieldValues = customFieldValues;
        this.loading = false;
      });
    },
  },
};
</script>
