import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { technicianPath } from '@/api/helpers/apiPath';

export default {
  getClients(params) {
    return axios.get(technicianPath('/clients?') + prepareQueryString(params)).then((response) => {
      return {
        clients: response.data.map((location) => an('Client', location)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  get(id) {
    return axios.get(technicianPath(`/clients/${id}`)).then((response) => an('Client', response.data));
  },
};
