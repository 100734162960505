import Profitability from '@/pages/admin/reports/workOrders/Profitability.vue';
import Taxes from '@/pages/admin/reports/invoices/Taxes.vue';
import TotalOverTime from '@/pages/admin/reports/workOrders/TotalOverTime.vue';
import LocationSearch from '@/pages/admin/reports/clientFeedbacks/LocationSearch.vue';
import DicksSportingGoodPerformance from '@/pages/admin/reports/clientFeedbacks/DicksSportingGoodPerformance.vue';
import Revenue from '@/pages/admin/reports/invoices/Revenue.vue';
import Technicians from '@/pages/admin/reports/users/Technicians.vue';
import TechniciansForecast from '@/pages/admin/reports/users/TechniciansForecast.vue';
import TechnicianDashboard from '@/pages/admin/reports/users/TechnicianDashboard.vue';
import handlePaginationRouteProps from '@/utils/handlePaginationRouteProps.js';

export default [
  {
    path: 'reports/work_orders/profitability',
    name: 'work-orders-profitability-report',
    component: Profitability,
    props: handlePaginationRouteProps,
  },
  {
    path: 'reports/invoices/taxes',
    name: 'invoices-taxes-report',
    component: Taxes,
    props: handlePaginationRouteProps,
  },
  {
    path: 'reports/work_orders/total_over_time',
    name: 'work-orders-total-over-time',
    component: TotalOverTime,
    props: handlePaginationRouteProps,
  },
  {
    path: 'reports/client_feedbacks/location_search',
    name: 'client-feedbacks-location-search',
    component: LocationSearch,
    props: handlePaginationRouteProps,
  },
  {
    path: 'reports/client_feedbacks/dicks_sporting_goods_kpi',
    name: 'client-feedbacks-dicks-sporting-goods-kpi',
    component: DicksSportingGoodPerformance,
    props: handlePaginationRouteProps,
  },
  {
    path: 'reports/invoices/revenue',
    name: 'invoices-revenue-report',
    component: Revenue,
    props: handlePaginationRouteProps,
  },
  {
    path: 'reports/technicians/kpi',
    name: 'technicians-report',
    component: Technicians,
    props: handlePaginationRouteProps,
  },
  {
    path: 'reports/technicians/forecast',
    name: 'technicians-forecast-report',
    component: TechniciansForecast,
    props: handlePaginationRouteProps,
  },
  {
    path: 'reports/technicians/:id(\\d+)',
    name: 'technician-report',
    component: TechnicianDashboard,
    props: handlePaginationRouteProps,
  },
];
