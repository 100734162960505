<template>
  <sfs-base-table
    :key="tableKey"
    :data-provider="$quotesAPI.getItems"
    :data-id="quote.id"
    provider-key="quoteItems"
    :columns="translatedColumns(modelName, columns)"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
    :append-to-query="false"
    :skeleton-rows="2"
    @data-changed="updateEstimatedTaxes"
  >
    <template #cell(description)="data">
      <text-with-popover
        v-if="data.item.description != data.item.service?.name"
        :popover-id="`quote-item-${data.item.id}-description`"
        :text="data.item.description"
      />
      <b-link
        v-if="data.item.service_id"
        class="font-size-sm text-prewrap"
        :to="{ name: 'service', params: { id: data.item.service_id } }"
      >
        {{ data.item.service.name }}
      </b-link>
    </template>
    <template #cell(cost)="data">
      <money-format
        :value="data.item.total_cost.amount"
        :currency-code="data.item.total_cost.currency"
        subunits-value
      />
      <div
        v-if="data.item.quantity != 1"
        class="font-size-sm text-secondary"
      >
        <money-format
          :value="data.item.cost.amount"
          :currency-code="data.item.cost.currency"
          subunits-value
        />
      </div>
    </template>
    <template #cell(price)="data">
      <money-format
        :value="data.item.total_price.amount"
        :currency-code="data.item.total_price.currency"
        subunits-value
      />
      <div
        v-if="data.item.quantity != 1"
        class="font-size-sm text-secondary"
      >
        <money-format
          :value="data.item.price.amount"
          :currency-code="data.item.price.currency"
          subunits-value
        />
      </div>
    </template>
    <template #cell(taxes)="data">
      <div class="text-nowrap tax-rate-info">
        <div v-if="taxAmounts[data.item.id] > 0">
          <money-format
            :id="`tax-rate-info-${data.item.id}`"
            class="d-inline"
            :value="taxAmounts[data.item.id]"
            :currency-code="data.item.price.currency"
            subunits-value
          />
          <tax-rate-popover
            :target="`tax-rate-info-${data.item.id}`"
            :tax-rates="[taxRates[data.item.id]]"
          />
        </div>
        <div v-else-if="taxAmounts[data.item.id] === 0" />
        <div v-else>
          <b-skeleton />
        </div>
      </div>
    </template>
    <template #cell(actions)="data">
      <b-button-group>
        <b-button
          v-if="$can('update', data.item)"
          v-b-modal="`quote-item-${data.item.id}-edit-modal`"
          class="mr-1"
          size="sm"
          variant="link"
        >
          <i class="fa fa-pencil-alt" />
        </b-button>
        <b-button
          v-if="$can('destroy', data.item)"
          size="sm"
          variant="link"
          @click="destroyQuoteItemDialog(data.item.id)"
        >
          <i class="fa fa-times" />
        </b-button>
        <edit-modal
          v-if="$can('update', data.item)"
          :key="`quote-items-edit-modal-${data.item.id}`"
          :value="data.item"
          :quote="quote"
          @on-save="refreshTable"
        />
      </b-button-group>
    </template>
  </sfs-base-table>
</template>

<script>
import tablePropsMixin from '@/mixins/tablePropsMixin';
import translationMixin from '@/mixins/translationMixin';
import { QUOTE_ITEM_MODEL_NAME, QUOTE_ITEM_TABLE_DEFAULT_COLUMNS } from '@/constants/quoteItems';
import EditModal from '@/components/admin/quoteItems/EditModal.vue';
import TaxRatePopover from '@/components/admin/taxRates/TaxRatePopover.vue';
import TextWithPopover from '@/components/shared/TextWithPopover.vue';

export default {
  name: 'QuoteItemsTable',
  mixins: [tablePropsMixin, translationMixin],
  components: {
    EditModal,
    TextWithPopover,
    TaxRatePopover,
  },
  props: {
    quote: {
      type: Object,
      required: true,
    },
    columns: {
      type: Array,
      default: () => QUOTE_ITEM_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    return {
      taxAmounts: {},
      taxRates: {},
      modelName: QUOTE_ITEM_MODEL_NAME,
    };
  },
  methods: {
    destroyQuoteItemDialog(id) {
      this.$swal({
        text: 'Once it\'s deleted, its gone forever!',
        preConfirm: () => this.$quotesAPI.destroyItem(id),
      }).then((result) => {
        if (result.value) {
          this.refreshTable();
        }
        result.timer;
      });
    },
    updateEstimatedTaxes(records) {
      records.forEach((item) => this.calculateEstimatedTaxes(item));
    },
    calculateEstimatedTaxes(item) {
      const id = item.id;
      this.$taxRatesAPI.getQuoteItemsTaxRates(id, {})
        .then((response) => {
          const { total, taxRates } = response;
          if (total == 0) {
            this.taxAmounts[id] = 0;
          } else {
            const taxRate = taxRates[0];
            this.taxAmounts[id] = (item.quantity * item.price.amount) * taxRate.rate;
            this.taxRates[id] = taxRate;
          }
          this.taxAmounts = Object.assign({}, this.taxAmounts);
          this.taxRates = Object.assign({}, this.taxRates);
        });
    },
    marginClass(margin) {
      const positiveMargin = margin > 0;
      return {
        'px-2': true,
        'py-1': true,
        'font-size-sm': true,
        'font-w600': true,
        'd-inline': true,
        'text-danger': !positiveMargin,
        'bg-danger-light': !positiveMargin,
        'text-success': positiveMargin,
        'bg-success-light': positiveMargin,
      };
    },
  },
};
</script>
