<template>
  <sfs-base-table
    :key="tableKey"
    :data-provider="$walkthroughsAPI.getWalkthroughs"
    provider-key="walkthroughs"
    :columns="translatedColumns(modelName, columns)"
    :filters="{ ...filters, include_photos: true }"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
  >
    <template #cell(date)="data">
      <b-link :to="{ name: 'walkthrough', params: { id: data.item.id } }">
        {{ data.item.date | dateFormat }}
      </b-link>
    </template>
    <template #cell(location_id)="data">
      <b-link
        :to="{ name: 'location', params: { id: data.item.location_id } }"
        v-b-tooltip.hover.window
        :title="`${data.item.location.street}, ${data.item.location.city}, ${data.item.location.state}, ${data.item.location.zip_code}`"
      >
        {{ data.item.location.store_number }}
        <span v-if="data.item.location.name">- {{ data.item.location.name }}</span>
      </b-link>
      <div class="text-nowrap font-size-sm text-secondary">
        {{ { address: data.item.location.street, city: data.item.location.city, state: data.item.location.state } | locationFormat }}
      </div>
    </template>
    <template #cell(client_id)="data">
      <b-link
        :to="{ name: 'client', params: { id: data.item.client_id } }"
      >
        {{ data.item.client.name }}
      </b-link>
    </template>
    <template #cell(user_id)="data">
      <b-link :to="{ name: 'user', params: { id: data.item.user_id } }">
        {{ data.item.user.name }}
      </b-link>
      <div class="text-nowrap font-size-sm text-secondary">
        Last modified {{ data.item.updated_at | dateOrTimeFormat }}
      </div>
    </template>

    <template #cell(photos)="data">
      <b-img
        rounded
        width="50"
        height="50"
        class="ml-2"
        v-for="(photo) in data.item.photos"
        :key="photo.id"
        :src="photo.url"
      />
    </template>
  </sfs-base-table>
</template>

<script>
import tablePropsMixin from '@/mixins/tablePropsMixin';
import translationMixin from '@/mixins/translationMixin';
import { WALKTHROUGH_TABLE_DEFAULT_COLUMNS, WALKTHROUGH_MODEL_NAME } from '@/constants/walkthroughs';
export default {
  name: 'WalkthroughsTable',
  mixins: [tablePropsMixin, translationMixin],
  props: {
    columns: {
      type: Array,
      default: () => WALKTHROUGH_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    return {
      modelName: WALKTHROUGH_MODEL_NAME,
    };
  },
};
</script>
