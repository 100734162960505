<template>
  <b-input-group>
    <flat-pickr
      :id="pickerId"
      :name="name"
      :value="value"
      :config="dateConfig"
      :placeholder="placeholder"
      :disabled="disabled"
      :class="{ 'flat-pickr': true, 'form-control': true, 'border-right-0': true, ... withClass }"
      @input="(date) => $emit('input', date)"
    />
    <b-input-group-append
      class="flatpickr-icon-container"
      :readonly="disabled"
      @click="openFlatpickr"
    >
      <i class="si si-calendar" />
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import flatpickr from 'flatpickr';
import { uniqueId as _uniqueId } from 'lodash';

export default {
  name: 'DatePicker',
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    value: {
      type: [String, Date],
    },
    withClass: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    static: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
    },
  },
  data() {
    return {
      dateConfig: {
        altInput: true,
        allowInput: true,
        altFormat: 'M j, Y',
        static: this.static,
      },
      pickerId: this.id || `sfs-date-picker-${_uniqueId()}`,
    };
  },
  methods: {
    openFlatpickr() {
      if (!this.disabled) {
        const fp = flatpickr(`#${this.pickerId}`, this.dateConfig);
        fp.open();
      }
    }
  },
};
</script>
