import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const VERSION_TABLE_NAME = 'versions';
// The columns available to be shown in a versions table.
export const VERSION_TABLE_COLUMNS = {
  model: { label: 'Record', key: 'model' },
  model_id: { label: 'Record ID', key: 'model_id' },
  event: { label: 'Event', key: 'event' },
  user_id: { label: 'User', key: 'user_id' },
  created_at: { label: 'Event Date', key: 'created_at' },
  start_date: { label: 'Start Date', key: 'start_date' },
  end_date: { label: 'End Date', key: 'end_date' },
  changes: { label: 'Changes', key: 'changes' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const VERSION_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(VERSION_TABLE_COLUMNS);

// The default columns to be shown when the clients table loads.
export const VERSION_TABLE_DEFAULT_COLUMNS = [
  VERSION_TABLE_COLUMNS.model,
  VERSION_TABLE_COLUMNS.event,
  VERSION_TABLE_COLUMNS.user_id,
  VERSION_TABLE_COLUMNS.created_at,
  VERSION_TABLE_COLUMNS.changes,
];

export const VERSION_MODEL_ROUTES = {
  Client: 'client',
  Location: 'location',
  InsuranceCertificate: 'insurance-certificate',
  Invoice: 'invoice',
  Quote: 'quote',
  Service: 'service',
  TechnicianPayment: 'technician-payment',
  User: 'user',
  Vendor: 'vendor',
  WorkOrder: 'work-order',
};

export const VERSION_MODEL_OPTIONS = [
  'Client',
  'CustomField',
  'Document',
  'Import',
  'InsuranceCertificate',
  'IntegrationReference',
  'InvoiceItem',
  'Invoice',
  'IosCode',
  'Location',
  'ManagerReview',
  'Organization',
  'Quote',
  'RecurringCharge',
  'RecurringPayment',
  'RecurringWorkOrder',
  'Section',
  'Service',
  'SupplyOrder',
  'Supply',
  'TechnicianPaymentItem',
  'TechnicianPayment',
  'TechnicianService',
  'UserOwnership',
  'User',
  'Vendor',
  'Walkthrough',
  'WorkOrderCharge',
  'WorkOrder',
];

export const VERSION_MODEL_NAMES = {
  Client: 'Client',
  CustomField: 'Custom Field',
  Document: 'Document',
  Import: 'Import',
  InsuranceCertificate: 'Insurance Certificate',
  IntegrationReference: 'Integration Reference',
  InvoiceItem: 'Invoice Item',
  Invoice: 'Invoice',
  IosCode: 'iOS Code',
  Location: 'Location',
  ManagerReview: 'Manager Review',
  Organization: 'Organization',
  Quote: 'Quote',
  RecurringCharge: 'Recurring Charge',
  RecurringPayment: 'Recurring Payment',
  RecurringWorkOrder: 'Recurring Work Order',
  Section: 'Section',
  Service: 'Service',
  Supply: 'Supply',
  TechnicianPaymentItem: 'Technician Payment Item',
  TechnicianPayment: 'Technician Payment',
  TechnicianService: 'Technician Service',
  UserOwnership: 'User Ownership',
  User: 'User',
  Vendor: 'Vendor',
  Walkthrough: 'Walkthrough',
  WorkOrderCharge: 'Work Order Charge',
  WorkOrder: 'Work Order',
};

export const VERSION_EVENT_OPTIONS = [
  { label: 'Create', value: 'create' },
  { label: 'Update', value: 'update' },
];
