<template>
  <sfs-form-modal
    :id="modalId"
    title="Update Settings"
    size="lg"
  >
    <b-form
      @submit.stop.prevent="handleSubmit"
      autocomplete="off"
    >
      <b-row>
        <b-col>
          <b-alert
            :show="hasBaseServerErrors()"
            variant="danger"
            dismissible
          >
            <p
              class="mb-0"
              v-for="(error, index) in baseServerErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </b-alert>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6">
          <b-form-group
            :label="fieldLabels.locale"
            label-for="user-locale"
          >
            <locale-select
              id="user-locale"
              v-model="$v.form.locale.$model"
              :select-class="{ 'is-invalid': $v.form.locale.$dirty && $v.form.locale.$error }"
              :state="$v.form.locale.$dirty ? !$v.form.locale.$error : null"
            />
            <b-form-invalid-feedback
              v-if="$v.form.locale.$dirty"
              id="user-locale-feedback"
            >
              <span v-if="!$v.form.locale.required">Please enter a locale.</span>
              <span v-if="!$v.form.locale.serverFailed">{{ serverErrors.locale }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col lg="6">
          <b-form-group
            :label="fieldLabels.time_zone"
            label-for="user-time_zone"
          >
            <time-zone-select
              id="user-time_zone"
              v-model="$v.form.time_zone.$model"
              :select-class="{ 'is-invalid': $v.form.time_zone.$dirty && $v.form.time_zone.$error }"
              :state="$v.form.time_zone.$dirty ? !$v.form.time_zone.$error : null"
            />
            <b-form-invalid-feedback
              v-if="$v.form.time_zone.$dirty"
              id="user-time_zone-feedback"
            >
              <span v-if="!$v.form.time_zone.required">Please enter a time zone.</span>
              <span v-if="!$v.form.time_zone.serverFailed">{{ serverErrors.time_zone }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col lg="6">
          <b-form-group
            :label="fieldLabels.quick_books_vendor_id"
            label-for="user-quick_books_vendor_id"
          >
            <b-form-input
              id="user-quick_books_vendor_id"
              v-model="$v.form.quick_books_vendor_id.$model"
              :state="$v.form.quick_books_vendor_id.$dirty ? !$v.form.quick_books_vendor_id.$error : null"
              type="text"
            />
            <b-form-invalid-feedback
              v-if="$v.form.quick_books_vendor_id.$dirty"
              id="user-quick_books_vendor_id-feedback"
            >
              <span v-if="!$v.form.quick_books_vendor_id.required">Please enter a Netsuite vendor name.</span>
              <!-- prettier-ignore -->
              <span v-if="!$v.form.quick_books_vendor_id.maxLength">
                The Netsuite vendor name must be less than {{ $v.form.quick_books_vendor_id.$params.maxLength.max + 1 }} characters.
              </span>
              <span v-if="!$v.form.quick_books_vendor_id.serverFailed">
                {{ serverErrors.quick_books_vendor_id }}
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col lg="6">
          <b-form-group
            :label="fieldLabels.netsuite_vendor_id"
            label-for="user-netsuite_vendor_id"
          >
            <b-form-input
              id="user-netsuite_vendor_id"
              v-model="$v.form.netsuite_vendor_id.$model"
              :state="$v.form.netsuite_vendor_id.$dirty ? !$v.form.netsuite_vendor_id.$error : null"
              type="text"
            />
            <b-form-invalid-feedback
              v-if="$v.form.netsuite_vendor_id.$dirty"
              id="user-netsuite_vendor_id-feedback"
            >
              <span v-if="!$v.form.netsuite_vendor_id.required">Please enter a NetSuite vendor ID.</span>
              <!-- prettier-ignore -->
              <span v-if="!$v.form.netsuite_vendor_id.maxLength">
                The NetSuite vendor ID must be less than {{ $v.form.netsuite_vendor_id.$params.maxLength.max + 1 }} characters.
              </span>
              <span v-if="!$v.form.netsuite_vendor_id.serverFailed">
                {{ serverErrors.netsuite_vendor_id }}
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          v-if="isTechnician"
          lg="6"
        >
          <b-form-group label-for="user-qr_code_access">
            <b-form-checkbox
              switch
              id="user-qr_code_access"
              v-model="$v.form.qr_code_access.$model"
            >
              QR Code Access
            </b-form-checkbox>
            <small class="form-text text-secondary">Determines if the User can completed Work Orders using QR codes</small>
          </b-form-group>
        </b-col>

        <b-col
          v-if="isTechnician"
          lg="6"
        >
          <b-form-group label-for="user-technician_portal_access">
            <b-form-checkbox
              switch
              id="user-technician_portal_access"
              v-model="$v.form.technician_portal_access.$model"
            >
              Technician Portal
            </b-form-checkbox>
            <small class="form-text text-secondary">Determines if the User can login to the Technician Portal</small>
          </b-form-group>
        </b-col>

        <b-col
          v-if="isTechnician"
          lg="6"
        >
          <b-form-group label-for="user-payable_from_work_orders">
            <b-form-checkbox
              switch
              id="user-payable_from_work_orders"
              v-model="$v.form.payable_from_work_orders.$model"
            >
              Payable from Work Orders
            </b-form-checkbox>
            <small class="form-text text-secondary">Determines if the User can be paid through a Work Order Charge</small>
          </b-form-group>
        </b-col>

        <b-col
          v-if="isTechnician"
          lg="6"
        >
          <b-form-group label-for="user-pays_billing_fees">
            <b-form-checkbox
              switch
              id="user-pays_billing_fees"
              v-model="$v.form.pays_billing_fees.$model"
            >
              Tech Payment Fee
            </b-form-checkbox>
            <small class="form-text text-secondary">Determines if the User pays a fee for Tech Payment processing</small>
          </b-form-group>
        </b-col>

        <b-col
          v-if="isTechnician"
          lg="6"
        >
          <b-form-group label-for="user-parent_work_order_access">
            <b-form-checkbox
              switch
              id="user-parent_work_order_access"
              v-model="$v.form.parent_work_order_access.$model"
            >
              Parent Work Order Access
            </b-form-checkbox>
            <small class="form-text text-secondary">Determines if the User can access their parent technician's Work Orders</small>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-4">

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            id="user-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            id="user-submit-button"
            block
            type="submit"
            variant="primary"
            :disabled="processing"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';
import TimeZoneSelect from '@/components/shared/users/TimeZoneSelect.vue';
import LocaleSelect from '@/components/shared/users/LocaleSelect.vue';

import { USER_FORM_LABELS, USER_TECHNICIAN_CATEGORY, USER_SUB_TECHNICIAN_CATEGORY } from '@/constants/users';

export default {
  name: 'UserSettingsModal',
  components: {
    TimeZoneSelect,
    LocaleSelect,
  },
  mixins: [formMixin, formModalMixin],
  props: {
    value: Object,
  },
  data() {
    return {
      modalId: 'user-settings-modal',
      form: {
        locale: this.value.locale,
        time_zone: this.value.time_zone,
        pays_billing_fees: this.value.pays_billing_fees,
        quick_books_vendor_id: this.value.quick_books_vendor_id,
        netsuite_vendor_id: this.value.netsuite_vendor_id,
        qr_code_access: this.value.qr_code_access,
        parent_work_order_access: this.value.parent_work_order_access,
        technician_portal_access: this.value.technician_portal_access,
        payable_from_work_orders: this.value.payable_from_work_orders,
      },
      fieldLabels: USER_FORM_LABELS,
    };
  },
  validations: {
    form: {
      locale: {
        required,
        serverFailed() {
          return !this.hasServerErrors('locale');
        },
      },
      time_zone: {
        required,
        serverFailed() {
          return !this.hasServerErrors('time_zone');
        },
      },
      pays_billing_fees: {},
      qr_code_access: {},
      parent_work_order_access: {},
      technician_portal_access: {},
      payable_from_work_orders: {},
      quick_books_vendor_id: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('quick_books_vendor_id');
        },
      },
      netsuite_vendor_id: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('netsuite_vendor_id');
        },
      },
    },
  },
  methods: {
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }
      this.$usersAPI
        .update(this.value.id, this.form)
        .then((user) => {
          this.resetServerErrors();
          this.$v.$reset();
          this.closeModal();
          this.$emit('input', user);
        })
        .catch((error) => {
          this.processServerErrors(error.response.data.errors);
        });
    },
  },
  computed: {
    isTechnician() {
      return [USER_TECHNICIAN_CATEGORY, USER_SUB_TECHNICIAN_CATEGORY].includes(this.value.category);
    },
  },
};
</script>
