<template>
  <sfs-base-table
    :key="tableKey"
    :data-provider="$invoicesAPI.getItems"
    :data-id="invoiceId"
    provider-key="invoiceItems"
    :columns="columns"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
    @data-changed="updateTaxes"
    :append-to-query="false"
  >
    <template #cell(invoice_id)="data">
      <b-link :to="{ name: 'invoice', params: { id: data.item.invoice_id } }">
        {{ data.item.invoice_id }}
      </b-link>
    </template>
    <template #cell(reference_id)="data">
      <div v-if="data.item.reference_type == 'WorkOrderCharge'">
        <b-link :to="{ name: 'work-order', params: { id: data.item.reference.work_order_id } }">
          {{ data.item.reference.work_order.number }}
        </b-link>
        <div class="font-size-sm text-secondary">
          Work Order
        </div>
      </div>

      <div v-if="data.item.reference_type == 'RecurringCharge'">
        <b-link :to="{ name: 'location', params: { id: data.item.reference.location_id } }">
          {{ data.item.reference.description }}
        </b-link>
        <div class="font-size-sm text-secondary">
          Recurring Charge
        </div>
      </div>

      <div v-if="data.item.reference_type == 'SupplyOrderItem'">
        <b-link :to="{ name: 'supply-order', params: { id: data.item.reference.supply_order_id } }">
          {{ data.item.reference.supply_order.number }}
        </b-link>
        <div class="font-size-sm text-secondary">
          Supply Order
        </div>
      </div>
    </template>
    <template #cell(location_id)="data">
      <div v-if="data.item.location">
        <b-link
          :to="{ name: 'location', params: { id: data.item.location.id } }"
          v-b-tooltip.hover.window
          :title="`${data.item.location.street}, ${data.item.location.city}, ${data.item.location.state}, ${data.item.location.zip_code}`"
        >
          {{ data.item.location.store_number }}
        </b-link>
        <div class="text-nowrap font-size-sm text-secondary">
          {{ { city: data.item.location.city, state: data.item.location.state } | locationFormat }}
        </div>
      </div>
    </template>
    <template #cell(description)="data">
      <div class="text-prewrap">
        {{ data.item.description }}
      </div>
      <div
        v-if="data.item.gl_code"
        class="text-nowrap font-size-sm text-secondary"
      >
        GL Code: {{ data.item.gl_code.name }}
      </div>
    </template>
    <template #cell(details)="data">
      <span v-if="data.item.reference_type == 'WorkOrderCharge'">
        {{ data.item.reference.work_order | dateRangeFormat("date_bucket_start", "date_bucket_end") }}
        <br>
        <span class="font-size-sm text-secondary">Date Bucket</span>
      </span>
      <span v-if="data.item.reference_type == 'RecurringCharge'">
        Billed {{ data.item.reference.frequency_parts | frequencyFormat }}
      </span>
      <div v-if="data.item.reference_type == 'SupplyOrderItem'">
        <div>{{ data.item.reference.supply.item_number }}</div>
        <div class="font-size-sm text-secondary">
          {{ data.item.reference.quantity }} x
          <money-format
            class="d-inline"
            :value="data.item.reference.price.amount"
            :currency-code="data.item.reference.price.currency"
            subunits-value
          />
        </div>
      </div>
    </template>
    <template #cell(amount)="data">
      <money-format
        :value="data.item.amount.amount"
        :currency-code="data.item.amount.currency"
        subunits-value
      />
      <div class="text-nowrap font-size-sm text-secondary">
        {{ data.item.category | humanizedFormat | capitalizeFormat }}
      </div>
    </template>

    <template #cell(tax_amount)="data">
      <div
        class="text-nowrap tax-rate-info"
      >
        <div v-if="taxAmounts[data.item.id] > 0">
          <money-format
            :id="`tax-rate-info-${data.item.id}`"
            class="d-inline"
            :value="taxAmounts[data.item.id]"
            :currency-code="data.item.amount.currency"
            subunits-value
          />
          <tax-rate-popover
            :target="`tax-rate-info-${data.item.id}`"
            :tax-rates="taxRates[data.item.id]"
            tax-applied
          />
        </div>
        <div v-else-if="taxAmounts[data.item.id] === 0" />
        <div v-else>
          <b-skeleton />
        </div>
      </div>
    </template>

    <template #cell(actions)="data">
      <div class="text-nowrap">
        <b-button
          v-if="$can('update', data.item)"
          v-b-modal="`invoice-item-${data.item.id}-edit-modal`"
          size="sm"
          variant="link mr-1"
        >
          <i class="fa fa-fw fa-pencil-alt" />
        </b-button>
        <b-dropdown
          v-if="$can('destroy', data.item) || $can('read', 'Version')"
          variant="link"
          size="sm"
          boundary="window"
          no-caret
        >
          <template #button-content>
            <i class="fas fa-ellipsis-h" />
          </template>

          <b-dropdown-item
            v-if="$can('destroy', data.item)"
            size="sm"
            @click="destroyInvoiceItemDialog(data.item.id)"
          >
            <i class="fa fa-fw fa-times mr-1" />
            Delete
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('destroy', data.item)"
            size="sm"
            @click="destroyInvoiceItemTaxesDialog(data.item.id)"
          >
            <i class="fa fa-fw fa-sack-dollar mr-1" />Remove Taxes
          </b-dropdown-item>

          <b-dropdown-divider
            v-if="$can('update', data.item) || $can('destroy', data.item)"
          />

          <b-dropdown-item
            v-if="$can('read', 'Version')"
            :to="{ name: 'versions', query: { model_id: data.item.id, model: 'InvoiceItem' } }"
          >
            <i class="fa fa-glasses mr-1" />
            Audit Log
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <edit-modal
        v-if="$can('update', data.item)"
        :key="`invoice-items-edit-modal-${data.item.id}`"
        :value="data.item"
        @on-save="refreshTable"
      />
    </template>
  </sfs-base-table>
</template>

<script>
import tablePropsMixin from '@/mixins/tablePropsMixin';
import { INVOICE_ITEM_TABLE_DEFAULT_COLUMNS } from '@/constants/invoiceItems';
import EditModal from '@/components/admin/invoiceItems/EditModal.vue';
import TaxRatePopover from '@/components/admin/taxRates/TaxRatePopover.vue';

export default {
  name: 'InvoiceItemsTable',
  mixins: [tablePropsMixin],
  components: {
    EditModal,
    TaxRatePopover,
  },
  props: {
    invoiceId: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      default: () => INVOICE_ITEM_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    return {
      taxAmounts: {},
      taxRates: {},
    };
  },
  methods: {
    updateTaxes(records) {
      records.forEach((item) => this.fetchTaxes(item));
    },
    destroyInvoiceItemDialog(id) {
      this.$swal({
        text: 'Once it\'s deleted, its gone forever!',
        preConfirm: () => this.$invoicesAPI.destroyItem(id),
      }).then((result) => {
        if (result.value) {
          this.refreshTable();
        }
        result.timer;
      });
    },
    destroyInvoiceItemTaxesDialog(id) {
      this.$swal({
        title: 'Are you sure you want to remove the taxes?',
        text: 'Once they are removed, they are gone forever!',
        preConfirm: () => this.$invoicesAPI.destroyItemTaxes(id),
      }).then((result) => {
        if (result.value) {
          this.refreshTable();
        }
        result.timer;
      });
    },
    fetchTaxes(item) {
      const parentId = item.id;
      this.taxRates[parentId] = [];
      this.$invoicesAPI.getItems(this.invoiceId, { ...this.params, parent_id: parentId, include_tax_items: true, paginate: false })
        .then((response) => {
          const { invoiceItems } = response;
          const total = invoiceItems.length;
          if (total == 0) {
            this.taxAmounts[parentId] = 0;
          } else {
            invoiceItems.forEach((item) => {
              this.taxAmounts[parentId] = item.amount.amount || 0 + item.amount.amount;
              this.taxRates[parentId].push(item.tax_rate);
            });
          }
          this.taxAmounts = Object.assign({}, this.taxAmounts);
          this.taxRates = Object.assign({}, this.taxRates);
        });
    },
  },
};
</script>
