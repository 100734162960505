<template>
  <b-form @submit.stop.prevent="handleSubmit">
    <b-row>
      <b-col>
        <b-alert
          :show="hasBaseServerErrors()"
          variant="danger"
          dismissible
        >
          <p
            class="mb-0"
            v-for="(error, index) in baseServerErrors"
            :key="index"
          >
            {{ error }}
          </p>
        </b-alert>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="5">
        <b-form-group
          :label="fieldLabels.name"
          label-for="client-name"
        >
          <b-form-input
            id="client-name"
            v-model="$v.form.name.$model"
            :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.name.$dirty"
            id="client-name-feedback"
          >
            <span v-if="!$v.form.name.required">Please enter a name.</span>
            <span v-if="!$v.form.name.maxLength">
              The name must be less than {{ $v.form.name.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.name.serverFailed">{{ serverErrors.name }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="3">
        <b-form-group
          :label="fieldLabels.code"
          label-for="client-code"
        >
          <b-form-input
            id="client-code"
            v-model="$v.form.code.$model"
            :state="$v.form.code.$dirty ? !$v.form.code.$error : null"
            type="text"
            placeholder="ABCD"
          />
          <b-form-invalid-feedback
            v-if="$v.form.code.$dirty"
            id="client-code-feedback"
          >
            <span v-if="!$v.form.code.required">Please enter a code.</span>
            <span v-if="!$v.form.code.maxLength">
              The code must be less than {{ $v.form.code.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.code.serverFailed">{{ serverErrors.code }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="4">
        <b-form-group
          :label="fieldLabels.customer_identifier"
          label-for="client-customer_identifier"
        >
          <b-form-input
            id="client-customer_identifier"
            v-model="$v.form.customer_identifier.$model"
            :state="$v.form.customer_identifier.$dirty ? !$v.form.customer_identifier.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.customer_identifier.$dirty"
            id="client-customer_identifier-feedback"
          >
            <!-- prettier-ignore -->
            <span v-if="!$v.form.customer_identifier.maxLength">
              The customer identifier must be less than {{ $v.form.customer_identifier.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.customer_identifier.serverFailed">{{ serverErrors.customer_identifier }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.organization_id"
          label-for="client-organization_id"
        >
          <organization-select
            id="client-organization_id"
            :select-class="{ 'is-invalid': $v.form.organization_id.$dirty && $v.form.organization_id.$error }"
            :value="form.organization"
            @input="onOrganizationChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.organization_id.$dirty"
            id="client-organization_id-feedback"
          >
            <span v-if="!$v.form.organization_id.required">Please enter an organization.</span>
            <span v-if="!$v.form.organization_id.serverFailed">
              {{ serverErrors.organization_id }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.manager_ids"
          label-for="client-manager_ids"
        >
          <employee-select
            id="client-manager_ids"
            :select-class="{ 'is-invalid': $v.form.manager_ids.$dirty && $v.form.manager_ids.$error }"
            :value="form.managers"
            @input="onManagersChange"
            placeholder="Managers"
            multiple
          />
          <b-form-invalid-feedback
            v-if="$v.form.manager_ids.$dirty"
            id="client-manager_ids-feedback"
          >
            <span v-if="!$v.form.manager_ids.serverFailed">
              {{ serverErrors.manager_ids }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        v-if="newForm"
        cols="6"
      >
        <b-form-group
          label=""
          label-for="client-create_location"
        >
          <b-form-checkbox
            id="client-create_location"
            v-model="$v.form.create_location.$model"
          >
            Create Location from Client details
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <h3>Billing Details</h3>
    <b-row>
      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.contact_name"
          label-for="client-contact_name"
        >
          <b-form-input
            id="client-contact_name"
            v-model="$v.form.contact_name.$model"
            autocomplete="new-password"
            :state="$v.form.contact_name.$dirty ? !$v.form.contact_name.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.contact_name.$dirty"
            id="client-contact_name-feedback"
          >
            <span v-if="!$v.form.contact_name.maxLength">
              The billing contact name must be less than {{ $v.form.contact_name.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.contact_name.serverFailed">{{ serverErrors.contact_name }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.attention_to"
          label-for="client-attention_to"
        >
          <b-form-input
            id="client-attention_to"
            v-model="$v.form.attention_to.$model"
            :state="$v.form.attention_to.$dirty ? !$v.form.attention_to.$error : null"
            type="text"
            placeholder="ATTN: Accounts Payable"
          />
          <b-form-invalid-feedback
            v-if="$v.form.attention_to.$dirty"
            id="client-attention_to-feedback"
          >
            <span v-if="!$v.form.attention_to.maxLength">
              The attention to must be less than {{ $v.form.attention_to.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.attention_to.serverFailed">{{ serverErrors.attention_to }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="6">
        <b-form-group
          :label="`${fieldLabels.address}${form.create_location ? ' *' : ''}`"
          label-for="client-address"
        >
          <b-form-input
            id="client-address"
            v-model="$v.form.address.$model"
            :state="$v.form.address.$dirty ? !$v.form.address.$error : null"
            type="text"
            placeholder="123 Fake Street"
          />
          <b-form-invalid-feedback
            v-if="$v.form.address.$dirty"
            id="client-address-feedback"
          >
            <span v-if="!$v.form.address.required">Please enter an address.</span>
            <span v-if="!$v.form.address.maxLength">
              The address must be less than {{ $v.form.address.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.address.serverFailed">{{ serverErrors.address }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          :label="`${fieldLabels.city}${form.create_location ? ' *' : ''}`"
          label-for="client-city"
        >
          <b-form-input
            id="client-city"
            v-model="$v.form.city.$model"
            :state="$v.form.city.$dirty ? !$v.form.city.$error : null"
            type="text"
            placeholder="New York"
          />
          <b-form-invalid-feedback
            v-if="$v.form.city.$dirty"
            id="client-city-feedback"
          >
            <span v-if="!$v.form.city.required">Please enter an city.</span>
            <span v-if="!$v.form.city.maxLength">
              The city must be less than {{ $v.form.city.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.city.serverFailed">{{ serverErrors.city }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="6">
        <b-form-group
          :label="`${fieldLabels.state}${form.create_location ? ' *' : ''}`"
          label-for="client-state"
        >
          <b-form-input
            id="client-state"
            v-model="$v.form.state.$model"
            :state="$v.form.state.$dirty ? !$v.form.state.$error : null"
            type="text"
            placeholder="NY"
          />
          <b-form-invalid-feedback
            v-if="$v.form.state.$dirty"
            id="client-state-feedback"
          >
            <span v-if="!$v.form.state.required">Please enter a state.</span>
            <span v-if="!$v.form.state.maxLength">
              The state must be less than {{ $v.form.state.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.state.serverFailed">{{ serverErrors.state }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          :label="`${fieldLabels.zip_code}${form.create_location ? ' *' : ''}`"
          label-for="client-zip_code"
        >
          <b-form-input
            id="client-zip_code"
            v-model="$v.form.zip_code.$model"
            :state="$v.form.zip_code.$dirty ? !$v.form.zip_code.$error : null"
            type="text"
            placeholder="55555"
          />
          <b-form-invalid-feedback
            v-if="$v.form.zip_code.$dirty"
            id="client-zip_code-feedback"
          >
            <span v-if="!$v.form.zip_code.required">Please enter a zip code.</span>
            <span v-if="!$v.form.zip_code.maxLength">
              The zip code must be less than {{ $v.form.zip_code.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.zip_code.serverFailed">{{ serverErrors.zip_code }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <b-form-group
              :label="fieldLabels.invoice_delivery_method"
              label-for="client-invoice_delivery_method"
            >
              <invoice-delivery-method-select
                id="client-invoice_delivery_method"
                class="mb-1"
                :select-class="{ 'is-invalid': $v.form.invoice_delivery_method.$dirty && $v.form.invoice_delivery_method.$error }"
                v-model="$v.form.invoice_delivery_method.$model"
              />
              <b-form-textarea
                id="client-invoice_delivery_notes"
                v-model="$v.form.invoice_delivery_notes.$model"
                :state="$v.form.invoice_delivery_notes.$dirty ? !$v.form.invoice_delivery_notes.$error : null"
                placeholder="Invoice Delivery Notes"
                rows="3"
              />
              <b-form-invalid-feedback
                v-if="$v.form.invoice_delivery_notes.$dirty"
                id="client-invoice_delivery_notes-feedback"
              >
                <span v-if="!$v.form.invoice_delivery_notes.serverFailed">{{ serverErrors.invoice_delivery_notes }}</span>
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                v-if="$v.form.invoice_delivery_method.$dirty"
                id="client-invoice_delivery_method-feedback"
              >
                <span v-if="!$v.form.invoice_delivery_method.required">Please enter a delivery method.</span>
                <span v-if="!$v.form.invoice_delivery_method.serverFailed">{{ serverErrors.invoice_delivery_method }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <b-col>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="client-disable_auto_invoice_emails">
              <b-form-checkbox
                switch
                id="client-disable_auto_invoice_emails"
                v-model="$v.form.disable_auto_invoice_emails.$model"
              >
                {{ fieldLabels.disable_auto_invoice_emails }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label-for="client-send_physical_invoice">
              <b-form-checkbox
                switch
                id="client-send_physical_invoice"
                v-model="$v.form.send_physical_invoice.$model"
              >
                {{ fieldLabels.send_physical_invoice }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <hr class="mt-0 mb-4">

    <b-row>
      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.payment_term_days"
          label-for="client-payment_term_days"
        >
          <b-input-group
            prepend="NET"
            append="Days"
            :class="{ 'is-invalid': $v.form.payment_term_days.$dirty && $v.form.payment_term_days.$error }"
          >
            <b-form-input
              id="client-payment_term_days"
              v-model="$v.form.payment_term_days.$model"
              :state="$v.form.payment_term_days.$dirty ? !$v.form.payment_term_days.$error : null"
              type="number"
            />
          </b-input-group>
          <b-form-invalid-feedback
            v-if="$v.form.payment_term_days.$dirty"
            id="client-payment_term_days-feedback"
          >
            <span v-if="!$v.form.payment_term_days.required">Please enter the number of payment term days.</span>
            <span v-if="!$v.form.payment_term_days.integer">Please enter a number.</span>
            <span v-if="!$v.form.payment_term_days.minValue">Please enter a positive number.</span>
            <span v-if="!$v.form.payment_term_days.serverFailed">{{ serverErrors.payment_term_days }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.invoice_format_id"
          label-for="client-invoice_format_id"
        >
          <invoice-formats-select
            id="client-invoice_format_id"
            :select-class="{ 'is-invalid': $v.form.invoice_format_id.$dirty && $v.form.invoice_format_id.$error }"
            :value="form.invoice_format"
            @input="onInvoiceFormatChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.invoice_format_id.$dirty"
            id="client-invoice_format_id-feedback"
          >
            <span v-if="!$v.form.invoice_format_id.required">Please enter an invoice format.</span>
            <span v-if="!$v.form.invoice_format_id.serverFailed">{{ serverErrors.invoice_format_id }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        v-if="!newForm"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.technician_payment_gl_code_id"
          label-for="client-technician_payment_gl_code_id"
        >
          <gl-code-select
            id="client-technician_payment_gl_code_id"
            :select-class="{ 'is-invalid': $v.form.technician_payment_gl_code_id.$dirty && $v.form.technician_payment_gl_code_id.$error }"
            client-unassigned
            :value="form.technician_payment_gl_code"
            @input="onTechnicianPaymentGlCodeChange"
            :filter-params="technicianPaymentGlCodeFilters"
          />
          <b-form-invalid-feedback
            v-if="$v.form.technician_payment_gl_code_id.$dirty"
            id="client-technician_payment_gl_code_id-feedback"
          >
            <span v-if="!$v.form.technician_payment_gl_code_id.serverFailed">
              {{ serverErrors.technician_payment_gl_code }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        v-if="!newForm"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.invoice_gl_code_id"
          label-for="client-invoice_gl_code_id"
        >
          <gl-code-select
            id="client-invoice_gl_code_id"
            :select-class="{ 'is-invalid': $v.form.invoice_gl_code_id.$dirty && $v.form.invoice_gl_code_id.$error }"
            client-unassigned
            :value="form.invoice_gl_code"
            @input="onInvoiceGlCodeChange"
            :filter-params="invoiceGlCodeFilters"
          />
          <b-form-invalid-feedback
            v-if="$v.form.invoice_gl_code_id.$dirty"
            id="client-invoice_gl_code_id-feedback"
          >
            <span v-if="!$v.form.invoice_gl_code_id.serverFailed">
              {{ serverErrors.invoice_gl_code }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        v-if="!newForm"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.supply_order_invoice_gl_code_id"
          label-for="client-supply_order_invoice_gl_code_id"
        >
          <gl-code-select
            id="client-supply_order_invoice_gl_code_id"
            :select-class="{ 'is-invalid': $v.form.supply_order_invoice_gl_code_id.$dirty && $v.form.supply_order_invoice_gl_code_id.$error }"
            client-unassigned
            :value="form.supply_order_invoice_gl_code"
            @input="onSupplyOrderInvoiceGlCodeChange"
            :filter-params="invoiceGlCodeFilters"
          />
          <b-form-invalid-feedback
            v-if="$v.form.supply_order_invoice_gl_code_id.$dirty"
            id="client-supply_order_invoice_gl_code_id-feedback"
          >
            <span v-if="!$v.form.supply_order_invoice_gl_code_id.serverFailed">
              {{ serverErrors.supply_order_invoice_gl_code }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        v-if="!newForm"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.purchase_order_number"
          label-for="client-purchase_order_number"
        >
          <b-form-input
            id="client-purchase_order_number"
            v-model="$v.form.purchase_order_number.$model"
            autocomplete="new-password"
            :state="$v.form.purchase_order_number.$dirty ? !$v.form.purchase_order_number.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.purchase_order_number.$dirty"
            id="client-purchase_order_number-feedback"
          >
            <span v-if="!$v.form.purchase_order_number.maxLength">
              The billing PO # must be less than {{ $v.form.purchase_order_number.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.purchase_order_number.serverFailed">{{ serverErrors.purchase_order_number }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-form-group label-for="client-tax_exempt">
          <b-form-checkbox
            switch
            id="client-tax_exempt"
            v-model="$v.form.tax_exempt.$model"
          >
            {{ fieldLabels.tax_exempt }}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          id="client-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          id="client-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, maxLength, minValue, integer, requiredIf } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import GlCodeSelect from '@/components/admin/glCodes/Select.vue';
import InvoiceFormatsSelect from '@/components/admin/invoiceFormats/Select.vue';
import OrganizationSelect from '@/components/admin/organizations/Select.vue';
import EmployeeSelect from '@/components/admin/users/EmployeeSelect.vue';
import { GL_CODE_TECHNICIAN_PAYMENT_CATEGORY, GL_CODE_INVOICE_CATEGORY } from '@/constants/glCodes';
import InvoiceDeliveryMethodSelect from '@/components/admin/clients/InvoiceDeliveryMethodSelect.vue';

export default {
  name: 'ClientsForm',
  components: {
    OrganizationSelect,
    EmployeeSelect,
    GlCodeSelect,
    InvoiceFormatsSelect,
    InvoiceDeliveryMethodSelect,
  },
  mixins: [formMixin],
  props: {
    id: [String, Number],
    newForm: Boolean,
    organization: Object,
    organization_id: [String, Number],
    code: String,
    name: String,
    payment_term_days: Number,
    contact_name: String,
    attention_to: String,
    purchase_order_number: String,
    tax_exempt: Boolean,
    disable_auto_invoice_emails: Boolean,
    send_physical_invoice: Boolean,
    invoice_delivery_method: String,
    invoice_delivery_notes: String,
    address: String,
    city: String,
    state: String,
    zip_code: String,
    customer_identifier: String,
    invoice_format_id: [String, Number],
    invoice_format: Object,
    technician_payment_gl_code_id: [String, Number],
    technician_payment_gl_code: Object,
    invoice_gl_code_id: [String, Number],
    invoice_gl_code: Object,
    supply_order_invoice_gl_code_id: [String, Number],
    supply_order_invoice_gl_code: Object,
    manager_ids: Array,
    managers: Array,
    create_location: Boolean,
  },
  data() {
    return {
      form: {
        organization_id: this.organization_id,
        organization: this.organization,
        code: this.code,
        name: this.name,
        address: this.address,
        city: this.city,
        state: this.state,
        zip_code: this.zip_code,
        payment_term_days: this.payment_term_days,
        customer_identifier: this.customer_identifier,
        invoice_format_id: this.invoice_format_id,
        invoice_format: this.invoice_format,
        technician_payment_gl_code_id: this.technician_payment_gl_code_id,
        technician_payment_gl_code: this.technician_payment_gl_code,
        invoice_gl_code_id: this.invoice_gl_code_id,
        invoice_gl_code: this.invoice_gl_code,
        supply_order_invoice_gl_code_id: this.supply_order_invoice_gl_code_id,
        supply_order_invoice_gl_code: this.supply_order_invoice_gl_code,
        manager_ids: this.manager_ids,
        managers: this.managers,
        create_location: this.create_location,
        contact_name: this.contact_name,
        attention_to: this.attention_to,
        purchase_order_number: this.purchase_order_number,
        tax_exempt: this.tax_exempt,
        disable_auto_invoice_emails: this.disable_auto_invoice_emails,
        send_physical_invoice: this.send_physical_invoice,
        invoice_delivery_method: this.invoice_delivery_method,
        invoice_delivery_notes: this.invoice_delivery_notes
      },
      fieldLabels: {
        organization_id: 'Organization *',
        code: 'Code *',
        name: 'Name *',
        contact_name: 'Contact Name',
        purchase_order_number: 'Billing PO #',
        attention_to: 'Attention To',
        address: 'Address',
        city: 'City',
        state: 'State',
        zip_code: 'Zip Code',
        payment_term_days: 'Payment Terms',
        invoice_format_id: 'Invoice Format *',
        customer_identifier: 'Customer ID',
        technician_payment_gl_code_id: 'Default Technician Payment GL Code',
        invoice_gl_code_id: 'Default Invoice GL Code',
        supply_order_invoice_gl_code_id: 'Supply Order Invoice GL Code',
        manager_ids: 'Managers',
        tax_exempt: `${this.$store.getters.translate('client', 'Client')} is exempt from paying taxes`,
        disable_auto_invoice_emails: 'Disable automatic sending of Invoices',
        send_physical_invoice: `${this.$store.getters.translate('client', 'Client')} requires a physical copy of their Invoices`,
        invoice_delivery_method: 'Invoice Delivery Method',
        invoice_delivery_notes: 'Invoice Delivery Notes',
      },
      invoiceFormatOptions: [],
      technicianPaymentGlCodeFilters: { client_id: this.id, category: GL_CODE_TECHNICIAN_PAYMENT_CATEGORY, active: true },
      invoiceGlCodeFilters: { client_id: this.id, category: GL_CODE_INVOICE_CATEGORY, active: true },
    };
  },
  validations: {
    form: {
      organization_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('organization_id');
        },
      },
      code: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('code');
        },
      },
      create_location: {},
      name: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('name');
        },
      },
      contact_name: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('contact_name');
        }
      },
      purchase_order_number: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('purchase_order_number');
        },
      },
      attention_to: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('attention_to');
        },
      },
      tax_exempt: {},
      disable_auto_invoice_emails: {},
      send_physical_invoice: {},
      invoice_delivery_method: {
        required,
        serverFailed() {
          return !this.hasServerErrors('invoice_delivery_method');
        },
      },
      invoice_delivery_notes: {
        serverFailed() {
          return !this.hasServerErrors('invoice_delivery_notes');
        },
      },
      address: {
        required: requiredIf(function () {
          return this.form.create_location;
        }),
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('address');
        },
      },
      city: {
        required: requiredIf(function () {
          return this.form.create_location;
        }),
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('city');
        },
      },
      state: {
        required: requiredIf(function () {
          return this.form.create_location;
        }),
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('state');
        },
      },
      zip_code: {
        required: requiredIf(function () {
          return this.form.create_location;
        }),
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('zip_code');
        },
      },
      payment_term_days: {
        required,
        integer,
        minValue: minValue(0),
        serverFailed() {
          return !this.hasServerErrors('payment_term_days');
        },
      },
      invoice_format_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('invoice_format_id');
        },
      },
      customer_identifier: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('customer_identifier');
        },
      },
      technician_payment_gl_code_id: {
        serverFailed() {
          return !this.hasServerErrors('technician_payment_gl_code');
        },
      },
      invoice_gl_code_id: {
        serverFailed() {
          return !this.hasServerErrors('invoice_gl_code');
        },
      },
      supply_order_invoice_gl_code_id: {
        serverFailed() {
          return !this.hasServerErrors('supply_order_invoice_gl_code');
        },
      },
      manager_ids: {
        serverFailed() {
          return !this.hasServerErrors('manager_ids');
        },
      },
    },
  },
  methods: {
    onOrganizationChange(organization) {
      this.form.organization_id = organization ? organization.id : '';
      this.form.organization = organization;
    },
    onTechnicianPaymentGlCodeChange(glCode) {
      this.form.technician_payment_gl_code_id = glCode ? glCode.id : '';
      this.form.technician_payment_gl_code = glCode;
    },
    onInvoiceGlCodeChange(glCode) {
      this.form.invoice_gl_code_id = glCode ? glCode.id : '';
      this.form.invoice_gl_code = glCode;
    },
    onSupplyOrderInvoiceGlCodeChange(glCode) {
      this.form.supply_order_invoice_gl_code_id = glCode ? glCode.id : '';
      this.form.supply_order_invoice_gl_code = glCode;
    },
    onInvoiceFormatChange(invoiceFormat) {
      this.form.invoice_format_id = invoiceFormat ? invoiceFormat.id : '';
      this.form.invoice_format = invoiceFormat;
    },
    onManagersChange(managers) {
      this.form.managers = managers;
      this.form.manager_ids = managers.map((manager) => manager.id);
    },
  },
};
</script>
