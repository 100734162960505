<template>
  <sfs-base-table
    :data-provider="$clientsAPI.getClients"
    provider-key="clients"
    :columns="columns"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
  >
    <template #cell(name)="data">
      <b-link :to="{ name: 'client', params: { id: data.item.id } }">
        {{ data.item.name }}
      </b-link>
      <div class="font-size-sm text-secondary">
        {{ data.item.code }}
      </div>
    </template>
    <template #cell(payment_term_days)="data">
      NET {{ data.item.payment_term_days }} Days
    </template>
    <template #cell(invoice_delivery_method)="data">
      <b-badge variant="secondary">
        {{ data.item.invoice_delivery_method | humanizedFormat | capitalizeFormat }}
      </b-badge>
    </template>
    <template #cell(active)="data">
      {{ !data.item.deleted_at ? "Yes" : "No" }}
    </template>
    <template #cell(manager_id)="data">
      <div
        v-for="(manager, index) in data.item.managers"
        :key="index"
      >
        <b-link :to="{ name: 'user', params: { id: manager.id } }">
          {{ manager.name }}
        </b-link>
      </div>
    </template>
    <template #cell(netsuite_reference_id)="data">
      <div v-if="data.item.netsuite_reference?.netsuite_record">
        <span>{{ data.item.netsuite_reference.netsuite_record?.name }}</span>
        <div class="font-size-sm text-secondary">
          ID: {{ data.item.netsuite_reference.netsuite_record?.id }}
        </div>
      </div>
    </template>
  </sfs-base-table>
</template>

<script>
import { CLIENT_TABLE_DEFAULT_COLUMNS } from '@/constants/clients';
import tablePropsMixin from '@/mixins/tablePropsMixin';

export default {
  name: 'ClientsTable',
  mixins: [tablePropsMixin],
  props: {
    columns: {
      type: Array,
      default: () => CLIENT_TABLE_DEFAULT_COLUMNS,
    },
  },
};
</script>
