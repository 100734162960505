import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '@/api/helpers/apiPath';

export default {
  getDelayedJobs(params) {
    return axios.get(adminPath('/delayed_jobs?') + prepareQueryString(params)).then((response) => {
      return {
        delayedJobs: response.data.map((delayedJob) => an('DelayedJob', delayedJob)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  destroy(id) {
    return axios.delete(adminPath(`/delayed_jobs/${id}`));
  },
  getStatusOptions() {
    return axios.get(adminPath('/delayed_jobs/status_options'));
  },
  getQueueOptions() {
    return axios.get(adminPath('/delayed_jobs/queue_options'));
  },
  rerunDelayedJob(id) {
    return axios.get(adminPath(`/delayed_jobs/${id}/rerun`));
  },
};
