import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '@/api/helpers/apiPath';

export default {
  getTechnicianServices(params) {
    return axios.get(adminPath('/technician_services?') + prepareQueryString(params)).then((response) => {
      return {
        technicianServices: response.data.map((technicianService) => an('TechnicianService', technicianService)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  get(id) {
    return axios.get(adminPath(`/technician_services/${id}`)).then((response) => an('TechnicianService', response.data));
  },
  update(id, data) {
    return axios
      .patch(adminPath(`/technician_services/${id}`), {
        ...data,
      })
      .then((response) => an('TechnicianService', response.data));
  },
  create(data) {
    return axios
      .post(adminPath('/technician_services'), {
        ...data,
      })
      .then((response) => an('TechnicianService', response.data));
  },
  destroy(id) {
    return axios.delete(adminPath(`/technician_services/${id}`));
  },
  getSpreadsheet(params) {
    return axios.get(adminPath('/technician_services.csv?') + prepareQueryString(params), { responseType: 'blob' });
  },
};
