<template>
  <div
    id="page-container"
    :class="classContainer"
  >
    <!-- Sidebar -->
    <nav
      v-if="$store.state.layout.sidebar"
      id="sidebar"
      class="no-print"
      aria-label="Main Navigation"
    >
      <slot name="sidebar" />
    </nav>
    <!-- END Sidebar -->

    <!-- Main Container -->
    <div
      id="main-container"
      :class="{ 'sidebar-container': $store.state.layout.sidebar }"
    >
      <slot name="content" />
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view />
      </transition>
    </div>
    <!-- END Main Container -->

    <!-- Footer -->
    <footer
      v-if="$store.state.layout.footer"
      id="page-footer"
      aria-label="Footer"
    >
      <slot name="footer" />
    </footer>
    <!-- END Footer -->
  </div>
</template>

<script>
// Import main layout components

export default {
  name: 'BaseLayout',
  computed: {
    classContainer() {
      return {
        'main-content-boxed': this.$store.state.settings.mainContent === 'boxed',
        'main-content-narrow': this.$store.state.settings.mainContent === 'narrow',
      };
    },
  },
};
</script>
