<template>
  <div>
    <base-page-heading title="Taxes Report" />
    <div class="content">
      <tax-list-filter
        :columns="columns"
        :initial-filters="filters"
        @filters-applied="applyFilters"
      />
      <tax-table
        :filters="filters"
        :columns="columns"
        sort-by="taxable_sales_cents"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
      />
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import listMixin from '@/mixins/listMixin';
import TaxListFilter from '@/components/admin/reports/invoices/TaxListFilter.vue';
import TaxTable from '@/components/admin/reports/invoices/TaxTable.vue';
import { WORK_ORDER_TAX_REPORT_TABLE_NAME } from '@/constants/reports';

export default {
  name: 'TaxesReport',
  components: {
    TaxTable,
    TaxListFilter,
  },
  mixins: [listMixin],
  props: {
    client_id: String,
    state: String,
    start_date: String,
    end_date: String,
    sortBy: {
      type: String,
      default: 'taxable_sales_cents',
    },
  },
  data() {
    return {
      tableName: WORK_ORDER_TAX_REPORT_TABLE_NAME,
      filters: {
        client_id: this.client_id,
        state: this.state,
        start_date: this.start_date || this.$options.filters.dateFormat(DateTime.now().minus({ month: 6 }).startOf('month').toISODate()),
        end_date: this.end_date || this.$options.filters.dateFormat(DateTime.now().toISODate()),
      },
    };
  },
};
</script>
