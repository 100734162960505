import List from '@/pages/admin/quotes/List.vue';
import Show from '@/pages/admin/quotes/Show.vue';
import New from '@/pages/admin/quotes/New.vue';
import handlePaginationRouteProps from '@/utils/handlePaginationRouteProps.js';

export default [
  {
    path: 'quotes',
    name: 'quotes',
    component: List,
    props: handlePaginationRouteProps,
  },
  {
    path: 'quotes/new',
    name: 'new-quote',
    component: New,
    props: handlePaginationRouteProps,
  },
  {
    path: 'quotes/:id(\\d+)',
    name: 'quote',
    component: Show,
  },
];
