import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import fileFormDataSubmission from '@/utils/fileFormDataSubmission';
import { subject as an } from '@casl/ability';
import { adminPath } from '@/api/helpers/apiPath';

export default {
  getWorkOrderDocuments(workOrderId, params) {
    return axios.get(adminPath(`/work_orders/${workOrderId}/documents?`) + prepareQueryString(params)).then((response) => {
      return {
        documents: response.data.map((document) => an('Document', { ...document })),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  createWorkOrderDocument(workOrderId, data) {
    return fileFormDataSubmission(data, adminPath(`/work_orders/${workOrderId}/documents`));
  },
  getSupplyOrderDocuments(supplyOrderId, params) {
    return axios.get(adminPath(`/supply_orders/${supplyOrderId}/documents?`) + prepareQueryString(params)).then((response) => {
      return {
        documents: response.data.map((document) => an('Document', { ...document })),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  createSupplyOrderDocument(supplyOrderId, data) {
    return fileFormDataSubmission(data, adminPath(`/supply_orders/${supplyOrderId}/documents`));
  },
  getQuoteDocuments(quoteId, params) {
    return axios.get(adminPath(`/quotes/${quoteId}/documents?`) + prepareQueryString(params)).then((response) => {
      return {
        documents: response.data.map((document) => an('Document', { ...document })),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  createQuoteDocument(quoteId, data) {
    return fileFormDataSubmission(data, adminPath(`/quotes/${quoteId}/documents`));
  },
  destroy(documentId) {
    return axios.delete(adminPath(`/documents/${documentId}`));
  },
  update(documentId, data) {
    return axios
      .patch(adminPath(`/documents/${documentId}`), {
        ...data,
      });
  },
};
