import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '@/api/helpers/apiPath';

export default {
  get(id) {
    return axios
      .get(adminPath(`/insurance_certificates/verifications/${id}`))
      .then((response) => an('InsuranceCertificateVerification', response.data));
  },
  getVerifications(params) {
    return axios.get(adminPath('/insurance_certificates/verifications?') + prepareQueryString(params)).then((response) => {
      return {
        verifications: response.data.map((insuranceCertificateVerification) =>
          an('InsuranceCertificateCoverage', insuranceCertificateVerification)
        ),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getInsuranceCertificateVerifications(insuranceCertificateCoverageId, params) {
    return axios
      .get(
        adminPath(`/insurance_certificates/coverages/${insuranceCertificateCoverageId}/verifications?`) +
          prepareQueryString(params)
      )
      .then((response) => {
        return {
          verifications: response.data.map((insuranceCertificateVerification) =>
            an('InsuranceCertificateVerification', insuranceCertificateVerification)
          ),
          total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
        };
      });
  },
  create(insuranceCertificateCoverageId, data) {
    return axios
      .post(adminPath(`/insurance_certificates/coverages/${insuranceCertificateCoverageId}/verifications`), {
        ...data,
      })
      .then((response) => an('InsuranceCertificateVerification', response.data));
  },
  email(id, data) {
    return axios
      .post(adminPath(`/insurance_certificates/${id}/current_coverage/verifications/email`), {
        ...data,
      })
      .then((response) => an('InsuranceCertificate', response.data));
  },
  getSpreadsheet(params) {
    return axios.get(adminPath('/insurance_certificates/verifications.csv?') + prepareQueryString(params), {
      responseType: 'blob',
    });
  },
  getInsuranceCertificateVerificationsSpreadsheet(params) {
    return axios.get(
      adminPath(`/insurance_certificates/coverages/${params.insuranceCertificateCoverageId}/verifications.csv?`) +
        prepareQueryString(params),
      {
        responseType: 'blob',
      }
    );
  },
};
