<template>
  <div v-if="user">
    <base-page-heading
      :title="user.name"
      show-back-button
    >
      <template #extra>
        <b-button
          v-if="$can('update', 'User')"
          variant="primary"
          v-b-modal.technician-user-edit-modal
          class="mr-2"
        >
          <i class="fa fa-pencil-alt mr-1" /> Edit
        </b-button>

        <b-button
          v-if="$can('destroy', 'User')"
          class="mr-2"
          variant="outline-secondary"
          @click="toggleActive"
        >
          {{ toggleActiveTooltipText }}
        </b-button>
      </template>
    </base-page-heading>

    <div class="content">
      <b-row>
        <b-col
          md="5"
        >
          <b-card>
            <b-row>
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Status
              </b-col>
              <b-col class="text-right">
                <sfs-active-badge :active="!user.deleted_at" />
              </b-col>
            </b-row>

            <h4 class="mb-3">
              Contact
            </h4>
            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Email
              </b-col>
              <b-col class="text-right">
                <b-link :href="`mailto:${user.email}`">
                  {{ user.email }}
                </b-link>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Phone
              </b-col>
              <b-col class="text-right">
                {{ user.phone }}
              </b-col>
            </b-row>

            <div
              v-if="hasAnyAddressFields"
            >
              <h4 class="mb-3">
                Address
              </h4>
              <b-row
                v-if="user.address"
                class="mb-3"
              >
                <b-col
                  cols="auto"
                  class="mr-auto"
                >
                  Address
                </b-col>
                <b-col class="text-right">
                  {{ user.address }}
                </b-col>
              </b-row>

              <b-row
                v-if="user.city"
                class="mb-3"
              >
                <b-col
                  cols="auto"
                  class="mr-auto"
                >
                  City
                </b-col>
                <b-col class="text-right">
                  {{ user.city }}
                </b-col>
              </b-row>

              <b-row
                v-if="user.state"
                class="mb-3"
              >
                <b-col
                  cols="auto"
                  class="mr-auto"
                >
                  State
                </b-col>
                <b-col class="text-right">
                  {{ user.state }}
                </b-col>
              </b-row>

              <b-row
                v-if="user.zip_code"
                class="mb-3"
              >
                <b-col
                  cols="auto"
                  class="mr-auto"
                >
                  Zip Code
                </b-col>
                <b-col class="text-right">
                  {{ user.zip_code }}
                </b-col>
              </b-row>
            </div>

            <b-row>
              <b-col
                v-if="user.logged_in_at"
                class="text-right"
              >
                Last logged in {{ user.logged_in_at | dateTimeFormat }}
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-right">
                Last updated {{ user.updated_at | dateTimeFormat }}
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-right">
                Created on {{ user.created_at | dateTimeFormat }}
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col
          md="6"
          offset-md="1"
        >
          <b-card>
            <h4>
              Settings
            </h4>

            <b-row>
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Time Zone
              </b-col>
              <b-col class="text-right">
                {{ userTimezoneFormat(user.time_zone) }}
              </b-col>
            </b-row>

            <b-row class="mb-3 mt-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Language Preference
              </b-col>
              <b-col class="text-right">
                {{ user.locale }}
              </b-col>
            </b-row>

            <b-row class="mb-3 mt-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Work Order Access
              </b-col>

              <b-col class="text-right">
                <b-badge :variant="user.parent_work_order_access ? 'success' : 'secondary'">
                  {{ user.parent_work_order_access ? 'Full Access' : 'Only Assigned' }}
                </b-badge>
              </b-col>
            </b-row>

            <h4 class="mb-3">
              Mobile App
            </h4>

            <div v-if="mobileDevices">
              <b-row
                v-for="(data, deviceName) in mobileDevices"
                :key="deviceName"
                class="mb-2"
              >
                <b-col
                  cols="auto"
                  class="mr-auto font-w600"
                >
                  {{ deviceName }}
                </b-col>
                <b-col class="text-right">
                  {{ data['os'] }} {{ data['os_version'] }}
                  <div
                    v-if="data['last_seen_at']"
                    class="font-size-sm"
                  >
                    <em>Last seen {{ data['last_seen_at'] | dateTimeFormat }}</em>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div v-else>
              <b-row>
                <b-col
                  cols="auto"
                  class="mr-auto"
                >
                  No device records found
                </b-col>
              </b-row>
            </div>

            <h4 class="mb-3 mt-3">
              Versions
            </h4>

            <div v-if="mobileVersions">
              <b-row
                v-for="(lastSeen, version) in mobileVersions"
                :key="version"
              >
                <b-col
                  cols="auto"
                  class="mr-auto"
                >
                  {{ version }}
                </b-col>
                <b-col class="text-right">
                  {{ lastSeen | dateTimeFormat }}
                </b-col>
              </b-row>
            </div>
            <div v-else>
              <b-row>
                <b-col
                  cols="auto"
                  class="mr-auto"
                >
                  No mobile usage found
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <!-- Modals -->
    <edit-modal
      v-if="$can('update', 'User')"
      v-model="user"
    />
  </div>
</template>

<script>
import formMixin from '@/mixins/formMixin';
import EditModal from '@/components/technician/users/EditModal.vue';
import { DateTime } from 'luxon';

export default {
  name: 'TechnicianUsersShow',
  mixins: [formMixin],
  components: {
    EditModal,
  },
  data() {
    return {
      user: null,
      timezoneOptions: [],
    };
  },
  computed: {
    hasAnyAddressFields() {
      return (
        (this.user.address || '').length > 0 ||
        (this.user.city || '').length > 0 ||
        (this.user.state || '').length > 0 ||
        (this.user.zip_code || '').length > 0
      );
    },
    toggleActiveTooltipText() {
      return !this.user.deleted_at ? 'Deactivate' : 'Reactivate';
    },
    toggleActiveConfirmText() {
      return !this.user.deleted_at
        ? 'Are you sure you want to cancel the User?'
        : 'Are you sure you want to reactivate the User?';
    },
    toggleActiveButtonText() {
      return !this.user.deleted_at ? 'Yes, Cancel it!' : 'Yes, reactivate it!';
    },
    mobileDevices() {
      return this.user.devices;
    },
    mobileVersions() {
      return this.user.app_versions;
    },
  },
  mounted() {
    this.getUser();
    this.getTimeZoneOptions();
  },
  watch: {
    $route: 'getUser',
  },
  methods: {
    getUser() {
      this.$technicianUsersAPI
        .get(this.$route.params.id)
        .then((user) => (this.user = user))
        .catch(() => {
          this.$router.push('/404');
        });
    },
    getTimeZoneOptions() {
      this.$technicianUsersAPI.getTimeZoneOptions().then((response) => {
        this.timezoneOptions = response.data.time_zone_options.map((option) => {
          return { name: option.name, value: option.identifier };
        });
      });
    },
    userTimezoneFormat(timezone) {
      for (let i = 0; i < this.timezoneOptions.length; i++) {
        if(timezone === this.timezoneOptions[i].value) {
          timezone = this.timezoneOptions[i].name;
        }
      }
      return timezone;
    },
    toggleActive() {
      this.$swal({
        text: this.toggleActiveConfirmText,
        preConfirm: () => this.$technicianUsersAPI.update(this.$route.params.id, { deleted_at: !this.user.deleted_at ? DateTime.now().toISODate() : null }),
      }).then((result) => {
        if (result.value) {
          this.user = result.value;
        }
        result.timer;
      });
    },
  },
};
</script>
