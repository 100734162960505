import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '@/api/helpers/apiPath';

export default {
  getUserRecurringPayments(userId, params) {
    return axios.get(adminPath(`/users/${userId}/recurring_payments?`) + prepareQueryString(params)).then((response) => {
      return {
        recurringPayments: response.data.map((recurringPayment) => an('RecurringPayment', recurringPayment)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  update(id, data) {
    return axios
      .patch(adminPath(`/recurring_payments/${id}`), {
        ...data,
      })
      .then((response) => an('RecurringPayment', response.data));
  },
  create(userId, data) {
    return axios
      .post(adminPath(`/users/${userId}/recurring_payments`), {
        ...data,
      })
      .then((response) => an('RecurringPayment', response.data));
  },
  getSpreadsheet(params) {
    return axios.get(adminPath('/recurring_payments.csv?') + prepareQueryString(params), { responseType: 'blob' });
  },
};
