<template>
  <div>
    <base-page-heading
      :title="`New ${$store.getters.translate('client', 'Client')} Feedback`"
      show-back-button
    />
    <div
      class="content"
      v-if="loaded"
    >
      <b-row class="pt-3">
        <b-col>
          <b-form
            novalidate
            @submit.stop.prevent="handleSubmit"
          >
            <b-row
              v-if="linkedToWorkOrder"
              class="mb-3"
            >
              <b-col>
                <b-alert
                  show
                  variant="primary"
                  class="mb-0"
                >
                  <div class="text-prewrap">
                    You are preparing Feedback for Work Order
                    <b-link :to="{ name: 'work-order', params: { id: form.work_order.id } }">
                      {{ form.work_order.number }}.
                    </b-link>
                    <span v-if="form.work_order.checked_out_at">
                      The service was completed at {{ form.work_order.checked_out_at | dateTimeFormat(timeZone) }}.
                    </span>
                    <span v-else>
                      This service has not been completed.
                    </span>
                  </div>
                </b-alert>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-alert
                  :show="hasBaseServerErrors()"
                  variant="danger"
                  dismissible
                >
                  <p
                    class="mb-0"
                    v-for="(error, index) in baseServerErrors"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </b-alert>

                <b-alert
                  :show="!processing && $v.form.customFieldValues.$dirty && !$v.form.customFieldValues.required"
                  variant="danger"
                  dismissible
                >
                  <span v-if="!$v.form.customFieldValues.required">At least one response is needed to submit feedback.</span>
                </b-alert>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="12"
                lg="6"
              >
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="fieldLabels.client_id"
                      label-for="client-feedback-client_id"
                    >
                      <client-select
                        id="client-feedback-client_id"
                        :select-class="{ 'is-invalid': $v.form.client_id.$dirty && $v.form.client_id.$error, 'mb-1': true }"
                        :value="form.client"
                        @input="onClientChange"
                        :placeholder="`Select a ${$store.getters.translate('client', 'Client')}`"
                        :disabled="linkedToWorkOrder"
                      />
                      <b-form-invalid-feedback
                        v-if="$v.form.client_id.$dirty"
                        id="client-feedback-client_id-feedback"
                      >
                        <span v-if="!$v.form.client_id.required">Please enter a {{ $store.getters.translate('client', 'client') }}.</span>
                        <span v-if="!$v.form.client_id.serverFailed">
                          {{ serverErrors.client }}
                        </span>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group
                      :label="fieldLabels.location_id"
                      label-for="client-feedback-location_id"
                    >
                      <location-select
                        id="client-feedback-location_id"
                        :client-id="form.client_id"
                        :select-class="{ 'is-invalid': $v.form.location_id.$dirty && $v.form.location_id.$error }"
                        :value="form.location"
                        @input="onLocationChange"
                        :disabled="linkedToWorkOrder"
                      />
                      <small
                        v-if="form.location"
                        class="form-text text-muted"
                      >
                        Phone: {{ formattedPhoneContactInfos }}
                      </small>
                      <b-form-invalid-feedback
                        v-if="$v.form.location_id.$dirty"
                        id="client-feedback-location_id-feedback"
                      >
                        <span v-if="!$v.form.location_id.serverFailed">{{ serverErrors.location }}</span>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>

              <b-col
                cols="12"
                lg="6"
              >
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="fieldLabels.date"
                      label-for="client-feedback-date"
                    >
                      <sfs-date-picker
                        id="client-feedback-date"
                        v-model="form.date"
                        :class="{ 'is-invalid': $v.form.date.$dirty && $v.form.date.$error }"
                        :with-class="{ 'is-invalid': $v.form.date.$dirty && $v.form.date.$error }"
                      />
                      <b-form-invalid-feedback
                        v-if="$v.form.date.$dirty"
                        id="client-feedback-date-feedback"
                      >
                        <span v-if="!$v.form.date.required">Please enter a date.</span>
                        <span v-if="!$v.form.date.serverFailed">{{ serverErrors.date }}</span>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <h3 class="mt-4 mb-3">
                  Responses
                </h3>
              </b-col>
            </b-row>

            <b-row
              v-if="noCustomFieldValues"
              class="mb-3"
            >
              <b-col>
                Select a Client to see some feedback questions
              </b-col>
            </b-row>

            <b-row class="mt-3">
              <b-col
                v-for="(v, index) in $v.form.customFieldValues.$each.$iter"
                :key="index"
                :cols="form.customFieldValues.length > 3 ? 6 : 12"
              >
                <form-field
                  :custom-field="v.custom_field.$model"
                  v-model="v.value.$model"
                  :validation-class="{ 'is-invalid': v.value.$dirty && v.value.$error }"
                  :time-zone="timeZone"
                >
                  <template #feedback>
                    <b-form-invalid-feedback
                      v-if="v.value.$dirty"
                      class="custom-field-values-form-value-feedback"
                    >
                      <span v-if="!v.value.required">Please enter a response.</span>
                      <span v-if="!v.value.serverFailed">{{ serverErrors.value }}</span>
                    </b-form-invalid-feedback>
                  </template>
                </form-field>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                offset-lg="8"
                lg="2"
              >
                <b-button
                  variant="link-dark"
                  block
                  id="client-feedback-close-button"
                  @click="() => $router.go(-1)"
                >
                  Cancel
                </b-button>
              </b-col>
              <b-col lg="2">
                <b-button
                  id="client-feedback-submit-button"
                  block
                  type="submit"
                  variant="primary"
                  :disabled="processing"
                >
                  Save
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import formMixin from '@/mixins/formMixin';
import { required, minLength } from 'vuelidate/lib/validators';
import { isNil as _isNil } from 'lodash';
import ClientSelect from '@/components/admin/clients/Select.vue';
import LocationSelect from '@/components/admin/locations/Select.vue';
import FormField from '@/components/admin/customFields/FormField';
import { CLIENT_FEEDBACKS_FORM_LABELS } from '@/constants/clientFeedbacks.js';
import { CLIENT_FEEDBACK_MODEL_TYPE, CUSTOM_FIELD_TYPE_RATING } from '@/constants/customFields.js';
import { CONTACT_INFO_CATEGORY_PHONE } from '@/constants/contactInfos.js';

export default {
  name: 'NewClientFeedback',
  components: {
    ClientSelect,
    LocationSelect,
    FormField,
  },
  mixins: [formMixin],
  props: {
    work_order_id: {
      type: [String, Number]
    },
    location_id: {
      type: [String, Number]
    },
  },
  data() {
    return {
      loaded: false,
      form: {
        date: new Date(),
        client_id: null,
        client: null,
        location_id: null,
        location: null,
        work_order: null,
        work_order_id: null,
        customFieldValues: [],
      },
      phoneContactInfos: [],
      fieldLabels: {
        ...CLIENT_FEEDBACKS_FORM_LABELS,
        client_id: this.$store.getters.translate('client', 'Client'),
      },
    };
  },
  validations: {
    form: {
      date: {
        required,
        serverFailed() {
          return !this.hasServerErrors('date');
        },
      },
      client_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('client');
        },
      },
      location_id: {
        serverFailed() {
          return !this.hasServerErrors('location');
        },
      },
      work_order_id: {
        serverFailed() {
          return !this.hasServerErrors('work_order');
        },
      },
      customFieldValues: {
        required,
        minLength: minLength(1),
        $each: {
          value: {
            required,
            serverFailed() {
              return !this.hasServerErrors('value');
            },
          },
          field_type: {},
          custom_field: {},
          custom_field_id: {},
        },
      },
    },
  },
  mounted() {
    if (this.work_order_id) {
      this.loadWorkOrder();
    } else if (this.location_id) {
      this.loadLocation();
    } else {
      this.loaded = true;
    }
  },
  computed: {
    noCustomFieldValues() {
      return this.form.customFieldValues.length === 0;
    },
    linkedToWorkOrder() {
      return !_isNil(this.form.work_order);
    },
    timeZone() {
      return this.form.location?.time_zone;
    },
    formattedPhoneContactInfos() {
      if (this.phoneContactInfos.length > 0) {
        return this.phoneContactInfos.join(', ');
      } else {
        return 'Not provided';
      }
    }
  },
  watch: {
    'form.location': function (newLocation) {
      if (newLocation) {
        this.$contactInfosAPI.getLocationsContactInfos(newLocation?.id, { category: CONTACT_INFO_CATEGORY_PHONE, active: true }).then(({ contactInfos }) => {
          this.phoneContactInfos = contactInfos.map((contactInfo) => contactInfo.content);
        });
      } else {
        this.phoneContactInfos = [];
      }
    },
    'form.client': function (newClient) {
      if (newClient) {
        this.$customFieldsAPI.getCustomFields({ model_type: CLIENT_FEEDBACK_MODEL_TYPE, client_id: newClient?.id, organization_id: this.form.client?.organization_id }).then(({ customFields }) => {
        this.form.customFieldValues = customFields.map((customField) => ({
          value: customField.field_type == CUSTOM_FIELD_TYPE_RATING ? 0 : null,
          field_type: customField.field_type,
          custom_field: customField,
          custom_field_id: customField.id,
        }));
        this.$emit('data-changed', customFields);
      });
      } else {
        this.form.customFieldValues = [];
      }
    },
  },
  methods: {
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }
      this.processing = true;
      this.$clientFeedbacksAPI.create({
          ...this.form,
          custom_field_values_attributes: this.form.customFieldValues,
      }).then(() => {
        this.resetServerErrors();
        this.$v.$reset();
        this.processing = false;
        this.$router.push({ name: 'client-feedbacks' });
      }).catch((error) => {
          this.processServerErrors(error.response.data.errors);
          this.processing = false;
      });
    },
    loadWorkOrder() {
      this.$workOrdersAPI.get(this.work_order_id).then((workOrder) => {
        this.form.work_order = workOrder;
        this.form.work_order_id = workOrder.id;
        this.form.client_id = workOrder.client_id;
        this.form.location_id = workOrder.location_id;
        this.form.client = workOrder.client;
        this.form.location = workOrder.location;
      })
      .finally(() => {
        this.loaded = true;
      });
    },
    loadLocation() {
      this.$locationsAPI.get(this.location_id).then((location) => {
        this.form.client_id = location.clients[0]?.id;
        this.form.location_id = location.id;
        this.form.client = location.clients[0];
        this.form.location = location;
      })
      .finally(() => {
        this.loaded = true;
      });
    },
    onClientChange(client) {
      this.form.client_id = client ? client.id : null;
      this.form.client = client;
      this.form.location_id = null;
      this.form.location = null;
    },
    onLocationChange(location) {
      this.form.location_id = location ? location.id : null;
      this.form.location = location;
    },
  },
};
</script>
