import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const QUOTE_MODEL_NAME = 'quotes';
export const QUOTE_TABLE_NAME = 'quotes';

// Quote label constants
export const QUOTE_NUMBER_LABEL = 'Number';
export const QUOTE_DESCRIPTION_LABEL = 'Description';
export const QUOTE_CUSTOMER_IDENTIFIER_LABEL = 'Customer Identifier';
export const QUOTE_DATE_LABEL = 'Date';
export const QUOTE_DUE_DATE_LABEL = 'Due Date';
export const QUOTE_CLIENT_ID_LABEL = 'Client';
export const QUOTE_LOCATION_ID_LABEL = 'Location';
export const QUOTE_SERVICE_ID_LABEL = 'Service';

export const QUOTES_FORM_LABELS = {
  number: QUOTE_NUMBER_LABEL,
  description: QUOTE_DESCRIPTION_LABEL,
  customer_identifier: QUOTE_CUSTOMER_IDENTIFIER_LABEL,
  date: QUOTE_DATE_LABEL,
  due_date: QUOTE_DUE_DATE_LABEL,
  client_id: QUOTE_CLIENT_ID_LABEL,
  location_id: QUOTE_LOCATION_ID_LABEL,
  service_id: QUOTE_SERVICE_ID_LABEL,
};

// The columns available to be shown in a Quotes table.
export const QUOTE_TABLE_COLUMNS = {
  number: { label: QUOTE_NUMBER_LABEL, key: 'number', sortable: true },
  description: { label: QUOTE_DESCRIPTION_LABEL, key: 'description' },
  customer_identifier: { label: QUOTE_CUSTOMER_IDENTIFIER_LABEL, key: 'customer_identifier' },
  date: { label: QUOTE_DATE_LABEL, key: 'date', sortable: true },
  due_date: { label: QUOTE_DUE_DATE_LABEL, key: 'due_date' },
  client_id: { label: QUOTE_CLIENT_ID_LABEL, key: 'client_id' },
  location_id: { label: QUOTE_LOCATION_ID_LABEL, key: 'location_id' },
  service_id: { label: QUOTE_SERVICE_ID_LABEL, key: 'service_id' },
  cost: { label: 'Total Cost', key: 'cost' },
  estimated_taxes: { label: 'Estimated Taxes', key: 'estimated_taxes' },
  price: { label: 'Total', key: 'price' },
  status: { label: 'Status', key: 'status' },
  actions: { label: 'Actions', key: 'actions' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const QUOTE_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(QUOTE_TABLE_COLUMNS);

// The default columns to be shown when the quotes table loads.
export const QUOTE_TABLE_DEFAULT_COLUMNS = [
  QUOTE_TABLE_COLUMNS.number,
  QUOTE_TABLE_COLUMNS.status,
  QUOTE_TABLE_COLUMNS.date,
  QUOTE_TABLE_COLUMNS.client_id,
  QUOTE_TABLE_COLUMNS.location_id,
  QUOTE_TABLE_COLUMNS.price,
];

// The default columns to be shown when the quotes table loads.
export const WORK_ORDER_QUOTE_TABLE_DEFAULT_COLUMNS = [
  QUOTE_TABLE_COLUMNS.number,
  QUOTE_TABLE_COLUMNS.status,
  QUOTE_TABLE_COLUMNS.date,
  QUOTE_TABLE_COLUMNS.cost,
  QUOTE_TABLE_COLUMNS.price,
  QUOTE_TABLE_COLUMNS.actions,
];

export const QUOTE_STATUS_DRAFT = 'draft';
export const QUOTE_STATUS_PROPOSED = 'proposed';
export const QUOTE_STATUS_ACCEPTED = 'accepted';
export const QUOTE_STATUS_DECLINED = 'declined';
export const QUOTE_STATUS_CANCELLED = 'cancelled';

export const QUOTE_STATUS_VARIANTS = {
  [QUOTE_STATUS_DRAFT]: 'danger',
  [QUOTE_STATUS_PROPOSED]: 'primary',
  [QUOTE_STATUS_ACCEPTED]: 'success',
  [QUOTE_STATUS_DECLINED]: 'secondary',
  [QUOTE_STATUS_CANCELLED]: 'secondary',
};

