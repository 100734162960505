import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '@/api/helpers/apiPath';

export default {
  getSupplyOrders(params) {
    return axios.get(adminPath('/supply_orders?') + prepareQueryString(params)).then((response) => {
      return {
        supplyOrders: response.data.map((supplyOrder) => an('SupplyOrder', supplyOrder)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  get(id) {
    return axios.get(adminPath(`/supply_orders/${id}`)).then((response) => an('SupplyOrder', response.data));
  },
  getStatusOptions() {
    return axios.get(adminPath('/supply_orders/status_options'));
  },
  getPDF(params) {
    return axios.get(adminPath(`/supply_orders/${params.id}.pdf`), { responseType: 'blob' });
  },
  getPurchaseOrderPDF(params) {
    return axios.get(adminPath(`/supply_orders/${params.id}/purchase_order.pdf`), { responseType: 'blob' });
  },
  update(id, data) {
    return axios
      .patch(adminPath(`/supply_orders/${id}`), {
        ...data,
      })
      .then((response) => an('SupplyOrder', response.data));
  },
  create(data) {
    return axios
      .post(adminPath('/supply_orders'), {
        ...data,
      })
      .then((response) => an('SupplyOrder', response.data));
  },
  confirm(id, data) {
    return axios.patch(adminPath(`/supply_orders/${id}/confirm`), {
        ...data,
      })
      .then((response) => an('SupplyOrder', response.data));
  },
  emailPurchaseOrder(id, data) {
    return axios
      .post(adminPath(`/supply_orders/${id}/purchase_order_email`), {
        ...data,
      })
      .then((response) => an('SupplyOrder', response.data));
  },
  createInvoice(id, data) {
    return axios
      .post(adminPath(`/supply_orders/${id}/invoices`), {
        ...data,
      })
      .then((response) => an('Invoice', response.data));
  },
  getSpreadsheet(params) {
    return axios.get(adminPath('/supply_orders.csv?') + prepareQueryString(params), { responseType: 'blob' });
  },
  getItems(id, params) {
    return axios.get(adminPath(`/supply_orders/${id}/items?`) + prepareQueryString(params)).then((response) => {
      return {
        supplyOrderItems: response.data.map((item) => an('SupplyOrderItem', item)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getItemsSpreadsheet(id, params) {
    return axios.get(adminPath(`/supply_orders/${id}/items.csv?`) + prepareQueryString(params), { responseType: 'blob' });
  },
  createItem(id, data) {
    return axios
      .post(adminPath(`/supply_orders/${id}/items`), {
        ...data,
      })
      .then((response) => an('SupplyOrderItem', response.data));
  },
  updateItem(itemId, data) {
    return axios
      .patch(adminPath(`/supply_order_items/${itemId}`), {
        ...data,
      })
      .then((response) => an('SupplyOrderItem', response.data));
  },
  destroyItem(itemId) {
    return axios.delete(adminPath(`/supply_order_items/${itemId}`));
  },
};
