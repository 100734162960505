<template>
  <sfs-base-list-filter
    title="Filter Report"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="availableColumns"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
    :advanced-filter="false"
    :popover-filter="true"
    :column-picker="false"
  >
    <template #quick-filters>
      <b-form-group
        label="Dates"
        label-for="quick-filter-dates"
        label-sr-only
      >
        <div class="d-flex">
          <sfs-date-range-picker
            id="quick-filter-dates"
            :value="datesRange"
            placeholder="Select Dates"
            @on-change="onDatesChange"
          />
          <b-input-group-append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </b-input-group-append>
        </div>
      </b-form-group>
    </template>

    <template #popover-filters>
      <b-row>
        <b-col>
          <b-form-group
            label="Location"
            label-for="quick-filter-location_id"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <location-select
              id="quick-filter-location_id"
              placeholder="All"
              :value="location"
              @input="onLocationChange"
              :persist-state="true"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            class="mb-4"
            label="District"
            label-for="quick-filter-district"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-input
              id="quick-filter-district"
              v-model="filters.district"
              placeholder="All"
              type="text"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
    <template #right-tray>
      <b-row>
        <b-col>
          <b-dropdown
            boundary="window"
            class="float-right"
            variant="input"
            right
          >
            <template #button-content>
              <span class="font-w500 mr-1">
                <i class="fa fa-location-dot mr-1" />
                {{ formattedSelectedState }}
              </span>
            </template>

            <b-dropdown-item
              v-for="stateOption in stateOptions"
              :key="stateOption.code"
              @click="() => onStateChange(stateOption)"
            >
              {{ stateOption.name }}
            </b-dropdown-item>

            <b-dropdown-divider />

            <b-dropdown-item @click="() => onStateChange(null)">
              All States
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-link @click="() => onGroupByChange('state')">
            By State
          </b-link>
          <span class="pl-1 pr-1">|</span>
          <b-link @click="() => onGroupByChange('district')">
            By District
          </b-link>
        </b-col>
      </b-row>
    </template>
  </sfs-base-list-filter>
</template>
<script>

import LocationSelect from '@/components/admin/locations/Select.vue';
import listFilterMixin from '@/mixins/listFilterMixin';

export default {
  name: 'DicksSportingGoodsPerformanceListFilter',
  mixins: [listFilterMixin],
  components: {
    LocationSelect,
  },
  data() {
    return {
      tableName: 'clientFeedbackDicksSportingGoodsPerformance',
      availableColumns: [],
      stateOptions: [],
      selectedStateData: null,
    };
  },
  mounted() {
    this.loadInitialFilters();
    this.getStateOptions();
  },
  methods: {
    applyPresetFilter(filters, columns) {
      this.filters = filters;
      this.displayedColumns = columns;
      this.applyFilters();
    },
    onDefaultPresetApplied() {
      this.applyPresetFilter({}, []);
    },
    onLocationChange(location) {
      this.filters.location_id = location ? location.id.toString() : '';
      this.location = location;
    },
    onDatesChange(dates) {
      if (dates[0] && dates[1]) {
        this.filters.start_date = dates[0] ? this.$options.filters.dateFormat(dates[0].toISOString()) : '';
        this.filters.end_date = dates[1] ? this.$options.filters.dateFormat(dates[1].toISOString()) : '';
        this.applyFilters();
      }
    },
    onGroupByChange(newGroupBy) {
      this.filters.group_by = newGroupBy;
      this.applyFilters();
    },
    onStateChange(stateData) {
      this.selectedStateData = stateData;
      this.filters.state = stateData ? stateData?.code : null;
      this.$emit('selected-state-data-changed', stateData);
      this.applyFilters();
    },
    getStateOptions() {
      this.$locationsAPI.getClientLocationStates(this.filters.client_id).then((response) => {
        this.stateOptions = response.data;
        const selectedOption = response.data.find((stateData) => stateData.code == this.filters.state);
        this.selectedStateData = selectedOption;
        this.$emit('selected-state-data-changed', selectedOption);
      });
    },
    loadInitialFilters() {
      const loadLocation = new Promise((resolve) => {
        if (this.initialFilters.location_id) {
          this.$locationsAPI.get(this.initialFilters.location_id).then((location) => {
            resolve(location);
          });
        } else {
          resolve(null);
        }
      });

      // The order of the resolved promises is preserved so we are able to use
      // indexes to fetch the data.
      Promise.all([loadLocation]).then((values) => {
        this.location = values[0];
        this.applyFilters();
      });
    },
  },
  computed: {
    formattedSelectedState() {
      return this.selectedStateData?.name || 'All States';
    },
    datesRange() {
      return [this.filters.start_date, this.filters.end_date];
    }
  },
};
</script>
