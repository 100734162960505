import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { adminPath } from '@/api/helpers/apiPath';

export default {
  getWorkOrdersCount(params) {
    return axios.get(adminPath('/analytics/work_orders/count?') + prepareQueryString(params)).then((response) => {
      return {
        series: response.data,
      };
    });
  },
  getWorkOrdersSum(params) {
    return axios.get(adminPath('/analytics/work_orders/sum?') + prepareQueryString(params)).then((response) => {
      return {
        series: response.data,
      };
    });
  },
  getWorkOrdersDashboard(params) {
    return axios.get(adminPath('/analytics/work_orders/dashboard?') + prepareQueryString(params)).then((response) => {
      return {
        data: response.data,
      };
    });
  },
  getTechnicianPaymentsSum(params) {
    return axios.get(adminPath('/analytics/technician_payments/sum?') + prepareQueryString(params)).then((response) => {
      return {
        series: response.data,
      };
    });
  },
  getTechnicianPaymentsCount(params) {
    return axios.get(adminPath('/analytics/technician_payments/count?') + prepareQueryString(params)).then((response) => {
      return {
        series: response.data,
      };
    });
  },
  getWorkOrderProfitabilitySpreadsheet(params) {
    return axios.get(adminPath('/analytics/work_orders/profitability_report.csv?') + prepareQueryString(params), { responseType: 'blob' });
  },
  emailProfitabilityReport(email, params) {
    return axios
      .post(adminPath('/analytics/work_orders/profitability_report/email?') + prepareQueryString(params), {
        email: email,
      });
  },
  getWorkOrdersRevenueReport(params) {
    return axios.get(adminPath('/analytics/revenue_report?') + prepareQueryString(params)).then((response) => {
      return {
        records: response.data,
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getTaxReport(params) {
    return axios.get(adminPath('/analytics/tax/report?') + prepareQueryString(params)).then((response) => {
      return {
        records: response.data,
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getTaxReportSpreadsheet(params) {
    return axios.get(adminPath('/analytics/tax/report.csv?') + prepareQueryString(params), { responseType: 'blob' });
  },
  getTechnicianReport(params) {
    return axios.get(adminPath('/analytics/technician/report?') + prepareQueryString(params)).then((response) => {
      return {
        records: response.data,
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getTechnicianReportSpreadsheet(params) {
    return axios.get(adminPath('/analytics/technician/report.csv?') + prepareQueryString(params), { responseType: 'blob' });
  },
  getTechnicianForecastReport(params) {
    return axios.get(adminPath('/analytics/technician/forecast_report?') + prepareQueryString(params)).then((response) => {
      return {
        records: response.data,
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getTechnicianForecastReportSpreadsheet(params) {
    return axios.get(adminPath('/analytics/technician/forecast_report.csv?') + prepareQueryString(params), { responseType: 'blob' });
  },
  getTechnicianDashboard(id, params) {
    return axios.get(adminPath(`/analytics/technician/${id}/dashboard?`) + prepareQueryString(params)).then((response) => {
      return {
        data: response.data,
      };
    });
  },
  getClientFeedbacksLocationsReport(params) {
    return axios.get(adminPath('/analytics/client_feedbacks/locations_report?') + prepareQueryString(params)).then((response) => {
      return {
        records: response.data,
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getClientFeedbacksLocationsReportSpreadsheet(params) {
    return axios.get(adminPath('/analytics/client_feedbacks/locations_report.csv?') + prepareQueryString(params), { responseType: 'blob' });
  },
  getClientFeedbacksCount(params) {
    return axios.get(adminPath('/analytics/client_feedbacks/count?') + prepareQueryString(params)).then((response) => {
      return {
        series: response.data,
      };
    });
  },
  getClientFeedbacksSum(params) {
    return axios.get(adminPath('/analytics/client_feedbacks/sum?') + prepareQueryString(params)).then((response) => {
      return {
        series: response.data,
      };
    });
  },
  getClientFeedbacksAvg(params) {
    return axios.get(adminPath('/analytics/client_feedbacks/avg?') + prepareQueryString(params)).then((response) => {
      return {
        series: response.data,
      };
    });
  },
  getClientFeedbacksDashboard(params) {
    return axios.get(adminPath('/analytics/client_feedbacks/dashboard?') + prepareQueryString(params)).then((response) => {
      return {
        data: response.data,
      };
    });
  },
};
