import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '@/api/helpers/apiPath';

export default {
  getQuotes(params) {
    return axios.get(adminPath('/quotes?') + prepareQueryString(params)).then((response) => {
      return {
        quotes: response.data.map((quote) => an('Quote', quote)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  get(id) {
    return axios.get(adminPath(`/quotes/${id}`)).then((response) => an('Quote', response.data));
  },
  getEstimatedTaxes(id) {
    return axios.get(adminPath(`/quotes/${id}/estimated_taxes`)).then((response) => an('Money', response.data));
  },
  getStatusOptions() {
    return axios.get(adminPath('/quotes/status_options'));
  },
  getPDF(params) {
    return axios.get(adminPath(`/quotes/${params.id}.pdf`), { responseType: 'blob' });
  },
  update(id, data) {
    return axios
      .patch(adminPath(`/quotes/${id}`), {
        ...data,
      })
      .then((response) => an('Quote', response.data));
  },
  convertToCharges(id) {
    return axios
      .patch(adminPath(`/quotes/${id}/convert_to_charges`))
      .then((response) => an('Quote', response.data));
  },
  email(id, data) {
    return axios
      .post(adminPath(`/quotes/${id}/email`), {
        ...data,
      })
      .then((response) => an('Quote', response.data));
  },
  propose(id, data) {
    return axios
      .patch(adminPath(`/quotes/${id}/propose`), {
        ...data,
      })
      .then((response) => an('Quote', response.data));
  },
  accept(id) {
    return axios
      .patch(adminPath(`/quotes/${id}/accept`))
      .then((response) => an('Quote', response.data));
  },
  decline(id) {
    return axios
      .patch(adminPath(`/quotes/${id}/decline`))
      .then((response) => an('Quote', response.data));
  },
  cancel(id) {
    return axios
      .patch(adminPath(`/quotes/${id}/cancel`))
      .then((response) => an('Quote', response.data));
  },
  uncancel(id) {
    return axios
      .patch(adminPath(`/quotes/${id}/uncancel`))
      .then((response) => an('Quote', response.data));
  },
  create(data) {
    return axios
      .post(adminPath('/quotes'), {
        ...data,
      })
      .then((response) => an('Quote', response.data));
  },
  getSpreadsheet(params) {
    return axios.get(adminPath('/quotes.csv?') + prepareQueryString(params), { responseType: 'blob' });
  },
  getItems(id, params) {
    return axios.get(adminPath(`/quotes/${id}/items?`) + prepareQueryString(params)).then((response) => {
      return {
        quoteItems: response.data.map((item) => an('QuoteItem', item)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  createItem(id, data) {
    return axios
      .post(adminPath(`/quotes/${id}/items`), {
        ...data,
      })
      .then((response) => an('QuoteItem', response.data));
  },
  updateItem(itemId, data) {
    return axios
      .patch(adminPath(`/quote_items/${itemId}`), {
        ...data,
      })
      .then((response) => an('QuoteItem', response.data));
  },
  destroyItem(itemId) {
    return axios.delete(adminPath(`/quote_items/${itemId}`));
  },
};
