import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '@/api/helpers/apiPath';

export default {
  getInvoiceCommunications(id, params) {
    return axios.get(adminPath(`/invoices/${id}/communications?`) + prepareQueryString(params)).then((response) => {
      return {
        communications: response.data.map((communication) => an('Communication', communication)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getInsuranceCertificatesCommunications(id, params) {
    return axios.get(adminPath(`/insurance_certificates/${id}/communications?`) + prepareQueryString(params)).then((response) => {
      return {
        communications: response.data.map((communication) => an('Communication', communication)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getTechnicianPaymentCommunications(id, params) {
    return axios.get(adminPath(`/technician_payments/${id}/communications?`) + prepareQueryString(params)).then((response) => {
      return {
        communications: response.data.map((communication) => an('Communication', communication)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
};
