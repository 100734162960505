<template>
  <base-layout :key="refreshKey">
    <!-- Sidebar Content -->
    <template #sidebar>
      <base-sidebar-menu :items="items" />
      <!-- Modals -->
      <edit-profile-modal
        v-if="$can('profile', 'User')"
        lazy-load
      />
      <edit-password-modal
        v-if="$can('update_password', 'User')"
        lazy-load
      />
      <change-organization-modal
        v-if="$can('change_organization', 'User')"
        :organization="organization"
        @on-save="() => $router.push({ name: 'admin-dashboard' })"
        lazy-load
      />
      <disable-mfa-auth-modal
        :email="user.email"
        @on-save="updateUser"
        lazy-load
      />
      <register-mfa-auth-modal
        :email="user.email"
        :qr-code="qrCode"
        @on-save="updateUser"
        lazy-load
      />
      <user-impersonation-modal
        v-if="$can('impersonate', 'User')"
        lazy-load
      />
    </template>
    <!-- END Sidebar Content -->
    <!-- Footer Content -->
    <template #footer>
      <div class="content py-3">
        <b-row>
          <b-col
            sm="12"
            order-sm="1"
            class="text-center"
          >
            <span class="mr-1">v{{ $appVersion }}</span>
            <a
              class="text-primary"
              href="http://scopesfs.com/"
              target="_blank"
            >
              {{ $store.getters.appName }}
              &copy; {{ $store.getters.appCopyright }}
            </a>
          </b-col>
        </b-row>
      </div>
    </template>
    <!-- END Footer Content -->
  </base-layout>
</template>

<script>
import { compact as _compact } from 'lodash';
import pluralize from 'pluralize';

import BaseLayout from '@/components/shared/layouts/Base';
import BaseSidebarMenu from '@/components/shared/BaseSidebarMenu';
import EditProfileModal from '@/components/shared/users/EditProfileModal.vue';
import EditPasswordModal from '@/components/shared/users/EditPasswordModal.vue';
import ChangeOrganizationModal from '@/components/admin/users/ChangeOrganizationModal.vue';
import UserImpersonationModal from '@/components/admin/users/ImpersonationModal.vue';
import DisableMfaAuthModal from '@/components/auth/mfa/DisableMfaAuthModal.vue';
import RegisterMfaAuthModal from '@/components/auth/mfa/RegisterMfaAuthModal.vue';

import showAppOutOfDatePopup from '@/utils/showAppOutOfDatePopup.js';
import { SET_OUT_OF_DATE_MUTATION, SET_ORGANIZATION_MUTATION } from '@/constants/mutations.js';

import store from '@/store';

export default {
  name: 'AdminLayout',
  components: {
    BaseLayout,
    BaseSidebarMenu,
    EditProfileModal,
    EditPasswordModal,
    ChangeOrganizationModal,
    UserImpersonationModal,
    DisableMfaAuthModal,
    RegisterMfaAuthModal,
  },
  data() {
    return {
      refreshKey: Date.now(),
      qrCode: null,
      items: _compact([
        {
          title: 'Dashboard',
          href: { path: '/admin/dashboard' },
          icon: 'si si-speedometer',
          exactPath: true,
        },
        {
          title: 'Work Orders',
          href: { path: '/admin/work_orders' },
          icon: 'si si-tag',
          exactPath: true,
        },
        this.$can('read', 'Quote') && {
          title: 'Quotes',
          href: '/admin/quotes',
          icon: 'si si-book-open',
          exactPath: true,
        },
        {
          title: pluralize(this.$store.getters.translate('client', 'Client')),
          icon: 'si si-organization',
          child: _compact([
            {
              title: pluralize(this.$store.getters.translate('client', 'Client')),
              href: '/admin/clients',
              icon: 'si si-organization',
              exactPath: true,
            },
            {
              title: 'Locations',
              href: '/admin/locations',
              icon: 'si si-location-pin',
              exactPath: true,
            },
            {
              title: 'Services',
              href: '/admin/services',
              icon: 'si si-wrench',
              exactPath: true,
            },
            {
              title: 'Feedback',
              href: '/admin/client_feedbacks',
              icon: 'si si-chart',
              exactPath: true,
            },
            {
              title: 'Walkthroughs',
              href: '/admin/walkthroughs',
              icon: 'si si-pencil',
              exactPath: true,
            },
          ]),
        },
        {
          title: 'Users',
          icon: 'si si-people',
          child: _compact([
            {
              title: 'Users',
              icon: 'si si-people',
              exactPath: true,
              href: '/admin/users',
            },
            this.$can('read', 'InsuranceCertificate') && {
              title: 'Insurance Certificates',
              href: '/admin/insurance_certificates',
              icon: 'si si-docs',
              exactPath: true,
            },
          ]),
        },
        {
          title: 'Vendors',
          icon: 'fa fa-shipping-fast',
          child: _compact([
            {
              title: 'Vendors',
              icon: 'fa fa-shipping-fast',
              exactPath: true,
              href: '/admin/vendors',
            },
             {
              title: 'Supply Orders',
              icon: 'fas fa-envelope-open-text',
              exactPath: true,
              href: '/admin/supply_orders',
            },
            {
              title: 'New Supply Order',
              icon: 'fas fa-folder-plus',
              exactPath: true,
              href: '/admin/supply_orders/new',
            },
          ]),
        },
        {
          title: 'Accounting',
          icon: 'fa fa-dollar-sign',
          child: _compact([
            this.$can('read', 'Invoice') && {
              title: 'Invoices',
              href: '/admin/invoices',
              icon: 'fa fa-dollar-sign',
              exactPath: true,
            },
            this.$can('read', 'TechnicianPayment') && {
              title: 'Tech Payments',
              href: '/admin/technician_payments',
              icon: 'si si-wallet',
              exactPath: true,
            },
            {
              title: 'GL Codes',
              icon: 'si si-people',
              exactPath: true,
              href: '/admin/gl_codes',
            },
            {
              title: 'Tax Rates',
              icon: 'si si-calculator',
              exactPath: true,
              href: '/admin/tax_rates',
            },
          ]),
        },
        {
          title: 'Reports',
          icon: 'fa fa-chart-bar',
          child: _compact([
            {
              title: 'Work Orders',
              icon: '',
              child: _compact([
                {
                  title: 'Profitability',
                  href: '/admin/reports/work_orders/profitability',
                  icon: '',
                  exactPath: true,
                },
                {
                  title: 'Total Over Time',
                  href: '/admin/reports/work_orders/total_over_time',
                  icon: '',
                  exactPath: true,
                }
              ])
            },
            this.$can('read', 'ClientFeedback') && {
              title: 'Client Feedback',
              icon: '',
              child: _compact([
                {
                  title: 'DSG KPI',
                  href: '/admin/reports/client_feedbacks/dicks_sporting_goods_kpi',
                  icon: 'fa fa-basketball-ball',
                  exactPath: true,
                },
              ])
            },
            this.$can('technician_report', 'User') && {
              title: 'Technicians',
              icon: '',
              child: _compact([
                {
                  title: 'KPI',
                  href: '/admin/reports/technicians/kpi',
                  icon: '',
                  exactPath: true,
                },
                {
                  title: 'Forecast',
                  href: '/admin/reports/technicians/forecast',
                  icon: '',
                  exactPath: true,
                }
              ])
            },
            this.$can('revenue_report', 'Invoice') && {
              title: 'Revenue',
              href: '/admin/reports/invoices/revenue',
              icon: '',
              exactPath: true,
            },
            this.$can('read', 'Invoice') && {
              title: 'Tax',
              href: '/admin/reports/invoices/taxes',
              icon: '',
              exactPath: true,
            },
          ]),
        },
        this.$can('read', 'Import') && {
          title: 'Imports',
          href: '/admin/imports',
          icon: 'si si-doc',
          exactPath: true,
        },
        this.$can('read', 'Version') && {
          title: 'Audit Log',
          href: '/admin/versions',
          icon: 'si si-eyeglass',
          exactPath: true,
        },
        this.$can('read', 'DelayedJob') && {
          title: 'Delayed Jobs',
          href: '/admin/delayed_jobs',
          icon: 'si si-clock',
          exactPath: true,
        },
        {
          title: this.$store.getters.user?.name,
          icon: 'si si-user',
          child: _compact([
            {
              title: 'Edit My Profile',
              href: '',
              icon: '',
              modalId: 'edit-profile-modal',
            },
            {
              title: 'Update My Password',
              href: '',
              icon: '',
              modalId: 'update-password-modal',
            },
            this.$store.getters.user?.otp_verified && {
              title: 'Disable MFA',
              href: '',
              icon: '',
              modalId: 'disable-mfa-auth-modal',
            },
            !this.$store.getters.user?.otp_verified && {
              title: 'Register MFA',
              href: '',
              icon: '',
              modalId: 'register-mfa-auth-modal',
            },
            {
              title: 'Change Organization',
              href: '',
              icon: '',
              modalId: 'change-organization-modal',
            },
            this.$can('impersonate', 'User') && {
              title: 'Impersonate Technician',
              href: '',
              icon: '',
              modalId: 'user-impersonation-modal',
            },
            {
              title: 'Logout',
              href: '/logout',
              icon: '',
            },
          ]),
        }
      ]),
    };
  },
  created() {
    // Set default elements for this layout
    this.$store.commit('setLayout', {
      footer: true,
      sidebar: true,
    });

    this.$store.commit('mainContent', { mode: 'boxed' });
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === SET_OUT_OF_DATE_MUTATION) {
        if (!state.app.upToDate && !this.$store.getters.hasUpToDatePopupRendered) {
          showAppOutOfDatePopup();
        }
      } else if (mutation.type === SET_ORGANIZATION_MUTATION) {
        this.refreshPage();
      }
    });
  },
  mounted() {
    if (!this.user.otp_verified) {
      this.registerMfaAuth();
    }
  },
  methods: {
    refreshPage() {
      this.refreshKey = Date.now();
    },
    updateUser(user) {
      if (!user.otp_verified) {
        this.registerMfaAuth();
      }
    },
    registerMfaAuth() {
      this.$authAPI.registerLoggedInUserMfaAuth().then((response) => {
        this.qrCode = response.data.otp_qr_code;
      });
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    organization() {
      return this.$store.getters.currentOrganization;
    },
  },
  beforeRouteEnter(_to, _from, next) {
    store
      .dispatch('getProfile')
      .then(() => store.dispatch('getTerminology'))
      .then(() => next())
      .catch((error) => {
        if (error?.response?.status == 412) {
          showAppOutOfDatePopup();
        }
      });
  },
};
</script>
