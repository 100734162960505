<template>
  <div class="text-nowrap">
    <b-badge
      :variant="variant"
    >
      {{ status | humanizedFormat | capitalizeFormat }}
      <span v-if="subStatus">- {{ subStatus | humanizedFormat | capitalizeFormat }}</span>
    </b-badge>
  </div>
</template>

<script>
export default {
  name: 'StatusBadge',
  props: {
    status: {
      type: String,
      default: '',
    },
    subStatus: {
      type: String,
    },
    statusVariants: {
      type: Object,
      required: true,
    }
  },
  computed: {
    variant() {
      return this.statusVariants[this.status];
    },
    humanizedSubStatus() {
      if (!this.subStatus) {
        return '';
      }

      return this.$options.filters.capitalizeFormat(this.$options.filters.humanizedFormat(this.subStatus));
    }
  },
};
</script>
