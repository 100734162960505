/*
 * VueX - State Management
 */

import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import createCache from 'vuex-cache';
import abilityPlugin from '@/store/modules/auth/abilityPlugin.js';

// Register Vuex
Vue.use(Vuex);

import uiModule from '@/store/modules/ui/index.js';
import authModule from '@/store/modules/auth/index.js';
// Vuex Store

const store = new Vuex.Store({
  ...uiModule,
  modules: {
    auth: authModule,
  },
  plugins: [
    createPersistedState({
      paths: ['auth.token', 'auth.impersonatingToken','auth.impersonatingUser', 'auth.category', 'auth.organization', 'auth.client', 'auth.organizationListColumns'],
    }),
    createCache({ timeout: 86400000 }), // our cached store invalidates after 24 hours
    abilityPlugin,
  ],
});

export default store;
