import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const CLIENT_FEEDBACK_MODEL_NAME = 'client_feedbacks';
export const CLIENT_FEEDBACK_TABLE_NAME = 'clientFeedbacks';

// Client Feedback label constants
export const CLIENT_FEEDBACK_DATE_LABEL = 'Date';
export const CLIENT_FEEDBACK_CLIENT_ID_LABEL = 'Client';
export const CLIENT_FEEDBACK_LOCATION_ID_LABEL = 'Location';
export const CLIENT_FEEDBACK_WORK_ORDER_ID_LABEL = 'Work Order';

export const CLIENT_FEEDBACKS_FORM_LABELS = {
  date: CLIENT_FEEDBACK_DATE_LABEL,
  client_id: CLIENT_FEEDBACK_CLIENT_ID_LABEL,
  location_id: CLIENT_FEEDBACK_LOCATION_ID_LABEL,
  work_order_id: CLIENT_FEEDBACK_WORK_ORDER_ID_LABEL,
};

// The columns available to be shown in a client feedbacks table.
export const CLIENT_FEEDBACK_TABLE_COLUMNS = {
  date: { label: CLIENT_FEEDBACK_DATE_LABEL, key: 'date', sortable: true },
  client_id: { label: CLIENT_FEEDBACK_CLIENT_ID_LABEL, key: 'client_id' },
  location_id: { label: CLIENT_FEEDBACK_LOCATION_ID_LABEL, key: 'location_id' },
  work_order_id: { label: CLIENT_FEEDBACK_WORK_ORDER_ID_LABEL, key: 'work_order_id' },
  actions: { label: 'Actions', key: 'actions' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const CLIENT_FEEDBACK_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(CLIENT_FEEDBACK_TABLE_COLUMNS);

// The default columns to be shown when the clientFeedback table loads.
export const CLIENT_FEEDBACK_TABLE_DEFAULT_COLUMNS = [
  CLIENT_FEEDBACK_TABLE_COLUMNS.date,
  CLIENT_FEEDBACK_TABLE_COLUMNS.location_id,
  CLIENT_FEEDBACK_TABLE_COLUMNS.work_order_id,
  CLIENT_FEEDBACK_TABLE_COLUMNS.actions,
];
