import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';
import { WORK_ORDER_FORM_LABELS } from './workOrders';

export const WORK_ORDER_PROFITABILITY_REPORT_TABLE_NAME = 'workOrderProfitabilityReport';
export const WORK_ORDER_REVENUE_REPORT_TABLE_NAME = 'revenueReportTable';
export const WORK_ORDER_TAX_REPORT_TABLE_NAME = 'workOrderTaxReportTable';
export const WORK_ORDER_TECHNICIAN_REPORT_TABLE_NAME = 'technicianReportTable';
export const WORK_ORDER_TECHNICIAN_FORECAST_REPORT_TABLE_NAME = 'technicianForecastReportTable';
export const CLIENT_FEEDBACK_LOCATION_SEARCH_REPORT_TABLE_NAME = 'locationFeedbackSearchReport';

export const WORK_ORDER_PROFITABILITY_REPORT_TABLE_COLUMNS = {
  number: { label: WORK_ORDER_FORM_LABELS.number, key: 'number', sortable: true },
  location_id: { label: WORK_ORDER_FORM_LABELS.location_id, key: 'location_id', sortable: true },
  square_footage: { label: 'Footage', key: 'square_footage' },
  assigned_to_id: { label: WORK_ORDER_FORM_LABELS.assigned_to_id, key: 'assigned_to_id' },
  manager_id: { label: 'Manager', key: 'manager_id' },
  margin: { label: 'Margin', key: 'margin' },
  margin_percent: { label: 'Margin %', key: 'margin_percent' },
};

export const WORK_ORDER_PROFITABILITY_REPORT_TABLE_DEFAULT_COLUMNS = [
   WORK_ORDER_PROFITABILITY_REPORT_TABLE_COLUMNS.number,
   WORK_ORDER_PROFITABILITY_REPORT_TABLE_COLUMNS.location_id,
   WORK_ORDER_PROFITABILITY_REPORT_TABLE_COLUMNS.square_footage,
   WORK_ORDER_PROFITABILITY_REPORT_TABLE_COLUMNS.assigned_to_id,
   WORK_ORDER_PROFITABILITY_REPORT_TABLE_COLUMNS.manager_id,
   WORK_ORDER_PROFITABILITY_REPORT_TABLE_COLUMNS.margin,
   WORK_ORDER_PROFITABILITY_REPORT_TABLE_COLUMNS.margin_percent,
];

export const WORK_ORDER_REVENUE_REPORT_TABLE_COLUMNS = {
  group: { label: 'Group', key: 'group', sortable: true },
  revenue: { label: 'Revenue', key: 'revenue'},
  work_orders: { label: 'Work Orders', key: 'work_orders' },
  total_cost: { label: 'Cost', key: 'total_cost' },
  profit: { label: 'Profit', key: 'profit' },
  margin: { label: 'Margin', key: 'margin' },
  client_id: { label: 'Client', key: 'client_id', filterOnly: true },
  service_id: { label: 'Service', key: 'service_id', filterOnly: true },
  state: { label: 'State', key: 'state', filterOnly: true },
  start_date: { label: 'Start Date From', key: 'start_date', filterOnly: true },
  end_date: { label: 'End Date From', key: 'end_date', filterOnly: true },
};

export const WORK_ORDER_REVENUE_REPORT_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(WORK_ORDER_REVENUE_REPORT_TABLE_COLUMNS);

export const WORK_ORDER_REVENUE_REPORT_TABLE_DEFAULT_COLUMNS = [
   WORK_ORDER_REVENUE_REPORT_TABLE_COLUMNS.group,
   WORK_ORDER_REVENUE_REPORT_TABLE_COLUMNS.revenue,
   WORK_ORDER_REVENUE_REPORT_TABLE_COLUMNS.work_orders,
   WORK_ORDER_REVENUE_REPORT_TABLE_COLUMNS.total_cost,
   WORK_ORDER_REVENUE_REPORT_TABLE_COLUMNS.profit,
   WORK_ORDER_REVENUE_REPORT_TABLE_COLUMNS.margin,
];

export const CLIENT_FEEDBACK_LOCATION_SEARCH_REPORT_TABLE_COLUMNS = {
  store_number: { label: 'Store #', key: 'store_number', sortable: true },
  last_feedback_date: { label: 'Feedback Last Received', key: 'last_feedback_date', sortable: true },
  feedback_last_received_at: { label: 'Feedback received before', key: 'feedback_last_received_at', filterOnly: true },
  client_id: { label: 'Client', key: 'client_id', filterOnly: true },
  location_id: { label: 'Location', key: 'location_id', filterOnly: true },
  actions: { label: 'Actions', key: 'actions' },
};

export const CLIENT_FEEDBACK_LOCATION_SEARCH_REPORT_TABLE_DEFAULT_COLUMNS = [
  CLIENT_FEEDBACK_LOCATION_SEARCH_REPORT_TABLE_COLUMNS.store_number,
  CLIENT_FEEDBACK_LOCATION_SEARCH_REPORT_TABLE_COLUMNS.last_feedback_date,
  CLIENT_FEEDBACK_LOCATION_SEARCH_REPORT_TABLE_COLUMNS.actions,
];

export const CLIENT_FEEDBACK_LOCATION_SEARCH_REPORT_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(CLIENT_FEEDBACK_LOCATION_SEARCH_REPORT_TABLE_COLUMNS);

export const WORK_ORDER_TAX_REPORT_TABLE_COLUMNS = {
  tax_name: { label: 'Tax Name', key: 'tax_name', sortable: true },
  tax_rate: { label: 'Tax Rate', key: 'tax_rate', sortable: true },
  tax_city: { label: 'City', key: 'tax_city', sortable: true },
  tax_county: { label: 'County', key: 'tax_county', sortable: true },
  tax_state: { label: 'State', key: 'tax_state', sortable: true },
  taxable_sales: { label: 'Taxable Sales', key: 'taxable_sales', sortable: true },
  tax_amount: { label: 'Tax Amount', key: 'tax_amount', sortable: true },
  total_sales: { label: 'Total Sales', key: 'total_sales', sortable: true },
  client_id: { label: 'Client', key: 'client_id', filterOnly: true },
  state: { label: 'State', key: 'state', filterOnly: true },
  start_date: { label: 'Start Date From', key: 'start_date', filterOnly: true },
  end_date: { label: 'End Date From', key: 'end_date', filterOnly: true },
};

export const WORK_ORDER_TAX_REPORT_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(WORK_ORDER_TAX_REPORT_TABLE_COLUMNS);

export const WORK_ORDER_TAX_REPORT_TABLE_DEFAULT_COLUMNS = [
  WORK_ORDER_TAX_REPORT_TABLE_COLUMNS.tax_name,
  WORK_ORDER_TAX_REPORT_TABLE_COLUMNS.tax_city,
  WORK_ORDER_TAX_REPORT_TABLE_COLUMNS.tax_county,
  WORK_ORDER_TAX_REPORT_TABLE_COLUMNS.tax_state,
  WORK_ORDER_TAX_REPORT_TABLE_COLUMNS.taxable_sales,
  WORK_ORDER_TAX_REPORT_TABLE_COLUMNS.tax_amount,
  WORK_ORDER_TAX_REPORT_TABLE_COLUMNS.total_sales,
];

export const TECHNICIAN_REPORT_TABLE_COLUMNS = {
  technician_name: { label: 'Technician', key: 'technician_name', sortable: true },
  revenue_cents: { label: 'WO Revenue', key: 'revenue_cents', sortable: true },
  recurring_revenue_cents: { label: 'Recurring Revenue', key: 'recurring_revenue_cents' },
  cost_cents: { label: 'Technician Cost', key: 'cost_cents', sortable: true },
  total_work_orders: { label: 'Work Orders', key: 'total_work_orders', sortable: true },
  total_locations: { label: 'Total Locations', key: 'total_locations', sortable: true },
  mobile_usage_percentage: { label: 'Mobile Usage', key: 'mobile_usage_percentage', sortable: true },
  average_completion_time: { label: 'Avg Completion Time', key: 'average_completion_time', sortable: true },
  average_rating: { label: 'Avg Rating', key: 'average_rating', sortable: true },
  actions: { label: 'Actions', key: 'actions', sortable: true },
  client_id: { label: 'Client', key: 'client_id', filterOnly: true },
  service_id: { label: 'Service', key: 'service_id', filterOnly: true },
  manager_id: { label: 'Manager', key: 'manager_id', filterOnly: true },
  user_id: { label: 'Technician', key: 'user_id', filterOnly: true },
  state: { label: 'State', key: 'state', filterOnly: true },
  start_date: { label: 'Start Date From', key: 'start_date', filterOnly: true },
  end_date: { label: 'End Date From', key: 'end_date', filterOnly: true },
};

export const TECHNICIAN_FORECAST_REPORT_TABLE_COLUMNS = {
  technician_name: { label: 'Technician', key: 'technician_name', sortable: true },
  revenue_cents: { label: 'WO Revenue', key: 'revenue_cents', sortable: true },
  cost_cents: { label: 'Technician Cost', key: 'cost_cents', sortable: true },
  total_work_orders: { label: 'Work Orders', key: 'total_work_orders', sortable: true },
  total_locations: { label: 'Total Locations', key: 'total_locations', sortable: true },
  client_id: { label: 'Client', key: 'client_id', filterOnly: true },
  service_id: { label: 'Service', key: 'service_id', filterOnly: true },
  manager_id: { label: 'Manager', key: 'manager_id', filterOnly: true },
  user_id: { label: 'Technician', key: 'user_id', filterOnly: true },
  state: { label: 'State', key: 'state', filterOnly: true },
  start_date: { label: 'Date Bucket Start', key: 'start_date', filterOnly: true },
  end_date: { label: 'Date Bucket End', key: 'end_date', filterOnly: true },
};

export const TECHNICIAN_REPORT_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(TECHNICIAN_REPORT_TABLE_COLUMNS);
export const TECHNICIAN_FORECAST_REPORT_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(TECHNICIAN_FORECAST_REPORT_TABLE_COLUMNS);

export const TECHNICIAN_REPORT_TABLE_DEFAULT_COLUMNS = [
   TECHNICIAN_REPORT_TABLE_COLUMNS.technician_name,
   TECHNICIAN_REPORT_TABLE_COLUMNS.revenue_cents,
   TECHNICIAN_REPORT_TABLE_COLUMNS.recurring_revenue_cents,
   TECHNICIAN_REPORT_TABLE_COLUMNS.total_work_orders,
   TECHNICIAN_REPORT_TABLE_COLUMNS.mobile_usage_percentage,
   TECHNICIAN_REPORT_TABLE_COLUMNS.average_completion_time,
   TECHNICIAN_REPORT_TABLE_COLUMNS.average_rating,
   TECHNICIAN_REPORT_TABLE_COLUMNS.actions,
];

export const TECHNICIAN_FORECAST_REPORT_TABLE_DEFAULT_COLUMNS = [
  TECHNICIAN_REPORT_TABLE_COLUMNS.technician_name,
  TECHNICIAN_REPORT_TABLE_COLUMNS.revenue_cents,
  TECHNICIAN_REPORT_TABLE_COLUMNS.cost_cents,
  TECHNICIAN_REPORT_TABLE_COLUMNS.total_work_orders,
  TECHNICIAN_REPORT_TABLE_COLUMNS.total_locations,
];