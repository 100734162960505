<template>
  <div>
    <base-page-heading
      title="Quotes"
      show-back-button
    >
      <template #extra>
        <b-button
          v-if="$can('create', 'Quote')"
          variant="primary"
          class="mr-2"
          @click="() => $router.push({ name: 'new-quote' })"
        >
          <i class="fa fa-plus mr-1" />
          New Quote
        </b-button>

        <sfs-download-button
          v-b-tooltip.hover
          :provider-params="filters"
          filename="Quotes.csv"
          :file-provider="$quotesAPI.getSpreadsheet"
          title="Download spreadsheet"
          label="Download"
        />
      </template>
    </base-page-heading>
    <div class="content">
      <b-row>
        <b-col>
          <quotes-list-filter
            :table-name="tableName"
            :columns="columns"
            :initial-filters="filters"
            @filters-applied="applyFilters"
          />
        </b-col>
      </b-row>

      <quotes-table
        :filters="filters"
        :columns="columns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
      />
    </div>
  </div>
</template>

<script>
import QuotesTable from '@/components/admin/quotes/Table.vue';
import QuotesListFilter from '@/components/admin/quotes/ListFilter.vue';
import listMixin from '@/mixins/listMixin';
import { QUOTE_TABLE_NAME } from '@/constants/quotes';

export default {
  name: 'QuotesList',
  components: {
    QuotesListFilter,
    QuotesTable,
  },
  mixins: [listMixin],
  props: {
    text: String,
    client_id: String,
    location_id: String,
    status: String,
  },
  data() {
    return {
      tableName: QUOTE_TABLE_NAME,
      filters: {
        text: this.text,
        client_id: this.client_id,
        location_id: this.location_id,
        status: this.status,
      },
    };
  },
};
</script>
