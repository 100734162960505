<template>
  <div>
    <base-page-heading
      :title="`${$store.getters.translate('client', 'Client')} Feedback`"
      show-back-button
    >
      <template #extra>
        <b-button
          v-if="$can('create', 'ClientFeedback')"
          variant="primary"
          class="mr-2"
          @click="() => $router.push({ name: 'new-client-feedback' })"
        >
          <i class="fa fa-plus mr-1" />
          Add Feedback
        </b-button>
        <b-button
          v-if="$can('create', 'Import')"
          variant="outline-secondary"
          class="mr-2"
          v-b-tooltip.hover.window
          title="Find Locations needing Feedback"
          @click="() => $router.push({ name: 'client-feedbacks-location-search' })"
        >
          <i class="fa fa-search mr-1" />
          Search
        </b-button>
      </template>
    </base-page-heading>
    <div class="content">
      <b-row>
        <b-col>
          <client-feedbacks-list-filter
            :table-name="tableName"
            :columns="columns"
            :initial-filters="filters"
            @filters-applied="applyFilters"
          />
        </b-col>
      </b-row>

      <client-feedbacks-table
        :filters="filters"
        :columns="columns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
      />
    </div>
  </div>
</template>

<script>
import ClientFeedbacksTable from '@/components/admin/clientFeedbacks/Table.vue';
import ClientFeedbacksListFilter from '@/components/admin/clientFeedbacks/ListFilter.vue';
import listMixin from '@/mixins/listMixin';
import { CLIENT_FEEDBACK_TABLE_NAME } from '@/constants/clientFeedbacks';

export default {
  name: 'ClientFeedbacksList',
  components: {
    ClientFeedbacksListFilter,
    ClientFeedbacksTable,
  },
  mixins: [listMixin],
  props: {
    text: String,
    client_id: String,
    location_id: String,
    work_order_id: String,
  },
  data() {
    return {
      tableName: CLIENT_FEEDBACK_TABLE_NAME,
      filters: {
        text: this.text,
        client_id: this.client_id || this.$store.getters?.client?.id,
        location_id: this.location_id,
        work_order_id: this.work_order_id,
      },
    };
  },
};
</script>
